import { useIsAuthenticated } from "@azure/msal-react";
import { PhaseBanner } from "govuk-react";

import './HeaderBar.css';

/**
 * Renders the navbar component with a sign-in or sign-out button depending on whether or not a user is authenticated
 * @param props 
 */
export const HeaderBar = () => {
  const isAuthenticated = useIsAuthenticated();

  return (
    <>
      <header id="govuk-header-mhra" className="govuk-header" data-module="header" role="banner">
        <div className="govuk-header__container govuk-width-container">
          <a href="https://www.gov.uk/government/organisations/medicines-and-healthcare-products-regulatory-agency" title="MHRA" id="mhra-logo-link" className="external-no-style">
            <img src={`${process.env.REACT_APP_UI_REDIRECT_URL}assets/images/Agency_single_line_sm_white_v2.png`} height="34" alt="MHRA logo" title="MHRA logo" typeof="foaf:Image"/>
          </a>
        </div>
      </header>
      <header className="govuk-header" data-module="header" role="banner">
        <div className="govuk-header__container govuk-width-container">
          <div className="region region-header">
            <div id="block-cprd-branding" className="block block-system block-system-branding-block">
              <a href="https://cprd.com/home" title="Home" rel="home" className="site-logo external-no-style">
                <img src={`${process.env.REACT_APP_UI_REDIRECT_URL}assets/images/cprdlogo.svg`} alt="Clinical Practice Research Datalink" typeof="foaf:Image"/>
              </a>
              <div className="tag-line">
                UK data driving real-world evidence
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="HeaderBar__phase-wrapper">
        <PhaseBanner level="Beta">
          CPRD Safe
        </PhaseBanner>
      </div>
    </>
  );
};
