import React from "react";

import './Fieldset.css';

type FieldsetProps = {
  error?: boolean | null;
  id?: string;
  children: React.ReactNode;
  className?: string;
}

export const Fieldset = ({ error, children, id, className }: FieldsetProps) => {
  return (
    <fieldset id={id} className={`fieldset${className ? ` ${className}` : ''}${error ? ' fieldset--error' : ''}`}>
      {children}
    </fieldset>
  )
}