import React from 'react';

import './FormTitle.css';

type FormTitleProps = {
  children: React.ReactNode
}

export const FormTitle = ({ children }: FormTitleProps) => {
  return (
    <h1 className="FormTitle">{children}</h1>
  );
}
