import { Link, useLocation } from 'react-router-dom';

import './SubNav.css';

export const SubNav = () => {
  const location = useLocation();

  const isDashboard = location.pathname.includes('Home') || location.pathname === '';
  const dashboardClassnames = `sub-nav__link${isDashboard ? ' sub-nav__link--current' : ''}`;

  const isManageWorkspaces = location.pathname.includes('manage-workspaces');
  const manageWorkspacesClassnames = `sub-nav__link${isManageWorkspaces ? ' sub-nav__link--current' : ''}`;

  return (
    <nav className="sub-nav">
      <Link className={dashboardClassnames} to="HomeLink">Dashboard</Link>
      <Link className={manageWorkspacesClassnames} to="manage-workspaces">Manage workspaces</Link>
    </nav>
  )
}
