import React, { useContext, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import yn from "yn";
import { LabelText, HintText, Input, Button, ErrorText, ErrorSummary, LoadingBox } from 'govuk-react';

import { UpdateWorkspaceRequest } from '../models/workspace';
import { WorkspaceContext } from '../../contexts/WorkspaceContext';

import { CancelButton } from '../ui/CancelButton';
import { FormWrapper } from '../ui/FormWrapper';
import { FormLabel } from '../ui/FormLabel';
import { FormButtonContainer } from '../ui/FormButtonContainer';
import { Textarea } from '../ui/Textarea';
import { Title } from '../ui/Title';
import { Subtitle } from '../ui/Subtitle';

import { error } from '../../types';
import { CallApiWithToken, HttpMethod } from '../Core/fetch';
import { trecoreServicesConfig } from '../Core/authConfig';
import { ApiEndpoint } from '../models/apiEndPoints';
import { NotificationBox } from '../ui/NotificationBox';

export const UpdateWorkspace = () => {
  const workspaceCtx = useContext(WorkspaceContext);
  const wsId = useParams();
  const { instance, accounts } = useMsal();
  const [errors, setErrors] = useState<null | error[]>(null);
  const [apiError, setApiError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [resquestSuccessful, setRequestSuccessful] = useState<null | string>(null);
  let navigate = useNavigate();
  const wsNameInputRef = useRef<HTMLInputElement>(null);
  const descriptionInputRef = useRef<HTMLTextAreaElement>(null);
  const airlockManagerEmailInputRef = useRef<HTMLInputElement>(null);
  let path = '/manage-workspaces/';

  const routeChange = () => {
    navigate(path);
  }

  const makeApiCall = async (payload: any, workspace: any) => {
    const tokenRequest = {
      scopes: [`${process.env.REACT_APP_TRE_CORE_API_WORKSPACE_SCOPES}/user_impersonation`],
      account: accounts[0]
    }
    const finalUrl = `${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${workspace.id}`;
    
    setApiError(null);
    setIsLoading(true);

    instance.acquireTokenSilent(tokenRequest).then(async (response) => {
      await CallApiWithToken(response.accessToken, finalUrl, HttpMethod.Patch, payload, workspace._etag)
        .then(response => {
          setIsLoading(false);
          navigate(path, {state: { updated: wsNameInputRef.current!.value }})
        })
        .catch((err: any) => {
          setApiError(err.message);
          setIsLoading(false);
        })
      }).catch((err: any) => {
        setApiError(err.message);
        setIsLoading(false);
      })
    document.getElementById("notification")?.scrollIntoView();
    yn(process.env.REACT_APP_DEBUG) && console.log("Workspace update request submitted");
  }

  const createPayload = (e: any) => {
    e.preventDefault();
    const entereddescription = descriptionInputRef.current!.value;       
    const enteredvmName = wsNameInputRef.current!.value;
    
    let properties: UpdateWorkspaceRequest["properties"] = {};
      
    properties = {
      display_name: enteredvmName,
      description: entereddescription
    };

    const newUpdateWorkspaceRequest : UpdateWorkspaceRequest = {
      properties: properties
    };

    makeApiCall(newUpdateWorkspaceRequest, workspaceCtx.workspace);
  }

  const hasError = (formItem: string) => {
    const checkError = (obj: error) => obj.targetName === formItem;
    return errors && errors.some(checkError);
  }

  const onSubmit = (e: any) => {
    e.preventDefault();
    setErrors(null);
    const newErrors = [];
    const workspaceName = wsNameInputRef.current?.value;
    if (!workspaceName || (workspaceName.length < 5 || workspaceName.length > 100)) {
      newErrors.push({
        targetName: "workspace-name",
        text: "Enter a valid workspace name"
      })
    }

    const description = descriptionInputRef.current?.value;
    if (!description || (description.length < 10 || description.length > 100)) {
      newErrors.push({
        targetName: "description",
        text: "Enter a valid description, maximum 100 characters"
      })
    }

    return newErrors.length > 0 ? (
      setErrors(newErrors),
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    ) : createPayload(e);
  }

  return (
    <LoadingBox loading={isLoading}>
      {(apiError || resquestSuccessful) && (
        <NotificationBox
          id="notification"
          error={apiError}
          text={resquestSuccessful}
          linkPreText={resquestSuccessful ? "Go back to " : ""}
          linkText={resquestSuccessful ? "manage workspaces" : ""}
          linkDestination={resquestSuccessful ? "/manage-workspaces" : ""}
        />
      )}
      {workspaceCtx.workspace.properties && workspaceCtx.workspace.id === wsId.id && (
        <>
          <header>
            <Title>Update workspace</Title>
            <Subtitle>{workspaceCtx.workspace.properties.display_name}</Subtitle>
          </header>
          {errors && errors.length > 0 && (
            <ErrorSummary
              errors={errors}
              heading="There is a problem submitting your request"
              onHandleErrorClick={(e: any) => document.getElementById(e)?.scrollIntoView()}
            />
          )}
          <form onSubmit={onSubmit}>
            <FormWrapper>
              <FormLabel error={hasError("workspace-name")} id="workspace-name">
                <LabelText>Workspace Name</LabelText>
                <HintText>The name of the workspace</HintText>
                {hasError("workspace-name") && <ErrorText>Enter a valid workspace name</ErrorText>}
                <Input ref={wsNameInputRef} defaultValue={workspaceCtx.workspace.properties.display_name} />
              </FormLabel>
              <FormLabel error={hasError("description")} id="description">
                <LabelText>Description</LabelText>
                <HintText>Description for the workspace</HintText>
                {hasError("description") && <ErrorText>Enter a valid description (maximum 100 character)</ErrorText>}
                <Textarea forwardRef={descriptionInputRef} defaultValue={workspaceCtx.workspace.properties.description} />
              </FormLabel>
              <FormButtonContainer>
                <Button type="submit" className="govuk-button" data-module="govuk-button">Update workspace</Button>
                <CancelButton onClick={() => routeChange()}>Cancel</CancelButton>
              </FormButtonContainer>
            </FormWrapper>
          </form>
        </>
      )}
    </LoadingBox>
  )
}
