import "./Home.css";

export const DashSample=(props:any)=>{    
        
        return (
            <>

        <p>Dash Sample here...</p>
        <iframe src="" height="100%" width="100%" title="W3Schools Free Online Web Tutorials"></iframe> 
            </>
        );
    }