import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { LoadingBox, Tag, Button, ButtonArrow } from 'govuk-react';

import { WorkspaceContext } from '../contexts/WorkspaceContext';

import { ApiEndpoint } from '../components/models/apiEndPoints';
import { loginRequest, trecoreServicesConfig } from '../components/Core/authConfig';
import { CallApiWithToken, HttpMethod } from '../components/Core/fetch';
import { useAuthApiCall } from '../components/hooks/useAuthAPICall';
import { useInterval } from '../components/hooks/useInterval';
import { CheckOps } from '../services/CheckOps';

import { Title } from '../components/ui/Title';
import { Subtitle } from '../components/ui/Subtitle';
import { Selectbox } from '../components/ui/Selectbox';
import { TableHead } from '../components/ui/TableHead';
import { TextButton } from '../components/ui/TextButton';
import { Modal } from '../components/ui/Modal';
import { MessageCard } from '../components/Error/MessageCard';
import { NotificationBox } from '../components/ui/NotificationBox';
import { Td } from '../components/ui/GDS-components/Table';

import './ManageWorkspaceServices.css';

export const ManageWorkspaceServices = () => {
  const workspaceCtx = useContext(WorkspaceContext);
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const apiCall = useAuthApiCall();
  const [allWorkspaceServices, setAllWorkspaceServices] = useState([]);
  const [workspaceServices, setWorkspaceServices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorData, setErrorData] = useState(null);
  const [pausedWorkspaceService, setPausedWorkspaceService] = useState(false);
  const [changeStateError, setChangeStateError] = useState(null);
  const [selectedWorkspaceService, setSelectedWorkspaceService] = useState<any>([]);
  const [selectedAction, setSelectedAction] = useState<null | string>(null);
  const [confirmPauseDelete, setConfirmPauseDelete] = useState(false);
  const [deploying, setDeploying] = useState(false);
  const [sort, setSort] = useState<string | null>();
  const [updatedService, setUpdatedService] = useState<null | string>(null);
  const [refresh, setRefresh] = useState(false);
  const location: any = useLocation();

  useEffect(() => {
    setIsLoading(true);
    workspaceCtx.workspace.properties && (
      instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
        scopes: [`${workspaceCtx.workspace.properties.scope_id}/${process.env.REACT_APP_TRE_CORE_API_USER_IMPERSONATION}`]
      }).then(async (response: any) => {
        await CallApiWithToken(
          response.accessToken,
          `${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${workspaceCtx.workspace.id}/${ApiEndpoint.WorkspaceServices}`,
          HttpMethod.Get,
          ''
        ).then(response => {
          setAllWorkspaceServices(response.workspaceServices);
          setWorkspaceServices(response.workspaceServices);
          const deploying = response.workspaceServices?.filter((item: any) => item.deploymentStatus === "deploying" || item.deploymentStatus === "awaiting_deployment" || item.deploymentStatus == null);
          setDeploying(deploying.length > 0);
          setIsLoading(false);
        }).catch((err: any) => {
          setErrorData(err);
          setIsLoading(false);
        })
      })
    )
    pausedWorkspaceService && setPausedWorkspaceService(false);
    refresh && setRefresh(false);
    refresh && setUpdatedService(null);
  }, [workspaceCtx, pausedWorkspaceService, refresh]);

  const routeChange = () => {
    let path = 'CreateNewWorkspaceService'; 
    navigate(path);
  }

  const onSort = (e: any) => {
    setSort(e);
    if (e === null) {
      return setWorkspaceServices(allWorkspaceServices);
    } else if (e === "active") {
      setWorkspaceServices(allWorkspaceServices.filter((item: any) => item.isEnabled));
    } else {
      setWorkspaceServices(allWorkspaceServices.filter((item: any) => !item.isEnabled));
    }
  }

  const onPause = async (id: string, workspaceId: string, etag: string, enable: boolean) => {
    const pause = {
      isEnabled: enable
    }
    setIsLoading(true);
    await apiCall(`/${ApiEndpoint.Workspaces}/${workspaceId}/workspace-services/${id}`, HttpMethod.Patch, workspaceCtx.workspaceApplicationIdURI, pause, undefined, undefined, false, etag)
    .then(response => {
      setPausedWorkspaceService(true);
      setIsLoading(false);
      setChangeStateError(null);
      setUpdatedService(id);
    })
    .catch((err: any) => {
      setIsLoading(false);
      setChangeStateError(err.message);
    })
  }

  const onDelete = async (id: string) => {
    setIsLoading(true);
    await apiCall(`/${ApiEndpoint.Workspaces}/${workspaceCtx.workspace.id}/workspace-services/${id}`, HttpMethod.Delete, workspaceCtx.workspaceApplicationIdURI)
    .then(() => {
      setChangeStateError(null);
      setPausedWorkspaceService(true);
      setIsLoading(false);
      setUpdatedService(id);
    })
    .catch((err: any) => {
      setIsLoading(false);
      setChangeStateError(err.message);
    })
  }

  const notificationCopy = selectedAction === "delete" ? (
    `The following workspace service is awaiting deletion: ${selectedWorkspaceService?.properties?.display_name}`
  ) : (
    `The following workspace service has been ${selectedAction}d: ${selectedWorkspaceService?.properties?.display_name}`
  );

  return (
    <>
      {location.state && (!changeStateError || !selectedWorkspaceService?.properties) && !confirmPauseDelete && (
        <NotificationBox
          error={null}
          text="The following workspace service has been updated: "
          updated={location.state.updated}
        />
      )}
      {errorData ? (
        <MessageCard msgData={errorData} />
      ) : (
        <section>
          {(changeStateError || selectedWorkspaceService?.properties) && confirmPauseDelete && (
            <NotificationBox
              error={changeStateError}
              text={notificationCopy}
            />
          )}
          <header>
            <Title>Manage workspace services</Title>
            {workspaceCtx.workspace.properties && <Subtitle>{workspaceCtx.workspace.properties.display_name}</Subtitle>}
          </header>
          <LoadingBox loading={isLoading}>
            <div className="manage-workspace-services__container">
              <Selectbox
                className=""
                label="Filter by"
                options={
                  [
                    {
                      name: "Active",
                      value: "active"
                    },
                    {
                      name: "Inactive",
                      value: "inactive"
                    }
                  ]
                }
                onChange={(e: any) => onSort(e)}
              />
              {deploying ? (
                <p>Currently deploying a workspace service, once it has finished you can create another</p>
              ) : (
                <Button
                  buttonColour="#3f78c0"
                  className="workspace-services__create-new"
                  icon={<ButtonArrow />}
                  onClick={() => routeChange()}
                  start
                >
                  Create new workspace service
                </Button>
              )}
            </div>
            {workspaceServices && (
              <Services
                services={workspaceServices}
                onPause={onPause}
                onDelete={onDelete}
                selectedAction={selectedAction}
                setSelectedAction={setSelectedAction}
                selectedWorkspaceService={selectedWorkspaceService}
                setSelectedWorkspaceService={setSelectedWorkspaceService}
                setConfirmPauseDelete={setConfirmPauseDelete}
                updatedService={updatedService}
                setRefresh={setRefresh}
                sort={sort}
                onSort={onSort}
              />
            )}
          </LoadingBox>
        </section>
      )}
    </>
  )
}

const Services = ({
  services,
  onPause,
  onDelete,
  selectedAction,
  setSelectedAction,
  selectedWorkspaceService,
  setSelectedWorkspaceService,
  setConfirmPauseDelete,
  updatedService,
  setRefresh,
  sort, 
  onSort
}: any) => {
  const [modalOpen, setModalOpen] = useState(false);

  const tableHeaders = [
    {
      header: "Workspace name"
    },
    {
      header: "Service"
    },
    {
      header: "Status",
      colSpan: 4
    }
  ];

  const openModal = (service: any, action: string) => {
    setModalOpen(true);
    setSelectedWorkspaceService(service);
    setSelectedAction(action);
  }

  const confirmAction = () => {
    selectedAction === "delete" ? onDelete(selectedWorkspaceService.id) : onPause(selectedWorkspaceService.id, selectedWorkspaceService.workspaceId, selectedWorkspaceService._etag, !selectedWorkspaceService.isEnabled);
    setModalOpen(false);
    setConfirmPauseDelete(true);
  }

  const cancelAction = () => {
    setConfirmPauseDelete(false);
    setModalOpen(false);
  }

  const actionCopy = selectedAction === "delete" ? (
    "You are about to delete this workspace service, are you sure you want to delete this workspace service permanently?"
  ) : (
    `You are about to ${selectedAction} this workspace service temporarily, are you sure you want to ${selectedAction} this workspace service?`
  );

  return (
    <>
      {modalOpen && (
        <Modal
          action={selectedAction}
          copy={actionCopy}
          onAction={() => confirmAction()}
          onExit={() => cancelAction()}
          title={`Are you sure you want to ${selectedAction} this workspace service?`}
        />
      )}
      <table className="manage-workspace-services__table">
        <TableHead headers={tableHeaders} />
        <tbody>
          {services.map((service: any) => (
            service.properties && (
              <Service
                key={service.id}
                service={service}
                openModal={openModal}
                updatedService={updatedService}
                setRefresh={setRefresh}
                sort={sort}
                onSort={onSort}
              />
            )
          ))}
        </tbody>
      </table>
    </>
  )
}

const Service = ({ service, openModal, updatedService, setRefresh, sort, onSort}: any) => {
  const workspaceCtx = useContext(WorkspaceContext);
  const { instance, accounts } = useMsal();
  const [operations, setOperations] = useState<any>();
  const [updating, setUpdating] = useState(false);

  const callOperations = () => {
    instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
      scopes: [`${workspaceCtx.workspace.properties.scope_id}/${process.env.REACT_APP_TRE_CORE_API_USER_IMPERSONATION}`]
    }).then(async (response) => {
      await CallApiWithToken(
        response.accessToken,
        `${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${workspaceCtx.workspace.id}/${ApiEndpoint.WorkspaceServices}/${service.id}/${ApiEndpoint.Operations}`,
        HttpMethod.Get,
        ''
      ).then(response => {
        setOperations(response.operations);
        CheckOps(service, response.operations, setUpdating, setRefresh, updating);
      })
    })
  }

  useEffect(() => {
    workspaceCtx.workspace.properties && callOperations()
  }, [workspaceCtx]);

  useEffect(() => {
    updatedService === service.id && callOperations()
  }, [updatedService]);

  useEffect(() => {
    sort && onSort(sort)
  }, [updating]);

  return (
    <ServiceItem
      updating={updating}
      service={service}
      openModal={openModal}
      operations={operations}
      callOperations={callOperations}
    />
  )
}

const ServiceItem = ({updating, service, openModal, callOperations}: any) => {
  const params = useParams();
  const getServiceName = (name: string) => {
    if (name.includes("gitea")) {
      return "gitea";
    } else if (name.includes("guacamole")) {
      return "guacamole";
    } else if (name.includes("ohdsi")) {
      return "atlas";
    } else {
      return null
    }
  }

  useInterval(() => callOperations(), updating ? 10000 : null);

  return (
    <tr>
      <Td>
        {getServiceName(service.templateName) === "guacamole" ? (
          <Link to={`/manage-workspaces/${params.id}/manage-workspace-services/${service.id}/manage-user-resources`}>{service.properties.display_name}</Link>
        ) : (
          service.properties.display_name
        )}
      </Td>
      <Td>
        <Tag backgroundColor="#80224d">{getServiceName(service.templateName)}</Tag>
      </Td>
      <Td>
      {updating ? (
          <Tag backgroundColor="#eeefef" color="#383f43">Updating</Tag>
        ) : (
          service.isEnabled ? <Tag tint="GREEN">Active</Tag> : <Tag tint="RED">Inactive</Tag>
        )}
      </Td>
      <Td>
        {updating ? (
          <>loading...</>
        ) : (
          !service.isEnabled && <TextButton onClick={() => openModal(service, "delete")}>Delete</TextButton>
        )}
      </Td>
      <Td>
        {updating ? (
          <>loading...</>
        ) : (
          <Link
            to={`/manage-workspaces/${service.workspaceId}/manage-workspace-services/${service.id}/update-workspace-service`}
          >
            Update
          </Link>
        )}
      </Td>
      <Td>
        {updating ? (
          <>loading...</>
        ) : (
          <TextButton onClick={() => openModal(service, service.isEnabled ? "pause" : "unpause")}>
            {service.isEnabled ? "Pause" : "Unpause"}
          </TextButton>
        )}
      </Td>
    </tr>
  )
}
