
import { UnauthenticatedTemplate, useMsal, AuthenticatedTemplate } from "@azure/msal-react";
import { Home } from "../../pages/Home";
import { loginRequest } from "../Core/authConfig";
import "./Layoutstyle.css";

/**
 * Renders the navbar component with a sign-in or sign-out button depending on whether or not a user is authenticated
 * @param props 
 */
export const MainDisplay = (props: { children: any; }) => {    
  const { instance } = useMsal();
  const HhandleLogin = (loginType: string) => {        
    if (loginType === "popup") {
      instance.loginPopup(loginRequest).then(e=>{                     
          }
            ).catch(e => {
          console.log(e);
      });
    } else if (loginType === "redirect") {
      instance.loginRedirect(loginRequest).catch(e => {
          console.log(e);
      });            
    }
  }

  return (
    <main role="main" id="content">
      <UnauthenticatedTemplate>
        <Home/>
      </UnauthenticatedTemplate> 
      <AuthenticatedTemplate>
        {props.children}        
      </AuthenticatedTemplate>
    </main>
  );
};
