import React from 'react';
import { Heading } from 'govuk-react';

import './SummaryList.css';

type SummaryListProps = {
  children?: React.ReactNode;
  heading?: string;
  greyHeader?: boolean;
  data?: {
    key: string,
    value: string
  }[]
}

export const SummaryList = ({ heading = "Overview", data, greyHeader, children }: SummaryListProps) => {
  return (
    <article className="summary-list">
      <header className={`summary-list__heading-wrapper${greyHeader ? " summary-list__heading-wrapper--grey" : ""}`}>
        <Heading className={`summary-list__heading${greyHeader ? " summary-list__heading--grey" : ""}`} size="SMALL" as="h2">{heading}</Heading>
      </header>
      <div className="summary-list__body">
        {data && (
          <dl className="summary-list__row-wrapper">
            {data.map((item: any) => {
              return (
                <div className="summary-list__row">
                  <dt className="summary-list__key">{item.key}</dt>
                  <dd className="summary-list__value">{item.value}</dd>
                </div>
              )
            })}
          </dl>
        )}
        {children}
      </div>
    </article>
  )
}
