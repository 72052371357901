import { useParams } from "react-router";


/**
 * Renders the navbar component with a sign-in or sign-out button depending on whether or not a user is authenticated
 * @param props 
 */
export const FooterBar = (props: { children: any; }) => {
 
    return (
        <>
            <footer className="govuk-footer app-footer app-footer--full" role="contentinfo">
                <div className="govuk-width-container app-site-width-container">
                        <div className="footer-logos">
                    <a className="footer-logos_nihr external-no-style" href="https://www.nihr.ac.uk/" rel="external" target="_blank">
                        <img alt="NIHR logo" src={`${process.env.REACT_APP_UI_REDIRECT_URL}assets/images/nihr-logo.png`} />
                    </a>
                </div>

        

                  <div className="govuk-footer__meta">
                  <div className="govuk-footer__meta-item govuk-footer__meta-item--grow">
                      <h2 className="govuk-visually-hidden">Support links</h2>
                
                          <ul className="govuk-footer__inline-list">
                          <li className="govuk-footer__inline-list-item">
        <a href="https://cprd.com/accessibility-statement" className="govuk-footer__link external-link" target="_blank" rel="noopener">Accessibility Statement<svg className="external-link-icon" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="butt"><g fill="none" fillRule="evenodd"><path d="M18 14v5a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8c0-1.1.9-2 2-2h5M15 3h6v6M10 14L20.2 3.8"></path></g></svg></a>
              </li>                          
              <li className="govuk-footer__inline-list-item">
        <a href="https://www.cprd.com/contact" className="govuk-footer__link external-link" target="_blank" rel="noopener">Contact<svg className="external-link-icon" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="butt"><g fill="none" fillRule="evenodd"><path d="M18 14v5a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8c0-1.1.9-2 2-2h5M15 3h6v6M10 14L20.2 3.8"></path></g></svg></a>
              </li>
                <li className="govuk-footer__inline-list-item">
        <a href="https://www.cprd.com/privacy-notice" className="govuk-footer__link external-link" target="_blank" rel="noopener">Cookies<svg className="external-link-icon" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="butt"><g fill="none" fillRule="evenodd"><path d="M18 14v5a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8c0-1.1.9-2 2-2h5M15 3h6v6M10 14L20.2 3.8"></path></g></svg></a>
              </li>
                <li className="govuk-footer__inline-list-item">
        <a href="https://www.cprd.com/privacy-notice" className="govuk-footer__link external-link" target="_blank" rel="noopener">Privacy<svg className="external-link-icon" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="butt"><g fill="none" fillRule="evenodd"><path d="M18 14v5a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8c0-1.1.9-2 2-2h5M15 3h6v6M10 14L20.2 3.8"></path></g></svg></a>
              </li>
                <li className="govuk-footer__inline-list-item">
        <a href="https://cprd.com/terms-and-conditions" className="govuk-footer__link external-link" target="_blank" rel="noopener">Terms &amp; Conditions<svg className="external-link-icon" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="butt"><g fill="none" fillRule="evenodd"><path d="M18 14v5a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8c0-1.1.9-2 2-2h5M15 3h6v6M10 14L20.2 3.8"></path></g></svg></a>
              </li>
                    </ul>
                        </div>
                        <div className="govuk-footer__meta-item">
                            <a className="govuk-footer__link govuk-footer__copyright-logo external-link" href="https://www.nationalarchives.gov.uk/information-management/re-using-public-sector-information/uk-government-licensing-framework/crown-copyright/" target="_blank">Crown Copyright © 2022 | All Rights Reserved
                            <svg className="external-link-icon" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="butt" ><g fill="none" fillRule="evenodd"><path d="M18 14v5a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8c0-1.1.9-2 2-2h5M15 3h6v6M10 14L20.2 3.8"></path></g></svg>
                            </a>
                        </div>                        
                    </div>
                    <div>
                        {process.env.REACT_APP_UI_VERSION}
                    </div>
                </div>

            </footer>
            {props.children}
        </>
    );
};
