import { Dispatch, SetStateAction } from "react";
import yn from "yn";

export const OnlineStatus = async (setIsOnline: Dispatch<SetStateAction<boolean>>, setLoading: Dispatch<SetStateAction<boolean>>) => {
  const condition = navigator.onLine ? 'online' : 'offline';
  if (condition === 'online') {
    yn(process.env.REACT_APP_DEBUG) && console.log('ONLINE');
    await fetch(process.env.REACT_APP_INTERNET_CHECK!, { // Check for internet connectivity
      mode: 'no-cors',
    }).then(() => {
        yn(process.env.REACT_APP_DEBUG) &&  console.log('CONNECTED TO INTERNET');
        setIsOnline(true);
        setLoading(false);
      }).catch(() => {
          yn(process.env.REACT_APP_DEBUG) &&  console.log('INTERNET CONNECTIVITY ISSUE');
          setIsOnline(false);
          setLoading(false);
      })
  } else{
    yn(process.env.REACT_APP_DEBUG) && console.log('OFFLINE')
    setIsOnline(false);
    setLoading(false);
  }
}
