import React from 'react';

import './Lede.css';

type LedeProps = {
  children: React.ReactNode;
  className?: string;
}

export const Lede = ({ children, className }: LedeProps) => {
  const classNames = `lede${className ? ` ${className}` : ''}`;
  return (
    <h3 className={classNames}>{children}</h3>
  )
}
