import React from 'react';

export const SearchIcon = () => {
  return (
    <svg aria-labelledby="title" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title id="title" lang="en">Search</title>
      <path fillRule="evenodd" clipRule="evenodd" d="M8.90373 2.96441C6.03343 2.96441 3.7066 5.29125 3.7066 8.16154C3.7066 11.0318 6.03343 13.3587 8.90373 13.3587C11.774 13.3587 14.1009 11.0318 14.1009 8.16154C14.1009 5.29125 11.774 2.96441 8.90373 2.96441ZM1.48438 8.16154C1.48438 4.06395 4.80613 0.742188 8.90373 0.742188C13.0013 0.742188 16.3231 4.06395 16.3231 8.16154C16.3231 12.2591 13.0013 15.5809 8.90373 15.5809C4.80613 15.5809 1.48438 12.2591 1.48438 8.16154Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M18.8014 20.0016L12.4453 13.6456L14.0167 12.0742L20.3727 18.4302L18.8014 20.0016Z" fill="white"/>
    </svg>
  )
}
