import React from "react";
import { Link } from "react-router-dom";

import './BackLink.css';

type BackLinkProps = {
  children: React.ReactNode;
  to: string;
}

export const BackLink = ({ children, to }: BackLinkProps) => {
  return (
    <Link to={to} className="gds-back-link">{children}</Link>
  )
}
