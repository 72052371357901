import React from 'react';

import './SmallTitle.css';

type SmallTitleProps = {
  children: React.ReactNode;
}

export const SmallTitle = ({ children }: SmallTitleProps) => {
  return (
    <h3 className="small-title">{children}</h3>
  )
}
