import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Tag } from "govuk-react";

import { HttpMethod } from "../components/Core/fetch";
import { ApiEndpoint } from "../components/models/apiEndPoints";
import { MessageCard } from "../components/Error/MessageCard";
import { Title } from "../components/ui/Title";
import { TableHead } from "../components/ui/TableHead";
import { Td } from "../components/ui/GDS-components/Table";

import { useAuthApiCall } from "../components/hooks/useAuthAPICall";
import { NotificationBox } from "../components/ui/NotificationBox";
import { CheckOps } from "../services/CheckOps";
import { useInterval } from "../components/hooks/useInterval";

import "./SharedServices.css";

export const SharedServices = (props:any) => {
  const [services, setServices] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const apiCall = useAuthApiCall();
  const location: any = useLocation();

  const getSharedServices = async () => {
    try {
      const sharedServices = await apiCall(ApiEndpoint.SharedServices, HttpMethod.Get);
      setServices(sharedServices.sharedServices);
      setLoading(false);
    } catch (e: any) {
      setError(e);
      setLoading(false);
    }
  }

  useEffect(() => {
    setLoading(true);
    getSharedServices();
    refresh && setRefresh(false);
  }, [refresh]);

  const tableHeaders = [
    {
      header: "Shared service"
    },
    {
      header: "Description"
    },
    {
      header: "Status"
    }
  ];

  return (
    <>
      {location.state && (
        <NotificationBox
          error={null}
          text="The following shared service has been updated: "
          updated={location.state.updated}
        />
      )}
      <Title>Shared services</Title>
      {loading ? (
        <p>loading...</p>
      ) : (
        <>
          {error ? (
            <MessageCard msgData={error} />
          ) : (
            <table className="shared-services__table">
              <TableHead headers={tableHeaders} />
              <tbody>
                {services.length > 0 && services.map((service: any) => (
                  <SharedService 
                    key={service.id}
                    service={service}
                    setRefresh={setRefresh}
                  />
                ))}
              </tbody>
            </table>
          )}
        </>
      )}
    </>
  );
}

const SharedService = ({ service, setRefresh }: any) => {
  const apiCall = useAuthApiCall();
  const [updating, setUpdating] = useState(false);

  const callOperations = async() => {
    await apiCall(`${ApiEndpoint.SharedServices}/${service.id}/${ApiEndpoint.Operations}`, HttpMethod.Get)
      .then(response => {
        CheckOps(service, response.operations, setUpdating, setRefresh, updating)
      })
  }

  useEffect(() => {
    callOperations();
  }, [])

  return (
    <SharedServiceItem
      service={service}
      updating={updating}
      callOperations={callOperations}
    />
  )
}

const SharedServiceItem = ({ service, updating, callOperations }: any) => {

  useInterval(() => callOperations(), updating ? 10000 : null);

  return (
    <tr key={service.id}>
      <Td>{service.properties.display_name}</Td>
      <Td>{service.properties.description}</Td>
      <Td>
        {updating ? (
          <Tag backgroundColor="#eeefef" color="#383f43">Updating</Tag>
        ) : (
          service.isEnabled ? <Tag tint="GREEN">Active</Tag> : <Tag tint="RED">Inactive</Tag>
        )}
      </Td>
    </tr>
  )
}
