import './Checkbox.css';

type CheckboxProps = {
  label: string;
  onChange?: (e: any) => void;
  id?: string;
  name?: string;
  hint?: string;
  disabled?: boolean;
  checked?: boolean;
}

export const Checkbox = ({ label, onChange, id, name, hint, disabled = false, checked }: CheckboxProps) => {
  return (
    <div className={`checkbox${disabled ? " checkbox--disabled" : ""}`} id={id}>
      <input
        className="checkbox__input"
        type="checkbox"
        aria-describedby={hint ? "hint" : label}
        disabled={disabled}
        onChange={onChange}
        checked={checked && checked}
      />
      <label className="checkbox__label" htmlFor={name ? name : label}>{label}</label>
      {hint && <p id="hint" className="checkbox__hint">{hint}</p>}
    </div>
  );
}
