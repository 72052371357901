import { useContext, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Table, Tag } from "govuk-react";
import { useMsal } from '@azure/msal-react';

import { loginRequest, trecoreConfig } from '../../Core/authConfig';
import { AppRolesContext } from '../../../contexts/AppRolesContext';
import { useInterval } from '../useInterval';
import { WorkspaceRoleName, RoleName } from '../../models/roleNames';
import { ApiEndpoint } from '../../models/apiEndPoints';
import { CallApiWithToken, HttpMethod } from '../../Core/fetch';
import { CheckOps } from '../../../services/CheckOps';
import { Td } from '../../ui/GDS-components/Table';

type useTableBodyProps = {
  sort: string | null | undefined;
  tableData: any;
  updatedWorkspace?: string | null;
  setRefresh?: React.Dispatch<React.SetStateAction<boolean>>;
  sortData: any;
  searchedTerm: string | null | undefined;
  onSearch: any;
  setWorkspaceUpdating: React.Dispatch<React.SetStateAction<boolean>>;
}

const TableBody = ({
  tableData,
  sort,
  updatedWorkspace,
  setRefresh,
  sortData,
  searchedTerm,
  onSearch,
  setWorkspaceUpdating
}: useTableBodyProps) => {
  return (
    <tbody>
      {tableData && tableData.length > 0 && tableData.map((data:any) => {
        return (
          <TableRow
            key={data.id}
            data={data}
            updatedWorkspace={updatedWorkspace}
            setRefresh={setRefresh}
            sort={sort}
            sortData={sortData}
            searchedTerm={searchedTerm}
            onSearch={onSearch}
            setWorkspaceUpdating={setWorkspaceUpdating}
          />
        );
      })}
    </tbody>
  );
};

const TableRow = ({ data, updatedWorkspace, setRefresh, sort, sortData, searchedTerm, onSearch, setWorkspaceUpdating }: any) => {
  const { instance, accounts } = useMsal();
  const [operations, setOperations] = useState<any>();
  const [updating, setUpdating] = useState(false);

  const callOperations = () => {
    instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
      scopes: trecoreConfig.scopes
    }).then(async (response) => {
      await CallApiWithToken(
        response.accessToken,
        `${trecoreConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${data.id}/${ApiEndpoint.Operations}`,
        HttpMethod.Get,
        ''
      ).then(response => {
        setOperations(response.operations);
        CheckOps(data, response.operations, setUpdating, setRefresh, updating);
      })
    })
  }

  useEffect(() => {
    callOperations();
  }, []);

  useEffect(() => {
    searchedTerm && onSearch(searchedTerm);
    sort && sortData(sort);
  }, [updating]);

  return (
    <TableRowItem
      data={data}
      updating={updating}
      callOperations={callOperations}
      setWorkspaceUpdating={setWorkspaceUpdating}
    />
  )
}

export default TableBody;

const TableRowItem = ({ data, updating, callOperations, setWorkspaceUpdating }: any) => {
  const appRolesCtx = useContext(AppRolesContext);
  const canViewCosts = (appRolesCtx.roles?.includes(WorkspaceRoleName.WorkspaceOwner) || appRolesCtx.roles?.includes(RoleName.TREAdmin));

  updating && setWorkspaceUpdating(true);

  useInterval(() => callOperations(), updating ? 10000 : null);

  return (
    <Table.Row key={data.id}>
      <Td>
        {updating || data.deploymentStatus === "deployment_failed" ? (
          data.properties.display_name
        ) : (
          <Link to={{ pathname: `/Workspaces/${data.id}` }} state={{ data: data.id }}>{data.properties.display_name}</Link>
        )}
      </Td>
      <Td>
        {appRolesCtx.roles?.includes(RoleName.TREAdmin) ? (
          data.id
        ) : (
          data.properties.description
        )}
      </Td>
      {canViewCosts && (
        <Td>
          {data.costs.length > 0 && Math.trunc(data.costs[0].credit_percentage_usage)}
        </Td>
      )}
      <Td>
        {updating ? (
          <Tag backgroundColor="#eeefef" color="#383f43">Updating</Tag>
        ) : (
          <Tag backgroundColor={data.isEnabled ? '#bfe3e0' : '#f6d6d2'} color={data.isEnabled ? '#10403c' : '#942514'}>{data.isEnabled ? "active" : "inactive"}</Tag>
        )}
      </Td>
      <Td>
        {updating ? (
          <Tag backgroundColor="#eeefef" color="#383f43">Updating</Tag>
        ) : (
          <Tag backgroundColor="#eeefef" color="#383f43">{data.deploymentStatus}</Tag>
        )}
      </Td>
    </Table.Row>
  )
}
