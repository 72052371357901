import React from "react";

import { Th } from "./GDS-components/Table";

import "./TableHead.css";

type TableHeadProps = {
  headers: {
    header: string;
    colSpan?: number;
  }[]
}

export const TableHead = ({ headers }: TableHeadProps) => {
  return (
    <thead>
      <tr>
        {headers.map((header: any, i: number) => <Th key={`${header.header}-${i}`} colspan={header.colSpan}>{header.header}</Th>)}
      </tr>
    </thead>
  )
}
