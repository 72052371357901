import { useContext } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated } from "@azure/msal-react";
import { Navbar } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { AppRolesContext } from "../../contexts/AppRolesContext";
import { RoleName } from "../models/roleNames";

import { SignOutButton } from "../Login/SignOutButton";
import { SubNav } from "../ui/SubNav";

import './NavigationBar.css';

/**
 * Renders the navbar component with a sign-in or sign-out button depending on whether or not a user is authenticated
 * @param props 
 */
export const NavigationBar = () => {
  const isAuthenticated = useIsAuthenticated();
  const appRolesCtx = useContext(AppRolesContext);
  const location = useLocation();

  const isHome = location.pathname.includes('Home') || location.pathname === '';
  const homeClassNames = `btn font-weight-bold navigation-bar__item${isHome ? ' navigation-bar--current' : ''}`;

  const isProfile = location.pathname.includes('profile');
  const profileClassNames = `btn font-weight-bold navigation-bar__item${isProfile ? ' navigation-bar--current' : ''}`;

  const isWorkspaces = location.pathname.includes('/workspaces') || location.pathname.includes('/Workspaces');
  const workspaceClassNames = `btn font-weight-bold navigation-bar__item${isWorkspaces ? ' navigation-bar--current' : ''}`;

  const isSharedServices = location.pathname.includes('shared-services');
  const sharedServicesClassNames = `btn font-weight-bold navigation-bar__item${isSharedServices ? ' navigation-bar--current' : ''}`;

  const isSupport = location.pathname.includes('support');
  const supportClassNames = `btn font-weight-bold navigation-bar__item navigation-bar__last-link${isSupport ? ' navigation-bar--current' : ''}`;

  return (
    <>
      <div className="region region-pre-content">        
        <div id="block-mainnavigation" className="block block-superfish block-superfishmain">
          <AuthenticatedTemplate>
            <div id="superfish-main" className="navigation-bar__wrapper sf-menu sf-main sf-horizontal sf-style-default cprd-menu sf-js-enabled">                     
              <Navbar className="navigation-bar__navbar">
                <Link to='/HomeLink' className={homeClassNames}>Home</Link>
                <Link to='/profile' className={profileClassNames}>Profile</Link>
                <Link to='/Workspaces' className={workspaceClassNames}>Workspaces</Link>
                {appRolesCtx.roles?.includes(RoleName.TREAdmin) && (
                  <Link to="/shared-services" className={sharedServicesClassNames}>Shared services</Link>
                )}
                <Link to="/support" className={supportClassNames}>Support</Link>
                <SignOutButton />
              </Navbar>
            </div>
          </AuthenticatedTemplate>
          {(appRolesCtx.roles?.includes(RoleName.TREAdmin)) && (
            <AuthenticatedTemplate>
              <SubNav />
            </AuthenticatedTemplate>
          )}
        </div>
      </div>
    </>
  );
};
