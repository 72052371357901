import React from 'react';

import './Heading.css';

type HeadingProps = {
  children: React.ReactNode;
}

export const Heading = ({ children }: HeadingProps) => {
  return (
    <h3 className="heading">{children}</h3>
  )
}
