import React, { useRef, useState, useContext } from 'react';
import { useMsal } from '@azure/msal-react';
import { useNavigate, useParams } from 'react-router-dom';
import yn from 'yn';
import { LabelText, HintText, Input, Button, ErrorText, ErrorSummary } from 'govuk-react';

import { NewWorkspaceServiceRequest } from '../models/workspaceServices';
import { WorkspaceContext } from '../../contexts/WorkspaceContext';
import { loginRequest, trecoreServicesConfig } from '../Core/authConfig';
import { ApiEndpoint } from '../models/apiEndPoints';
import { CallApiWithToken, HttpMethod } from '../Core/fetch';
import { CheckServiceDeployments } from '../../services/CheckServiceDeployments';
import { MessageCard } from '../Error/MessageCard';
import { Textarea } from '../ui/Textarea';
import { FormWrapper } from '../ui/FormWrapper';
import { FormLabel } from '../ui/FormLabel';
import { FormButtonContainer } from '../ui/FormButtonContainer';
import { CancelButton } from '../ui/CancelButton';

import { error } from '../../types';

export const CreateAtlasForm = (props: any) => {
  const { instance, accounts } = useMsal();
  const workspaceCtx = useContext(WorkspaceContext);
  const [errors, setErrors] = useState<null | error[]>(null);
  const [errorData, setErrorData] = useState(null);

  let { id } = useParams();
  let navigate = useNavigate();
  const templateNameInputRef = useRef<HTMLInputElement>(null);
  const wsNameInputRef = useRef<HTMLInputElement>(null);
  const descriptionInputRef = useRef<HTMLTextAreaElement>(null);
  const uiVersionInputRef = useRef<HTMLInputElement>(null);
  const apiVersionInputRef = useRef<HTMLInputElement>(null);
  
  const createAtlasHandler = (event: any) => {
    event.preventDefault();
    const templateName = templateNameInputRef.current?.value;
    const description = descriptionInputRef.current?.value;
    const name = wsNameInputRef.current?.value;
    const uiVersion = uiVersionInputRef.current?.value;
    const apiVersion = apiVersionInputRef.current?.value;

    const properties: NewWorkspaceServiceRequest["properties"] = {
      display_name: name,
      description: description,
      atlas_ui_version: uiVersion,
      ohdsi_api_version: apiVersion
    };

    const newWorkspaceRequest: NewWorkspaceServiceRequest = {
      templateName: templateName,
      properties: properties
    }

    yn(process.env.REACT_APP_DEBUG) && console.log('NewWorkspace is: ', newWorkspaceRequest);

    props.onAddImportRequest(newWorkspaceRequest);
  };

  const routeChange = () => {
    let path = `/workspaces/${id}`;
    navigate(path);
  }

  const apiUrl = `${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${id}/${ApiEndpoint.WorkspaceServices}`;

  const checkDeployments = (e: any) => {
    instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
      scopes: [`${workspaceCtx.workspace.properties.scope_id}/${process.env.REACT_APP_TRE_CORE_API_USER_IMPERSONATION}`]
    }).then(async (response) => {
      await CallApiWithToken(
        response.accessToken,
        apiUrl,
        HttpMethod.Get,
        ''
      ).then(response => {
        const deploying = response.workspaces.filter((item: any) => item.deploymentStatus === "deploying" || item.deploymentStatus === "awaiting_deployment");
        deploying.length > 0 ? (
          setErrors([{
            targetName: "",
            text: "A workspace is currently being deployed, you can create another workspace once this has completed"
          }])
        ) : (
          createAtlasHandler(e)
        )
      }).catch((err: any) => {
        setErrorData(err);
      })
    })
  }

  const hasError = (formItem: string) => {
    const checkError = (obj: error) => obj.targetName === formItem;
    return errors && errors.some(checkError);
  }

  const onSubmit = (e: any) => {
    e.preventDefault();
    const description = descriptionInputRef.current?.value;
    const name = wsNameInputRef.current?.value;
    const uiVersion = uiVersionInputRef.current?.value;
    const apiVersion = apiVersionInputRef.current?.value;

    setErrors(null);
    const newErrors = [];
    if (!name || (name.length < 5 || name.length > 100)) {
      newErrors.push({
        targetName: "atlas-service-name",
        text: "Enter a valid name"
      })
    }

    if (!description || (description.length < 10 || description.length > 100)) {
      newErrors.push({
        targetName: "description",
        text: "Enter a valid description, minimum of 10 characters and maximum of 100"
      })
    }

    if (!uiVersion || (uiVersion.length < 5 || uiVersion.length > 10)) {
      newErrors.push({
        targetName: "atlas-ui-version",
        text: "Enter a valid ui version"
      })
    }

    if (!apiVersion || (apiVersion.length < 5 || apiVersion.length > 10)) {
      newErrors.push({
        targetName: "ohdsi-api-version",
        text: "Enter a valid API version"
      })
    }

    return newErrors.length > 0 ? (
      setErrors(newErrors),
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    ) : (
      CheckServiceDeployments(
        instance,
        accounts,
        workspaceCtx.workspace.properties.scope_id,
        e,
        setErrors,
        setErrorData,
        createAtlasHandler,
        id,
        'ohdsi'
      ),
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    );
  }

  return (
    <>
      {errorData && (
        <MessageCard msgData={errorData} />
      )}
      {errors && errors.length > 0 && (
        <ErrorSummary
          errors={errors}
          heading="There is a problem submitting your request"
          onHandleErrorClick={(e: any) => document.getElementById(e)?.scrollIntoView()}
        />
      )}
      <form onSubmit={onSubmit}>
        <FormWrapper>
          <FormLabel>
            <LabelText>Template name</LabelText>
            <HintText>Template name for Atlas, which contains all configuration settings.</HintText>
            <Input ref={templateNameInputRef} defaultValue="tre-workspace-service-ohdsi" readOnly />
          </FormLabel>
          <FormLabel error={hasError("atlas-service-name")} id="atlas-service-name">
            <LabelText>Atlas service name</LabelText>
            <HintText>New Atlas name.</HintText>
            {hasError("atlas-service-name") && <ErrorText>Enter a valid name</ErrorText>}
            <Input ref={wsNameInputRef} defaultValue="Atlas Service" />
          </FormLabel>
          <FormLabel error={hasError("description")} id="description">
            <LabelText>Description</LabelText>
            <HintText>Description for the new Atlas service.</HintText>
            {hasError("description") && <ErrorText>Enter a valid description (max 100 characters)</ErrorText>}
            <Textarea forwardRef={descriptionInputRef} defaultValue="Atlas service" />
          </FormLabel>
          <FormLabel error={hasError("atlas-ui-version")} id="atlas-ui-version">
            <LabelText>Atlas ui version</LabelText>
            <HintText>The ui version of Atlas; "X.Y.Z"</HintText>
            {hasError("atlas-ui-version") && <ErrorText>Enter a valid ui version</ErrorText>}
            <Input ref={uiVersionInputRef} />
          </FormLabel>
          <FormLabel error={hasError("ohdsi-api-version")} id="ohdsi-api-version">
            <LabelText>OHDSI API version</LabelText>
            <HintText>The API version of OHDSI; "X.Y.Z"</HintText>
            {hasError("ohdsi-api-version") && <ErrorText>Enter a valid API version</ErrorText>}
            <Input ref={apiVersionInputRef} />
          </FormLabel>
          <FormButtonContainer>
            <Button type="submit" className="govuk-button" data-module="govuk-button">Create service</Button>
            <CancelButton onClick={() => routeChange()}>Cancel</CancelButton>
          </FormButtonContainer>
        </FormWrapper>
      </form>
    </>
  )
}
