import { Th } from "../../ui/GDS-components/Table";


const useTableHead = ({ columns }:{ columns:any, handleSorting?:any}) => {

  return (
    <thead>
      <tr>
        {columns.map(({ label, accessor }:{ label:any, accessor:any }) => {
          return (
            <Th key={accessor}>{label}</Th>
          );
        })}
      </tr>
    </thead>
  );
};

export default useTableHead;
