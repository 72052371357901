
import { User } from "./user";

export interface AirlockRequest {
  workspaceId:string
}

export enum AirlockRequestType {
    Import = 'import',
    Export = 'export'
  }

  export interface NewAirlockRequest {
    type: AirlockRequestType;
    title: string;
    businessJustification: string;
  }

  export enum AirlockRequestStatus {
    Draft = 'draft',
    InReview = 'in_review',
    Approved = 'approved',
    ApprovalInProgress = 'approval_in_progress',
    RejectionInProgress = 'rejection_in_progress',
    Rejected = 'rejected',
    Blocked = 'blocked',
    BlockedByScan = "blocked_by_scan",
    BlockingInProgress = 'blocking_in_progress',
    Submitted = 'submitted',
    Cancelled = 'cancelled',
    Failed = 'failed'
  }

  export enum AirlockTriage {
    Accepted = "L1: Accepted",
    NoAcro = "L2a: No ACRO",
    Acro = "L2b: ACRO",
    Exemption = "L3: Exemption",
    Rejected = "L4: Rejected",
    TriageRejection = "L4a: Rejected"
  }

  export enum AirlockTokenStatus {
    NotAttempted =1,
    Generated = 2,
    NotGenerated = 3    
  }


  export enum AirlockReviewDecision {
    Approved = 'approved',
    Rejected = 'rejected'
  }
  
  export interface AirlockReview {
    id: string,
    dateCreated: number,
    reviewDecision: AirlockReviewDecision,
    decisionExplanation: string,
    reviewer: User
  }

  export interface ReviewTemp  {
    approval: string,
    decisionExplanation: string
  };