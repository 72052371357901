import { useContext } from "react";
import { GridRow, GridCol, Paragraph } from "govuk-react";

import { AppRolesContext } from "../contexts/AppRolesContext";
import { GetTag } from "../components/ui/GetTag";

import './ProfileData.css';

/**
 * Renders information about the user obtained from MS Graph
 * @param props 
 */
export const ProfileData = (props: any) => {
  const user = useContext(AppRolesContext);

  const getInitials = () => {
    const firstInitial = props.graphData.givenName ? Array.from(props.graphData.givenName)[0] : '';
    const lastInitial = props.graphData.surname ? Array.from(props.graphData.surname)[0] : '';

    return `${firstInitial}${lastInitial}`;
  }
  
  return (
    <GridRow>
      <GridCol setWidth="one-third">
        <p className="profile-data__avatar">
          <span>{getInitials()}</span>
        </p>
        <h3 className="profile-data__bold-heading">Location</h3>
        <Paragraph>{props.graphData.officeLocation}</Paragraph>
        <h3 className="profile-data__bold-heading">Role</h3>
        {user.roles && user.roles.length > 0 && <GetTag roles={user.roles} />}
      </GridCol>
      <GridCol setWidth="two-thirds">
        <dl className="govuk-summary-list" id="profile-div">
          <div className="govuk-summary-list__row" >
            <dt className="govuk-summary-list__key">First name</dt>
            <dd className="govuk-summary-list__value">{props.graphData.givenName}</dd>
          </div>
          <div className="govuk-summary-list__row" >
            <dt className="govuk-summary-list__key">Last name</dt>
            <dd className="govuk-summary-list__value">{props.graphData.surname}</dd>
          </div>
          <div className="govuk-summary-list__row" >
            <dt className="govuk-summary-list__key">Email address</dt>
            <dd className="govuk-summary-list__value">{props.graphData.mail}</dd>
          </div>
          <div className="govuk-summary-list__row" >
            <dt className="govuk-summary-list__key">ID</dt>
            <dd className="govuk-summary-list__value">{props.graphData.id}</dd>
          </div>
          <div className="govuk-summary-list__row" >
            <dt className="govuk-summary-list__key">Organisation</dt>
            <dd className="govuk-summary-list__value">{props.companyName}</dd>
          </div>
          <div className="govuk-summary-list__row" >
            <dt className="govuk-summary-list__key">Phone</dt>
            <dd className="govuk-summary-list__value">{props.graphData.mobilePhone}</dd>
          </div>
        </dl>
      </GridCol>
    </GridRow>
  );
};
