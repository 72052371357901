import React, { useContext, useEffect, useState } from "react";
import { Button, ButtonArrow, Tag } from "govuk-react";
import { Link, useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { AppRolesContext } from "../../contexts/AppRolesContext";
import { RoleName, WorkspaceRoleName } from "../models/roleNames";
import { WorkspaceContext } from "../../contexts/WorkspaceContext";
import { Selectbox } from "../ui/Selectbox";
import { loginRequest, trecoreServicesConfig } from "../Core/authConfig";
import { CallApiWithToken, HttpMethod } from "../Core/fetch";
import { ApiEndpoint } from "../models/apiEndPoints";
import { CheckOps } from "../../services/CheckOps";
import { useInterval } from "../hooks/useInterval";
import { Lede } from "../ui/Lede";
import { CostFigures } from "../ui/CostFigures";
import { Th, Td } from "../ui/GDS-components/Table";

import './TRECoreWorkspaceServicesData.css';

/**
 * Renders information about the workspace obtained from TRE Core API
 * @param props 
 */

 export const TRECoreWorkspaceServicesData = (props: any) => {
  const [fullListWsServices, setFullListWsServices] = useState([]);
  const [sortedServices, setSortedServices] = useState([]);
  const [wsServiceUpdating, setWsServiceUpdating] = useState(false);
  const [sort, setSort] = useState<string | null>();
  const [deploymentFailed, setDeploymentFailed] = useState(false);
  let navigate = useNavigate();
  const appRolesCtx = useContext(AppRolesContext);
  const workSpaceCtx = useContext(WorkspaceContext);
  const { updatedService, setRefresh, trecoreData } = props;

  useEffect(() => {
    setFullListWsServices(trecoreData.workspaceServices);
    setSortedServices(trecoreData.workspaceServices);
    setWsServiceUpdating(false);
    workSpaceCtx.workspace && setDeploymentFailed(workSpaceCtx.workspace.deploymentStatus === "deployment_failed");
  }, [trecoreData, workSpaceCtx]);

  const routeChange = () => {
    let path = 'CreateNewWorkspaceService'; 
    navigate(path);
  }

  const onSort = (e: any) => {
    setSort(e);
    const newList = fullListWsServices;

    const sortData = () => newList.filter((service: any) => {
      return service.templateName.includes(e);
    })

    setSortedServices(e === null ? fullListWsServices : sortData());
  }

  return (        
    <div className="workspace-services">
      {deploymentFailed ? (
        <Lede>Deployment failed. Speak to your administrator</Lede>
      ) : (
        <>
          {workSpaceCtx.workspace.properties !== undefined && (
            <h1 className="workspace-service__title">{workSpaceCtx.workspace.properties.display_name}</h1>
          )}
          <h2 className="workspace-services__subheader">Workspace services</h2>
          {(appRolesCtx.roles?.includes(RoleName.TREAdmin) || (workSpaceCtx.roles.includes(WorkspaceRoleName.WorkspaceOwner))) && <CostFigures id={workSpaceCtx.workspace.id} />}
          <div className="workspace-services__container">
            <Selectbox
              label="Filter by"
              options={[
                {
                  name: "Guacamole",
                  value: "guacamole"
                },
                {
                  name: "Gitea",
                  value: "gitea"
                },
                {
                  name: "Atlas",
                  value: "ohdsi"
                }
              ]}
              onChange={(e: any) => onSort(e)}
            />
            {(appRolesCtx.roles?.includes(RoleName.TREAdmin) || (workSpaceCtx.roles.includes(WorkspaceRoleName.WorkspaceOwner))) && (
              <>
                {wsServiceUpdating ? (
                  <p>Currently deploying a workspace service, once it has finished you can create another</p>
                ) : (
                  workSpaceCtx.workspace.isEnabled && (
                    <Button
                      buttonColour="#3f78c0"
                      className="workspace-services__create-new"
                      icon={<ButtonArrow />}
                      onClick={() => routeChange()}
                      start
                    >
                      Create new workspace service
                    </Button>
                  )
                )}
              </>
            )}
          </div>
          {!workSpaceCtx.workspace.isEnabled && <Lede className="workspace-services__notification">This workspace is currently paused</Lede>}
          <table className="table-component">                
            <thead>
              <tr className="govuk-table__row">
                <Th>Name</Th>
                {(appRolesCtx.roles?.includes(RoleName.TREAdmin)) && <Th>Id</Th>}
                <Th>Workspace service</Th>
                <Th>Active</Th> 
                {(appRolesCtx.roles?.includes(RoleName.TREAdmin)) && (
                  <Th>Status</Th>
                )}
              </tr>
            </thead>
            <tbody>
              {sortedServices && (
                <TRECoreWorkspaceServiceDetailData
                  services={sortedServices}
                  updatedService={updatedService}
                  setRefresh={setRefresh}
                  sort={sort}
                  onSort={onSort}
                  setWsServiceUpdating={setWsServiceUpdating}
                />
              )}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

const TRECoreWorkspaceServiceDetailData = ({ services, setRefresh, sort, onSort, setWsServiceUpdating }: any) => {
  return (
    <>
      {services.length > 0 && services.map((workspaceService: any) => (
        <Service
          key={workspaceService.id}
          workspaceService={workspaceService}
          setRefresh={setRefresh}
          sort={sort}
          onSort={onSort}
          setWsServiceUpdating={setWsServiceUpdating}
        />
      ))}
    </>
  )
};

const Service = ({ workspaceService, setRefresh, sort, onSort, setWsServiceUpdating }: any) => {
  const { instance, accounts } = useMsal();
  const workspaceCtx = useContext(WorkspaceContext);
  const [operations, setOperations] = useState<any>();
  const [updating, setUpdating] = useState(false);

  const callOperations = () => {
    instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
      scopes: [`${workspaceCtx.workspace.properties.scope_id}/${process.env.REACT_APP_TRE_CORE_API_USER_IMPERSONATION}`]
    }).then(async (response: any) => {
      await CallApiWithToken(
        response.accessToken,
        `${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${workspaceCtx.workspace.id}/${ApiEndpoint.WorkspaceServices}/${workspaceService.id}/${ApiEndpoint.Operations}`,
        HttpMethod.Get,
        ''
      ).then(response => {
        setOperations(response.operations);
        CheckOps(workspaceService, response.operations, setUpdating, setRefresh, updating)
      })
    })
  }

  useEffect(() => {
    workspaceCtx.workspace.properties && callOperations()
  }, [workspaceCtx]);

  useEffect(() => {
    sort && onSort(sort);
  }, [updating]);

  return (
    <ServiceItem service={workspaceService} updating={updating} callOperations={callOperations} setWsServiceUpdating={setWsServiceUpdating} />
  )
}

const ServiceItem = ({ service, updating, callOperations, setWsServiceUpdating }: any) => {
  const appRolesCtx = useContext(AppRolesContext);
  const workSpaceCtx = useContext(WorkspaceContext);
  const getWsServiceType = (service: any) => {
    let serviceType: React.ReactNode = "";

    if (service.deploymentStatus === "deployment_failed") {
      return serviceType = <Tag tint="GREY">{service.deploymentStatus}</Tag>
    }

    if (service === undefined) return serviceType;

    if (service.templateName.includes("gitea")) {
      serviceType = <Tag backgroundColor="#80224d">gitea</Tag>;
    } else if (service.templateName.includes("guacamole")) {
      serviceType = <Tag backgroundColor="#80224d">guacamole</Tag>;
    } else if (service.templateName.includes("ohdsi")) {
      serviceType = <Tag backgroundColor="#80224d">atlas</Tag>;
    } else {
      serviceType = null
    }

    return serviceType;
  }

  updating && setWsServiceUpdating(true);

  useInterval(() => callOperations(), updating ? 10000 : null);

  return (
    <tr key={service.id}>
      <Td>
        {workSpaceCtx.workspace.isEnabled ? (
          (service.templateName=='tre-service-guacamole') && !updating ? (
            <Link
              to={{ pathname: "workspace-services/" + service.id + "/user-resources"}}
              state={{ stateObj: service }}
            >
              {service.properties.display_name}
            </Link>
          ) : (
            <p>{service.properties.display_name}</p>
          )
        ) : (
          appRolesCtx.roles?.includes(WorkspaceRoleName.WorkspaceOwner) || appRolesCtx.roles?.includes(RoleName.TREAdmin) ? (
            <Link
              to={{ pathname: "workspace-services/" + service.id + "/user-resources"}}
              state={{ stateObj: service }}
            >
              {service.properties.display_name}
            </Link>
          ) : (
            <p>{service.properties.display_name}</p>
          )
        )}
      </Td>
      {(appRolesCtx.roles?.includes(RoleName.TREAdmin)) && <Td>{service.id}</Td>}
      <Td>
        {getWsServiceType(service)}
      </Td>
      <Td>
        {updating ? (
          <Tag backgroundColor="#eeefef" color="#383f43">Updating</Tag>
        ) : (
          service.isEnabled ? <Tag tint="GREEN">Active</Tag> : <Tag tint="RED">Inactive</Tag>
        )}
      </Td>
      {(appRolesCtx.roles?.includes(RoleName.TREAdmin)) && updating && <Td><Tag backgroundColor="#eeefef" color="#383f43">Updating</Tag></Td>}
      {(appRolesCtx.roles?.includes(RoleName.TREAdmin)) && !updating && <Td><Tag tint="GREY">{service.deploymentStatus}</Tag></Td>}
    </tr>
  )
}
