import React from "react";
import { useMsal } from "@azure/msal-react";
import { Button } from "react-bootstrap";

/**
 * Renders a sign-out button
 */
export const SignOutButton = () => {
  const { instance } = useMsal();    
  
  const handleLogout = (logoutType: string) => {
    if (logoutType === "popup") {
      instance.logoutPopup({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/"
      });
    } else if (logoutType === "redirect") {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    }
    else if (logoutType === "direct") {
      instance.logout({
        postLogoutRedirectUri: "/",                
      });
    }
  }

  return (
    <Button
      type="button"
      variant="warning"
      title="Sign Out"
      onClick={() => handleLogout("redirect")}>Sign Out</Button>
  )
}
