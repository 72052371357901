import { Link } from 'react-router-dom';

import './NotificationBox.css';

type NotificationBoxProps = {
  error: null | string;
  text: any;
  updated?: string;
  linkPreText?: string;
  linkText?: string;
  linkDestination?: string;
  id?: string;
}

export const NotificationBox = ({ text, error, updated, linkText, linkDestination, linkPreText, id }: NotificationBoxProps) => {
  return (
    <div id={id} className={`notification-box${error ? ' notification-box--error' : ''}`}>
      <p className="notification-box__text">{error ? error : text} {updated ? <strong>{updated}</strong> : null}{(linkText && linkDestination && linkPreText) ? <>{linkPreText}<Link to={linkDestination}>{linkText}</Link></> : ''}</p>
    </div>
  )
}
