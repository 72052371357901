import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Heading, LoadingBox, HintText, SectionBreak, Button, WarningText } from 'govuk-react';
import { useMsal } from '@azure/msal-react';
import { loginRequest, trecoreServicesConfig } from '../Core/authConfig';
import { useAuthApiCall } from '../hooks/useAuthAPICall';

import { WorkspaceContext } from '../../contexts/WorkspaceContext';
import { CallApiWithToken, HttpMethod } from '../Core/fetch';
import { ApiEndpoint } from '../models/apiEndPoints';
import { SummaryList } from '../ui/GDS-components/SummaryList';
import { Fieldset } from '../ui/Fieldset';
import { RadioButton } from '../ui/RadioButton';
import { Checkbox } from '../ui/Checkbox';
import { BackLink } from '../ui/GDS-components/BackLink';
import { capitalizeFirst } from '../hooks/useStringFunc';
import { AirlockRequestStatus, AirlockRequestType } from '../models/airlock';

import './AirlockAuditScreen.css';
import { AppRolesContext } from '../../contexts/AppRolesContext';
import { IsOnlineContext } from '../../contexts/IsOnlineContext';

export const AirlockAuditScreen = () => {
  const ws = useParams();
  const { instance, accounts } = useMsal();
  const workspaceCtx = useContext(WorkspaceContext);

  const [loading, setLoading] = useState(true);
  const [request, setRequest] = useState<null | any>(null);

  useEffect(() => {
    setLoading(true);
    workspaceCtx.workspace.properties && (
      instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
        scopes: [`${workspaceCtx.workspace.properties.scope_id}/${process.env.REACT_APP_TRE_CORE_API_USER_IMPERSONATION}`]
      }).then(async (response) => {
        await CallApiWithToken(
          response.accessToken,
          `${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${ws.id}/${ApiEndpoint.AirlockRequests}/${ws.aid}`,
          HttpMethod.Get,
          ''
        ).then(response => {
          console.log("response: ", response);
          setRequest(response.airlockRequest);
          setLoading(false);
        }).catch((err: any) => {
          console.log("error: ", err);
          setLoading(false);
        })
      })
    )
  }, [workspaceCtx]);

  const getStageOne = () => {
    const data = [
      {
        key: "Destination workspace",
        value: workspaceCtx.workspace.properties && workspaceCtx.workspace.properties.display_name
      },
      {
        key: "Request name",
        value: request && request.title
      },
      {
        key: "Request justification",
        value: request && request.businessJustification
      },
      {
        key: "Name",
        value: request && request.files.length > 0 && request.files[0].name
      },
      {
        key: "Size",
        value: request && request.files.length > 0 && `${(request.files[0].size/(1024**2)).toFixed(2)} MB`
      }
    ];

    return data;
  }

  const getRequestorDetails = () => {
    const data = [
      {
        key: "Requestor",
        value: request && request.createdBy.name
      },
      {
        key: "Type",
        value: request && `${capitalizeFirst(request.type)} file`
      },
      {
        key: "Status",
        value: request && capitalizeFirst(request.status)
      },
      {
        key: "Workspace name",
        value: workspaceCtx.workspace.properties && workspaceCtx.workspace.properties.display_name
      },
      {
        key: "Created date",
        value: request && new Date(request.createdWhen * 1000).toLocaleDateString("en-GB")
      },
      {
        key: "Reason for request",
        value: request && request.businessJustification
      },
      {
        key: "File details",
        value: ""
      },
      {
        key: "File name",
        value: request && request.files.length > 0 ? request.files[0]?.name : "-"
      },
      {
        key: "File size",
        value: request && request.files.length > 0 ? `${(request.files[0]?.size/(1024**2)).toFixed(2)} MB` : "-"
      }
    ];

    return data;
  }

  const getSubmittedDate = (request: any) => {
    const historyItem = request.history.findLast(({ properties }: any) => properties.previousStatus === AirlockRequestStatus.Submitted);
    return new Date(historyItem.updatedWhen * 1000).toLocaleDateString("en-GB");
  }

  const getOverview = () => {
    const data = [
      {
        key: "Name",
        value: request && request.title
      },
      {
        key: "Airlock request ID",
        value: ws.aid
      },
      {
        key: "Workspace ID",
        value: ws.id
      },
      {
        key: "Workspace name",
        value: workspaceCtx.workspace.properties && workspaceCtx.workspace.properties.display_name
      },
      {
        key: "Requestor",
        value: request && request.createdBy.name
      },
      {
        key: "Created date",
        value: request && new Date(request.createdWhen * 1000).toLocaleDateString("en-GB")
      },
      {
        key: "Submitted date",
        value: getSubmittedDate(request)
      },
      {
        key: "Reviewed date",
        value: request && request.reviews.length > 0 ? new Date(request.reviews[0].dateCreated * 1000).toLocaleDateString("en-GB") : "-"
      },
      {
        key: "Status",
        value: request && capitalizeFirst(request.status)
      },
      {
        key: "Reason for decision",
        value: request && request.reviews.length > 0 ? request.reviews[0].decisionExplanation : "-"
      },
      {
        key: "File name",
        value: request && request.files.length > 0 ? request.files[0]?.name : "-"
      },
      {
        key: "File size",
        value: request && request.files.length > 0 ? `${(request.files[0]?.size/(1024**2)).toFixed(2)} MB` : "-"
      }
    ];

    return data;
  }

  console.log("request: ", request);

  const backToLink = request && request.type === AirlockRequestType.Import ? `/workspaces/${ws.id}/airlocks#imports` : `/workspaces/${ws.id}/airlocks#exports`;

  return (
    <LoadingBox loading={loading}>
      {workspaceCtx.workspace.properties && request && (
        <>
          <BackLink to={backToLink}>Back to my requests</BackLink>
          <Heading as="h1" size="LARGE">{workspaceCtx.workspace.properties.display_name}</Heading>
          <Heading as="h2" size="MEDIUM">New {request.type} request</Heading>
          <SummaryList heading="Set up information" data={getStageOne()} greyHeader />
          {request.triageStatements.length > 0 && (
            <>
              <SectionBreak level="XLARGE" visible />
              <SummaryList heading="File information" greyHeader>
                <Fieldset id="rdgConsistent">
                  <legend>
                    <p className="airlock-audit-screen__legend">Are requested outputs are consistent with the RDG approved protocol associated with this workspace?</p>
                  </legend>
                  <div className="airlock-audit-screen__radio-wrapper">
                    <RadioButton
                      label="Yes"
                      name="rdgConsistent-yes"
                      checked={request.triageStatements[0].rdgConsistent === true}
                      disabled
                    />
                    <RadioButton
                      label="No"
                      name="rdgConsistent-no"
                      checked={request.triageStatements[0].rdgConsistent === false}
                      disabled
                    />
                  </div>
                </Fieldset>
                <Fieldset id="patientLevelData">
                  <legend>
                    <p className="airlock-audit-screen__legend">Are there any event or patient level data are included in the requested outputs?</p>
                  </legend>
                  <div className="airlock-audit-screen__radio-wrapper">
                    <RadioButton
                      label="Yes"
                      checked={request.triageStatements[0].patientLevelData === true}
                      name="patientLevelData-yes"
                      disabled
                    />
                    <RadioButton
                      label="No"
                      checked={request.triageStatements[0].patientLevelData === false}
                      name="patientLevelData-no"
                      disabled
                    />
                  </div>
                </Fieldset>
                <Fieldset id="requestedOutputsClear">
                  <legend>
                    <p className="airlock-audit-screen__legend">All requested outputs are sufficiently clear and comprehensible to permit output checking without the need for dataset- or project-specific knowledge.</p>
                  </legend>
                  <div className="airlock-audit-screen__radio-wrapper">
                    <RadioButton
                      label="Yes"
                      checked={request.triageStatements[0].requestedOutputsClear === true}
                      name="requestedOutputsClear-yes"
                      disabled
                    />
                    <RadioButton
                      label="No"
                      checked={request.triageStatements[0].requestedOutputsClear === false}
                      name="requestedOutputsClear-no"
                      disabled
                    />
                  </div>
                </Fieldset>
                <Fieldset id="requestedOutputsStatic">
                  <legend>
                    <p className="airlock-audit-screen__legend">Are all requested outputs static?</p>
                  </legend>
                  <div className="airlock-audit-screen__radio-wrapper">
                    <RadioButton
                      label="Yes"
                      checked={request.triageStatements[0].requestedOutputsStatic === true}
                      name="requestedOutputsStatic-yes"
                      disabled
                    />
                    <RadioButton
                      label="No"
                      checked={request.triageStatements[0].requestedOutputsStatic === false}
                      name="requestedOutputsStatic-no"
                      disabled
                    />
                  </div>
                </Fieldset>
                <Fieldset id="requestedOutputsPermittedFiles">
                  <legend>
                    <p className="airlock-audit-screen__legend">Do all requested outputs use permitted file types?</p>
                  </legend>
                  <div className="airlock-audit-screen__radio-wrapper">
                    <RadioButton
                      label="Yes"
                      checked={request.triageStatements[0].requestedOutputsPermittedFiles === true}
                      name="requestedOutputsPermittedFiles-yes"
                      disabled
                    />
                    <RadioButton
                      label="No"
                      checked={request.triageStatements[0].requestedOutputsPermittedFiles === false}
                      name="requestedOutputsPermittedFiles-no"
                      disabled
                    />
                  </div>
                </Fieldset>
                <Fieldset id="hiddenInformation">
                  <legend>
                    <p className="airlock-audit-screen__legend">Is there any hidden information included (e.g., embedded files, comments, track changes).</p>
                  </legend>
                  <div className="airlock-audit-screen__radio-wrapper">
                    <RadioButton
                      label="Yes"
                      checked={request.triageStatements[0].hiddenInformation === true}
                      name="hiddenInformation-yes"
                      disabled
                    />
                    <RadioButton
                      label="No"
                      checked={request.triageStatements[0].hiddenInformation === false}
                      name="hiddenInformation-no"
                      disabled
                    />
                  </div>
                </Fieldset>
              </SummaryList>
            </>
          )}
          {request.statisticsStatements.length > 0 && (
            <>
              <SectionBreak level="XLARGE" visible />
              <SummaryList heading="Statistics information" greyHeader>
                <Fieldset id="codeLists">
                  <legend>
                    <p className="airlock-audit-screen__legend">The output only contains code lists or programming code</p>
                  </legend>
                  <div className="airlock-audit-screen__radio-wrapper">
                    <RadioButton
                      label="Yes"
                      name="codeLists-yes"
                      checked={request.statisticsStatements[0].codeLists === true}
                      disabled
                    />
                    <RadioButton
                      label="No"
                      name="codeLists-no"
                      checked={request.statisticsStatements[0].codeLists === false}
                      disabled
                    />
                  </div>
                </Fieldset>
                <Fieldset id="statisticalTests">
                  <legend>
                    <Heading>"Safe" statistics</Heading>
                  </legend>
                  <HintText>Select all that apply</HintText>
                  <Checkbox
                    label="Statistical hypothesis tests (e.g., t-test, chi-square, R-square, standard errors)"
                    checked={request.statisticsStatements[0].statisticalTests}
                    id="statistical-tests"
                    disabled
                  />
                  {request.statisticsStatements[0].statisticalTests && (
                    <Fieldset className="airlock-audit-screen__indented-item" id="statisticalTestsConfirmation">
                      <Checkbox
                        label="Please confirm that your tests were run on a minimum of five patients."
                        checked={request.statisticsStatements[0].statisticalTestsConfirmation}
                        disabled
                      />
                    </Fieldset>
                  )}
                  <Checkbox
                    label="Coefficients of association (e.g., estimated coefficients, models, AN(C)OVA, correlation tables, density plots, kernel density plots)"
                    checked={request.statisticsStatements[0].coefficientsAssociation}
                    id="coefficients-association"
                    disabled
                  />
                  {request.statisticsStatements[0].coefficientsAssociation && (
                    <Fieldset className="airlock-audit-screen__indented-item" id="coefficientsAssociationResidualDegrees">
                      <legend>
                        <p className="airlock-audit-screen__legend">You stated that your requested outputs include coefficients of association. Please confirm the following:</p>
                      </legend>
                      <Checkbox
                        label="The residual degrees of freedom (number of observations less number of variables) exceeds five"
                        checked={request.statisticsStatements[0].coefficientsAssociationResidualDegrees}
                        disabled
                      />
                      <Checkbox
                        label="The model is not saturated (i.e., not all variables are categorical and fully interacted)"
                        checked={request.statisticsStatements[0].coefficientsAssociationModelNotSaturated}
                        disabled
                      />
                      <Checkbox
                        label="Your outputs do not include a regression with a single binary explanatory variable"
                        checked={request.statisticsStatements[0].coefficientsAssociationRegressionNotIncluded}
                        disabled
                      />
                    </Fieldset>
                  )}
                  <Checkbox
                    label="Shape (e.g., standard deviation, skewness, kurtosis)"
                    checked={request.statisticsStatements[0].shape}
                    id="shape-type"
                    disabled
                  />
                  {request.statisticsStatements[0].shape && (
                    <Fieldset className="airlock-audit-screen__indented-item">
                      <legend>
                        <p className="airlock-audit-screen__legend">You stated that your requested outputs include statistics of shape. Please confirm the following:</p>
                      </legend>
                      <Checkbox
                        label="Any standard deviations are greater than zero"
                        checked={request.statisticsStatements[0].shapeStandardDeviations}
                        disabled
                      />
                      <Checkbox
                        label="All statistics of shape were calculated for a minimum of five patients or GP practices"
                        checked={request.statisticsStatements[0].shapeMinFive}
                        disabled
                      />
                    </Fieldset>
                  )}
                  <Checkbox
                    label="Mode"
                    checked={request.statisticsStatements[0].mode}
                    id="mode-type"
                    disabled
                  />
                  {request.statisticsStatements[0].mode && (
                    <Fieldset className="airlock-audit-screen__indented-item">
                      <Checkbox
                        label="You stated that your requested outputs include modes. Please confirm that the mode is not the only value (i.e., that different observations have different values)"
                        checked={request.statisticsStatements[0].modeConfirmation}
                        disabled
                      />
                    </Fieldset>
                  )}
                  <Checkbox
                    label="Non-linear concentration ratios (e.g., Herfindahl-Hirchsmann index, diversity index)"
                    checked={request.statisticsStatements[0].ratios}
                    id="ratio-type"
                    disabled
                  />
                  {request.statisticsStatements[0].ratios && (
                    <Fieldset className="airlock-audit-screen__indented-item">
                      <legend>
                        <p className="airlock-audit-screen__legend">You stated that your requested outputs include non-linear concentration ratios. Please confirm the following:</p>
                      </legend>
                      <Checkbox
                        label="N>2"
                        checked={request.statisticsStatements[0].ratiosConfirmationNRatios}
                        disabled
                      />
                      <Checkbox
                        label="H<0.81"
                        checked={request.statisticsStatements[0].ratiosConfirmationHRatios}
                        disabled
                      />
                    </Fieldset>
                  )}
                  <Checkbox
                    label="Gini coefficients or Lorenz curves"
                    checked={request.statisticsStatements[0].giniCoefficients}
                    id="gini-coefficients"
                    disabled
                  />
                  {request.statisticsStatements[0].giniCoefficients && (
                    <Fieldset className="airlock-audit-screen__indented-item">
                      <legend>
                        <p className="airlock-audit-screen__legend">You stated that your requested outputs include Gini coefficients or Lorenz curves. Please confirm the following:</p>
                      </legend>
                      <Checkbox
                        label="N>2"
                        checked={request.statisticsStatements[0].giniCoefficientsConfirmationN}
                        disabled
                      />
                      <Checkbox
                        label="The coefficient is less than 100%"
                        checked={request.statisticsStatements[0].giniCoefficientsConfirmationLessThan}
                        disabled
                      />
                    </Fieldset>
                  )}
                </Fieldset>
                <Fieldset id="unsafe-statistics">
                  <legend>
                    <Heading>"Unsafe" statistics</Heading>
                  </legend>
                  <HintText>Select all that apply</HintText>
                  <Checkbox
                    label="Frequencies (e.g. frequency tables, histograms, shares, alluvial flow graphs, heat maps, line graphs, pie charts, scatter graphs, scatter plots, smoothed histograms, waterfall charts)"
                    checked={request.statisticsStatements[0].frequencies}
                    id="frequency-type"
                    disabled
                  />
                  {request.statisticsStatements[0].frequencies && (
                    <Fieldset className="airlock-audit-screen__indented-item">
                      <legend>
                        <p className="airlock-audit-screen__legend">You stated that your requested outputs include frequencies. Please confirm the following:</p>
                      </legend>
                      <Checkbox
                        label="All counts <5 and frequencies derived from groups containing <5 patients or GP practices have been suppressed"
                        checked={request.statisticsStatements[0].frequenciesSmallFrequenciesSuppressed}
                        disabled
                      />
                      <Checkbox
                        label="All zeroes and full cells (100%) are evidential or structural (i.e., something you would expect)"
                        checked={request.statisticsStatements[0].frequenciesZerosFullCells}
                        disabled
                      />
                      <Checkbox
                        label="Underlying values are genuinely independent (i.e., they do not come from the same patient, the patients do not all have the same family number and do not all come from the same GP practice)"
                        checked={request.statisticsStatements[0].frequenciesUnderlyingValuesIndependent}
                        disabled
                      />
                      <Checkbox
                        label="The categories are comprehensive and apply to all data (i.e., all categories of each categorical variable are presented)."
                        checked={request.statisticsStatements[0].frequenciesCategoriesComprehensiveData}
                        disabled
                      />
                    </Fieldset>
                  )}
                  <Checkbox
                    label="Position (e.g., median, percentiles, box plots)"
                    id="position-type"
                    checked={request.statisticsStatements[0].position}
                    disabled
                  />
                  {request.statisticsStatements[0].position && (
                    <Fieldset className="airlock-audit-screen__indented-item">
                      <Checkbox
                        label="You stated that your requested outputs include statistics of position. Please confirm the numbers for each group (and complementary groups) are ≥5"
                        checked={request.statisticsStatements[0].positionConfirmation}
                        disabled
                      />
                      <Fieldset id="isAcroUsedPosition">
                        <legend>
                          <p className="airlock-audit-screen__legend">Please confirm whether ACRO tools have been used in the programming of statistics</p>
                        </legend>
                        <div className="airlock-audit-screen__radio-wrapper">
                          <RadioButton
                            label="Yes"
                            name="isAcroUsedPosition-yes"
                            checked={request.statisticsStatements[0].isAcroUsedPosition === true}
                            disabled
                          />
                          <RadioButton
                            label="No"
                            name="isAcroUsedPosition-no"
                            checked={request.statisticsStatements[0].isAcroUsedPosition === false}
                            disabled
                          />
                        </div>
                      </Fieldset>
                    </Fieldset>
                  )}
                  <Checkbox
                    label="Extreme values (e.g., maxima, minima)"
                    id="extreme-values"
                    checked={request.statisticsStatements[0].extremeValues}
                    disabled
                  />
                  {request.statisticsStatements[0].extremeValues && (
                    <Fieldset className="airlock-audit-screen__indented-item">
                      <Checkbox
                        label="You stated that your requested outputs include extreme values. Please confirm the maximum or minimum presented are non-informative and structural."
                        checked={request.statisticsStatements[0].extremeValuesConfirmation}
                        disabled
                      />
                    </Fieldset>
                  )}
                  <Checkbox
                    label="Linear aggregates (e.g., means, totals, simple indexes, linear correlation ratios, bar graphs, mean plots)"
                    id="linear-aggregates"
                    checked={request.statisticsStatements[0].linearAggregates}
                    disabled
                  />
                  {request.statisticsStatements[0].linearAggregates && (
                    <Fieldset className="airlock-audit-screen__indented-item">
                      <legend>
                        <p className="airlock-audit-screen__legend">You stated that your requested outputs include linear aggregates. Please confirm the following:</p>
                      </legend>
                      <Checkbox
                        label="The linear aggregates have been derived from groups containing ≥5 patients or GP practices."
                        checked={request.statisticsStatements[0].linearAggregatesDerivedGroups}
                        disabled
                      />
                      <Checkbox
                        label="The P-ratio dominance rule has been calculated and is greater than 10%. (NB: ACRO will check this automatically)."
                        checked={request.statisticsStatements[0].linearAggregatesPRatioDominanceRule}
                        disabled
                      />
                      <Checkbox
                        label="The N-K dominance rule has been calculated for the 2 largest values and is less than 90%. (NB: ACRO will check this automatically)."
                        checked={request.statisticsStatements[0].linearAggregatesNKDominanceRule}
                        disabled
                      />
                      <Fieldset id="isAcroUsedLinearAggregates">
                        <legend>
                          <p className="airlock-audit-screen__legend">Please confirm whether ACRO tools have been used in the programming of statistics</p>
                        </legend>
                        <div className="airlock-audit-screen__radio-wrapper">
                          <RadioButton
                            label="Yes"
                            name="isAcroUsedLinearAggregates-yes"
                            checked={request.statisticsStatements[0].isAcroUsedLinearAggregates === true}
                            disabled
                          />
                          <RadioButton
                            label="No"
                            name="isAcroUsedLinearAggregates-no"
                            checked={request.statisticsStatements[0].isAcroUsedLinearAggregates === false}
                            disabled
                          />
                        </div>
                      </Fieldset>
                    </Fieldset>
                  )}
                  <Checkbox
                    label="Odds ratios, risk ratios or other proportionate risks"
                    id="odd-ratios"
                    checked={request.statisticsStatements[0].oddsRatios}
                    disabled
                  />
                  {request.statisticsStatements[0].oddsRatios && (
                    <Fieldset className="airlock-audit-screen__indented-item">
                      <Checkbox
                        label="You stated that your requested outputs include odds ratios, risk ratios or other proportionate risks. Please confirm the underlying contingency table has been produced and is included in the requested outputs"
                        checked={request.statisticsStatements[0].oddsRatiosConfirmation}
                        disabled
                      />
                    </Fieldset>
                  )}
                  <Checkbox
                    label="Hazard and survival tables (e.g., tables of survival/death rates, Kaplan-Meier graphs)"
                    id="hazard-survival-tables"
                    checked={request.statisticsStatements[0].hazardSurvivalTables}
                    disabled
                  />
                  {request.statisticsStatements[0].hazardSurvivalTables && (
                    <Fieldset className="airlock-audit-screen__indented-item">
                      <legend>
                        <p className="airlock-audit-screen__legend">You stated that your requested outputs include hazard or survival tables. Please confirm the following:</p>
                      </legend>
                      <Checkbox
                        label="The number of patients who survived is ≥5"
                        checked={request.statisticsStatements[0].hazardSurvivalTablesNumberPatientsSurvived}
                        disabled
                      />
                      <Checkbox
                        label="Exit dates are relative, not absolute"
                        checked={request.statisticsStatements[0].hazardSurvivalTablesExitDatesRelatives}
                        disabled
                      />
                      <Checkbox
                        label="There are no dates with a single exit"
                        checked={request.statisticsStatements[0].hazardSurvivalTablesNoDatesWithSingleExit}
                        disabled
                      />
                    </Fieldset>
                  )}
                </Fieldset>
                <Fieldset id="other">
                  <legend>
                    <Heading>Other</Heading>
                  </legend>
                  <Checkbox
                    label="There are other statistics present in the output"
                    checked={request.statisticsStatements[0].other}
                    disabled
                  />
                </Fieldset>
              </SummaryList>
            </>
          )}
          {request.contactTeamForm.length > 0 && (
            <>
              <SectionBreak level="XLARGE" visible />
              <SummaryList heading="Exemption" greyHeader>
                <p><strong>Why are outputs required that do not align with the disclosure control rules?</strong></p>
                <p>{request.contactTeamForm[0].requiredDisclosureAlignment}</p>
                <SectionBreak level="MEDIUM" visible />
                <p><strong>What measures have been taken to minimise the risk of potentially disclosive outputs?</strong></p>
                <p>{request.contactTeamForm[0].measuresTakenMinimiseDisclosure}</p>
                <SectionBreak level="MEDIUM" visible />
                <p><strong>Why are outputs required that do not align with the disclosure control rules?</strong></p>
                <p>{request.contactTeamForm[0].transferToThirdParty}</p>
              </SummaryList>
            </>
          )}
          <SectionBreak level="XLARGE" visible />
          <SummaryList heading="Requestor details" data={getRequestorDetails()} greyHeader />
          <SectionBreak level="XLARGE" visible />
          <SummaryList heading="Overview of request" data={getOverview()} />
          <Download request={request} />
        </>
      )}
    </LoadingBox>
  )
}

const Download = ({ request }: any) => {
  const [loading, setLoading] = useState(false);
  const [filesLink, setFilesLink] = useState<string>();
  const [filesLinkError, setFilesLinkError] = useState<null | boolean>(null);
  
  const workspaceCtx = useContext(WorkspaceContext);
  const appRolesCtx = useContext(AppRolesContext);
  const isOnlineCtx = useContext(IsOnlineContext);
  const apiCall = useAuthApiCall();
  const { id } = useParams();

  const requestId = request.createdBy.id;
  const userId = appRolesCtx.OId;

  useEffect(() => {
    (request.status !== AirlockRequestStatus.Rejected && request.status !== AirlockRequestStatus.Failed && request.status !== AirlockRequestStatus.BlockedByScan && (request.status !== AirlockRequestStatus.InReview && requestId !== userId)) && generateFilesLink();
  }, []);

  const generateFilesLink = useCallback(async () => {
    setLoading(true);
    try {
      let airlockSaSTokenurl = `${ApiEndpoint.Workspaces}/${id}/${ApiEndpoint.AirlockRequests}/${request.id}/${ApiEndpoint.AirlockLink}`;
      const linkObject = (await apiCall(airlockSaSTokenurl, HttpMethod.Get, workspaceCtx.workspaceApplicationIdURI));
      console.log("linkObject: ", linkObject);

      if (request.files.length > 0) {
        setFilesLink(linkObject.containerUrl.replace(request.id, `${request.id}/${request.files[0].name}`));
        setLoading(false);
      } else {
        setFilesLink(linkObject.containerUrl);
        setLoading(false);
      }
    } catch (err: any) {
      console.log("err: ", err);
      setFilesLinkError(true);
      setLoading(false);
    }
  }, [apiCall]);

  return (
    <>
      {loading ? (
        "loading..."
      ) : (
        <div className="airlock-audit-screen__download">
          {isOnlineCtx.isOnline ? (
            <>
              <Button
                className="govuk-button"
                target="_blank"
                disabled={true}
                download={request.files.length > 0 ? request.files[0].name : "Download file"}
              >
                Download
              </Button>
              <WarningText>You must be inside CPRD Safe to download the file.</WarningText>
            </>
          ) : (
            <>
              {filesLink && (
                <>
                  <a
                    className="govuk-button"
                    href={filesLink}
                    target="_blank"
                    download={request.files.length > 0 ? request.files[0].name : "Download file"}
                  >
                    Download
                  </a>
                </>
              )}
              {filesLinkError && "error downloading link"}
              {request.status === AirlockRequestStatus.BlockedByScan && (
                <WarningText>This file was blocked by scan and is not available for download as it may be mailicious.</WarningText>
              )}
              {(request.status === AirlockRequestStatus.Rejected || request.status === AirlockRequestStatus.Failed) && (
                <WarningText>This file was {request.status} and is not available for download.</WarningText>
              )}
              {request.status === AirlockRequestStatus.InReview && (
                <>
                  {requestId === userId && (
                    <WarningText>This file is currently in review and will be available for download once reviewed.</WarningText>
                  )}
                </>
              )}
            </>
          )}
        </div>
      )}
    </>
  )
}
