import './RadioButton.css';

type RadioButtonProps = {
  checked: boolean;
  label: string;
  onChange?: () => void;
  name?: string;
  disabled?: boolean;
  disabledText?: string;
}

export const RadioButton = ({ checked, label, onChange, name, disabled, disabledText }: RadioButtonProps) => {
  return (
    <>
      <div className={`radio-button${disabled ? ' radio-button__disabled': ''}`}>
        <input
          className="radio-button__radio"
          type="radio"
          id={name ? name : label}
          name={name ? name : label}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
        <label className="radio-button__label" htmlFor={name ? name : label}>{label}</label>
      </div>
      {disabled && <p className="radio-button__disabled-text">{disabledText}</p>}
    </>
  );
}
