import React, { useContext } from 'react';
import { SkipLink } from "govuk-react";
import { AuthenticatedTemplate } from '@azure/msal-react';

import { IsOnlineContext } from '../../contexts/IsOnlineContext';
import { FooterBar } from "./FooterBar";
import { NavigationBar } from "./NavigationBar";
import { HeaderBar } from "./HeaderBar";
import { MainDisplay } from "./MainDisplay";
import { SafeInsetText } from '../ui/GDS-components/SafeInsetText';

/**
 * Renders the navbar component with a sign-in or sign-out button depending on whether or not a user is authenticated
 * @param props 
 */
export const PageLayout = (props: { children: any; }) => {
	const isOnlineCtx = useContext(IsOnlineContext);

	return (
		<>
			<SkipLink />
			<HeaderBar />
			<div className="govuk-width-container layout-container">
				<NavigationBar />
				<AuthenticatedTemplate>
					<SafeInsetText online={isOnlineCtx.isOnline}>
						You are {isOnlineCtx.isOnline ? "outside" : "inside"} CPRD Safe
					</SafeInsetText>
				</AuthenticatedTemplate>
				<MainDisplay children={props.children} />
			</div>
			<footer>
				<FooterBar children={undefined}/>
			</footer>
		</>
	);   
};
