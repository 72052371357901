import React from 'react';

type CombineWorkspacesAndCostsProps = {
  workspaces: any,
  workspaceCosts: any
}

export const CombineWorkspacesAndCosts = ({ workspaces, workspaceCosts }: CombineWorkspacesAndCostsProps) => {
  let combinedArray: any = [];
  workspaces.map((workspace: any, i: number) => {
    const combined = Object.assign({}, workspace, {costs: workspaceCosts.filter((item: any) => item.workspace_id === workspace.id)});
    combinedArray.push(combined);
  })
  return (
    combinedArray
  );
}

export const testWorkspaceCosts = [
  {
      "partition_key": "None",
      "row_key": "aa0ef4e2-0b45-4d41-988b-e0ec59e0272e",
      "timestamp": "2024-04-15T08:45:03.0516138Z",
      "workspace_id": "08e26003-02b9-4c06-8862-37bbc28299e1",
      "credit_limit": "1000.0",
      "available_credit": "300.0",
      "credit_percentage_usage": "70",
      "update_time": "1708593415"
  },
  {
      "partition_key": "None",
      "row_key": "035ec2c0-cc74-456b-a4ac-e774d66420e0",
      "timestamp": "2024-04-15T08:47:22.8229331Z",
      "workspace_id": "a75c9b09-1186-4eb8-a48f-fbbd2a32d95a",
      "credit_limit": "2000.0",
      "available_credit": "1500.0",
      "credit_percentage_usage": "75",
      "update_time": "1713170821"
  }
]
