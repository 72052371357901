// <snippet_package>
import { BlobServiceClient, ContainerClient} from '@azure/storage-blob';
import yn from 'yn';

//const containerName = `process.env.REACT_APP_STORAGERESOURCENAME`;
const sasToken = process.env.REACT_APP_STORAGESASTOKEN;
const storageAccountName = process.env.REACT_APP_STORAGERESOURCENAME; 
// </snippet_package>

// <snippet_isStorageConfigured>
// Feature flag - disable storage feature to app if not configured
export const isStorageConfigured = () => {
  return (!storageAccountName || !sasToken) ? false : true;
}
// </snippet_isStorageConfigured>


// <snippet_checkBlobsInContainer>
// return list of blobs in container to display
export const checkBlobsInContainer = async (blobSaSToken:string, containerName:string) => {
  console.log("blobSaSToken: ", blobSaSToken, " containerName: ", containerName);
  const returnedBlobUrls: string[] = [];

  const blobService = new BlobServiceClient(
    blobSaSToken  
  );  

  // get Container - full public read access
  const containerClient: ContainerClient = blobService.getContainerClient(containerName);  
  
  // get list of blobs in container
  // eslint-disable-next-line
  for await (const blob of containerClient.listBlobsFlat()) {
    // if image is public, just construct URL
    returnedBlobUrls.push(blob.name);
  }

  return returnedBlobUrls;
}

// <snippet_getBlobsInContainer>
// return list of blobs in container to display
const getBlobsInContainer = async (containerClient: ContainerClient,containerName:string) => {
  const returnedBlobUrls: string[] = [];

  // get list of blobs in container
  // eslint-disable-next-line
  for await (const blob of containerClient.listBlobsFlat()) {
    // if image is public, just construct URL
    returnedBlobUrls.push(
      `https://${storageAccountName}.blob.core.windows.net/${containerName}/${blob.name}`
    );
  }

  return returnedBlobUrls;
}
// </snippet_getBlobsInContainer>

// <snippet_createBlobInContainer>
const createBlobInContainer = async (containerClient: ContainerClient, file: File) => {
  
  // create blobClient for container
  const blobClient = containerClient.getBlockBlobClient(file.name);

  // set mimetype as determined from browser with file upload control
  const options = { blobHTTPHeaders: { blobContentType: file.type } };
  yn(process.env.REACT_APP_DEBUG) && console.log('AzureStorageBlob: calling upload......')
  // upload file
  await blobClient.uploadData(file, options);
}
// </snippet_createBlobInContainer>

// <snippet_uploadFileToBlob>
const uploadFileToBlob = async (file: File | null,blobSaSToken: string,containerName:string) => {
  if (!file) return [];    

  // get BlobService = notice `?` is pulled out of sasToken - if created in Azure portal
  const blobService = new BlobServiceClient(
    blobSaSToken  
  );  

  // get Container - full public read access
  const containerClient: ContainerClient = blobService.getContainerClient(containerName);
  //await containerClient.createIfNotExists({
  //  access: 'container',
  //});

  // upload file
  await createBlobInContainer(containerClient, file);

  // get list of blobs in container
  //return getBlobsInContainer(containerClient);
};
// </snippet_uploadFileToBlob>

export default uploadFileToBlob;

