import React, { useRef, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { Button, LabelText, HintText, Input, ErrorText, ErrorSummary } from 'govuk-react';
import { NewWorkspaceServiceRequest } from '../models/workspaceServices';
import { CheckServiceDeployments } from '../../services/CheckServiceDeployments';
import { FormWrapper } from '../ui/FormWrapper';
import { FormButtonContainer } from '../ui/FormButtonContainer';
import { FormLabel } from '../ui/FormLabel';
import { CancelButton } from '../ui/CancelButton';
import { Textarea } from '../ui/Textarea';
import { MessageCard } from '../Error/MessageCard';

import { error } from '../../types';
import { WorkspaceContext } from '../../contexts/WorkspaceContext';

export const CreateGuacamoleForm=(props:any) => {
  const { instance, accounts } = useMsal();
  const [errors, setErrors] = useState<null | error[]>(null);
  const [errorData, setErrorData] = useState(null);
  const workspaceCtx = useContext(WorkspaceContext)
  let navigate = useNavigate(); 
  let {id} = useParams();
  const templateNameInputRef = useRef<HTMLInputElement>(null);
  const wsNameInputRef = useRef<HTMLInputElement>(null);
  const descriptionInputRef = useRef<HTMLTextAreaElement>(null); 

  const wsIsExposedExternallyInputRef = useRef<HTMLInputElement>(null);
  const wsGuacDisableCopyInputRef = useRef<HTMLInputElement>(null);
  const wsGuacDisablePasteInputRef = useRef<HTMLInputElement>(null);
  const wsGuacEnableDriveInputRef = useRef<HTMLInputElement>(null);

  const createGuacamoleHandler = (event:any) => {
    event.preventDefault();
    const enteredtemplateName = templateNameInputRef.current?.value;
    const entereddescription = descriptionInputRef.current?.value;       
    const enteredvmName = wsNameInputRef.current?.value;
    
    const enteredwswsIsExposedExternallyInputRefInputRef = wsIsExposedExternallyInputRef.current!.value;
    const enteredwsGuacDisableCopyInputRefInputRef = wsGuacDisableCopyInputRef.current!.value;
    const enteredwsGuacDisablePasteInputRefInputRef = wsGuacDisablePasteInputRef.current!.value;
    const enteredwsGuacEnableDriveInputRefInputRef = wsGuacEnableDriveInputRef.current!.value;

    const properties : NewWorkspaceServiceRequest["properties"] = {
      display_name: enteredvmName,
      description: entereddescription,
      is_exposed_externally: String(enteredwswsIsExposedExternallyInputRefInputRef).toLowerCase() === "true",
      guac_disable_copy: String(enteredwsGuacDisableCopyInputRefInputRef).toLowerCase() === "true",
      guac_disable_paste: String(enteredwsGuacDisablePasteInputRefInputRef).toLowerCase() === "true",
      guac_enable_drive: String(enteredwsGuacEnableDriveInputRefInputRef).toLowerCase() === "true",
    };

    const newWorkspaceRequest : NewWorkspaceServiceRequest = {
      templateName: enteredtemplateName,
      properties: properties
    };

    props.onAddImportRequest(newWorkspaceRequest);
  };

  const routeChange = () =>{ 
    let path = `/workspaces/` +id ; 
    navigate(path);
  }

  const hasError = (formItem: string) => {
    const checkError = (obj: error) => obj.targetName === formItem;
    return errors && errors.some(checkError);
  }

  const onSubmit = (e: any) => {
    e.preventDefault();
    setErrors(null);
    const newErrors = [];
    const guacamoleServiceName = wsNameInputRef.current?.value;
    if (!guacamoleServiceName || (guacamoleServiceName.length < 5 || guacamoleServiceName.length > 100)) {
      newErrors.push({
        targetName: "guacomole-service-name",
        text: "Enter a valid Guacomole service name, minimum of 5 characters and maximum of 100"
      })
    }

    const description = descriptionInputRef.current?.value;
    if (!description || (description.length < 10 || description.length > 100)) {
      newErrors.push({
        targetName: "description",
        text: "Enter a valid description, minimum of 10 characters and maximum of 100"
      })
    }

    const isExposedExternally = wsIsExposedExternallyInputRef.current?.value;
    if (!isExposedExternally || (isExposedExternally.length < 2 || isExposedExternally.length > 100)) {
      newErrors.push({
        targetName: "is-exposed-externally",
        text: "Enter valid copy/paste and drive enable/disable settings"
      })
    }

    const guacDisableCopy = wsGuacDisableCopyInputRef.current?.value;
    if (!guacDisableCopy || (guacDisableCopy.length < 4 || guacDisableCopy.length > 100)) {
      newErrors.push({
        targetName: "guac-disable-copy",
        text: "Enter valid guac_disable_copy"
      })
    }

    const guacDisablePaste = wsGuacDisablePasteInputRef.current?.value;
    if (!guacDisablePaste || (guacDisablePaste.length < 4 || guacDisablePaste.length > 100)) {
      newErrors.push({
        targetName: "guac-disable-paste",
        text: "Enter valid guac_disable_paste"
      })
    }

    const guacEnableDrive = wsGuacEnableDriveInputRef.current?.value;
    if (!guacEnableDrive || (guacEnableDrive.length < 2 || guacEnableDrive.length > 100)) {
      newErrors.push({
        targetName: "guac-enable-drive",
        text: "Enter valid guac_enable_drive"
      })
    }

    return newErrors.length > 0 ? (
      setErrors(newErrors),
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    ) : (
      CheckServiceDeployments(
        instance,
        accounts,
        workspaceCtx.workspace.properties.scope_id,
        e,
        setErrors,
        setErrorData,
        createGuacamoleHandler,
        id,
        'guacamole'
      ),
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    );
  }

  return (
    <>
      {errorData && (
        <MessageCard msgData={errorData} />
      )}
      {errors && errors.length > 0 && (
        <ErrorSummary
          errors={errors}
          heading="There is a problem submitting your request"
          onHandleErrorClick={(e: any) => document.getElementById(e)?.scrollIntoView()}
        />
      )}
      <form onSubmit={onSubmit}>
        <FormWrapper>
          <FormLabel>
            <LabelText>Template name</LabelText>
            <HintText>Template name for the Guacamole, which contains all configuraiton settings.</HintText>
            <Input ref={templateNameInputRef} defaultValue="tre-service-guacamole" readOnly />
          </FormLabel>
          <FormLabel error={hasError("guacomole-service-name")} id="guacomole-service-name">
            <LabelText>Guacamole service name</LabelText>
            <HintText>New Guacamole name.</HintText>
            {hasError("guacomole-service-name") && <ErrorText>Enter a valid name</ErrorText>}
            <Input ref={wsNameInputRef} defaultValue="Virtual Desktop" />
          </FormLabel>
          <FormLabel error={hasError("description")} id="description">
            <LabelText>Description</LabelText>
            <HintText>Description for the new Guacamole service.</HintText>
            {hasError("description") && <ErrorText>Enter a valid description (max 100 characters)</ErrorText>}
            <Textarea forwardRef={descriptionInputRef} defaultValue="Create Virtual Desktops for running research workloads." />
          </FormLabel>
          <FormLabel error={hasError("is-exposed-externally")} id="is-exposed-externally">
            <LabelText>is_exposed_externally</LabelText>
            <HintText>Copy/paste and drive enable/disable settings</HintText>
            {hasError("is-exposed-externally") && <ErrorText>Enter valid copy/paste and drive enable/disable settings</ErrorText>}
            <Input ref={wsIsExposedExternallyInputRef} defaultValue="true" />
          </FormLabel>
          <FormLabel error={hasError("guac-disable-copy")} id="guac-disable-copy">
            <LabelText>guac_disable_copy</LabelText>
            <HintText>guac_disable_copy.</HintText>
            {hasError("guac-disable-copy") && <ErrorText>Enter valid guac_disable_copy</ErrorText>}
            <Input ref={wsGuacDisableCopyInputRef} defaultValue="true" />
          </FormLabel>
          <FormLabel error={hasError("guac-disable-paste")} id="guac-disable-paste">
            <LabelText>guac_disable_paste</LabelText>
            <HintText>guac_disable_paste.</HintText>
            {hasError("guac-disable-paste") && <ErrorText>Enter valid guac_disable_paste</ErrorText>}
            <Input ref={wsGuacDisablePasteInputRef} defaultValue="true" />
          </FormLabel>
          <FormLabel error={hasError("guac-enable-drive")} id="guac-enable-drive">
            <LabelText>guac_enable_drive</LabelText>
            <HintText>guac_enable_drive.</HintText>
            {hasError("guac-enable-drive") && <ErrorText>Enter valid guac_enable_drive</ErrorText>}
            <Input ref={wsGuacEnableDriveInputRef} defaultValue="false" />
          </FormLabel>
          <FormButtonContainer>                        
            <Button type="submit" className="govuk-button" data-module="govuk-button">Save and continue</Button>
            <CancelButton onClick={()=> routeChange()}>Cancel</CancelButton>
          </FormButtonContainer>
        </FormWrapper>
      </form>
    </>
  );
}
