import React from 'react';

import './FormWrapper.css';

type FormWrapperProps = {
  children: React.ReactNode;
}

export const FormWrapper = ({ children }: FormWrapperProps) => {
  return (
    <div className="FormWrapper">{children}</div>
  )
}
