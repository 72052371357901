import React from "react";

import './TextButton.css';

type TextButtonProps = {
  children: React.ReactNode;
  onClick: () => void;
}

export const TextButton = ({ children, onClick }: TextButtonProps) => {
  return (
    <button className="text-button" onClick={onClick}>{children}</button>
  )
}
