
import "./Home.css";


export const PrivacyNotice=(props:any)=>{    
        
        return (
            <>
                <div id="block-cprd-page-title" className="block block-core block-page-title-block">    
      
                  <h1 className="page-title"><span property="schema:name" className="field field--name-title field--type-string field--label-hidden">Privacy notice</span>
                  </h1>

                </div>


                <div id="block-cprd-content" className="block block-system block-system-main-block">
  
    
      
  <article data-history-node-id="129" role="article" about="/privacy-notice" typeof="schema:WebPage" className="node-type--page node node--type-page node--view-mode-full">
  
    
        <span property="schema:name" className="rdf-meta hidden"></span>
  
  
    
    <div className="node-content--page node-content node__content">
      
                  <div property="schema:text" className="clearfix text-formatted field field--name-body field--type-text-with-summary field--label-hidden govuk-body govuk-!-font-size-19 field__item"><h3>Who we are&nbsp;</h3>
  
  <p>The Clinical Practice Research Datalink (CPRD) is a centre of the Medicines and Healthcare products Regulatory Agency (MHRA), an executive agency of the Department of Health and Social Care (DHSC), which regulates medicines, medical devices and blood components for transfusion in the UK. The DHSC is the legal ‘controller’ of the data which we hold.</p>
  
  <p>This is information for researchers, GP practices and users of this website about how we use administrative forms of personal data you have provided to us when using this website.</p>
  
  <p><strong>Information about medical research data can be found on the&nbsp;<a href="https://www.cprd.com/data-protection-and-processing-notice">Data protection and processing notice page</a>&nbsp;of the CPRD website</strong>.</p>
  
  <h3>What data we collect from you through this website&nbsp;</h3>
  
  <p>The personal data we collect from users of this website will include:</p>
  
  <ul>
      <li>The IP address you use to access cprd.com</li>
      <li>The URLs of any of our web pages which you visit and the time of your visit</li>
  </ul>
  
  <p><strong>Cookies&nbsp;</strong></p>
  
  <p>We use cookies to measure how you use the website so it can be updated and improved.</p>
  
  <p>We use a third-party service, Google Analytics, to collect standard internet log information and details of visitor behaviour patterns. We do this to find out such things as the number of visitors to the various parts of the site. This information is only processed in a way that does not identify anyone. We do not make, and do not allow Google to make, any attempt to find out the identities of those visiting our website.</p>
  
  <p>Data collected by Google Analytics may be transferred outside the EEA for processing. A Google Analytics opt-out browser add-on is available.</p>
  
  <p>When you visit&nbsp;<a href="https://www.cprd.com">www.cprd.com</a>&nbsp;you will be notified that we use cookies and asked whether you are happy to accept this or choose to Decline. You will regularly receive prompts about this preference.</p>
  
  <p>If you choose Decline, we will still use necessary cookies so that our site works.</p>
  
  <p>You can find out more about&nbsp;<a href="https://ico.org.uk/for-the-public/online/cookies/" target="_blank">how to manage cookies</a>&nbsp;on the Information Commissioner’s Office (ICO) website.</p>
  
  <p><strong>Professional contact details&nbsp;</strong></p>
  
  <p>The additional personal data we collect from researchers and GP practices who complete a form or contact CPRD will include:</p>
  
  <ul>
      <li>Your name and title</li>
      <li>Your email address</li>
      <li>Your organisation name</li>
      <li>Your job title at the organisation</li>
      <li>Your phone number</li>
  </ul>
  
  <p>The legal basis for processing this data is the performance of a task carried out in the public interest or in the exercise of official authority vested in DHSC or its executive agency, the MHRA.</p>
  
  <h3>What we do with your data&nbsp;</h3>
  
  <p>We use your data to respond to your enquiry.</p>
  
  <p>We also use administrative data from GPs and GP practices as part of the process of joining CPRD and contributing patient data for research.&nbsp;<a href="http://cprd.com/whatwedowithGPinformation">Find out more on the What we do with GP information web page</a>.&nbsp;</p>
  
  <p>We use contact information for responsible individuals at research centres undertaking studies using CPRD data, both to arrange, support, and control use of that information under contract or licence and to vet applications for access to CPRD and related data.</p>
  
  <p>Information about medical research data can be found on the&nbsp;<a href="https://www.cprd.com/data-protection-and-processing-notice">Data protection and processing notice page</a>&nbsp;of the CPRD website.</p>
  
  <p>We will not:</p>
  
  <ul>
      <li>sell or rent your data to third parties</li>
      <li>share your data with third parties for marketing purposes</li>
  </ul>
  
  <p>We will share your data if we are required to do so by law – for example, by court order, or to prevent fraud or other crime.</p>
  
  <h3>How long we keep your data</h3>
  
  <p>We will retain your personal data for as long as you are contributing data to CPRD or have an enquiry or customer account with CPRD. We will delete your data in line with the MHRA Records Retention Policy or as required by law – typically, 8 years after any enquiry is closed or contract terminated.</p>
  
  <h3>Where your data is processed and stored</h3>
  
  <p>We make sure that your data is as safe as possible at any stage, both while it is processed and when it is stored. Your personal data is only stored in the European Economic Area (EEA).</p>
  
  <h3>How we protect your data and keep it secure</h3>
  
  <p>We are committed to doing all that we can to keep your data secure. To prevent unauthorised access or disclosure we have put in place technical and organisational procedures to secure the data we collect about you. We also make sure that any third parties that we deal with have an obligation to keep secure all personal data they process on our behalf.</p>
  
  <h3>What are your rights</h3>
  
  <p>You have the right to request:</p>
  
  <ul>
      <li>information about how your personal data is processed</li>
      <li>a copy of that personal data - this copy will be provided in a structured, commonly used and machine-readable format</li>
      <li>that anything inaccurate in your personal data is corrected immediately</li>
  </ul>
  
  <p>You can also:</p>
  
  <ul>
      <li>raise an objection about how your personal data is processed</li>
      <li>request that your personal data is erased if there is no longer a justification for it</li>
      <li>ask that the processing of your personal data is restricted in certain circumstances</li>
  </ul>
  
  <p>If you have any of these requests, get in contact with our Data Protection Officer -&nbsp;you can find their contact details below.</p>
  
  <h3>Changes to this notice</h3>
  
  <p>We may modify or amend this privacy notice at our discretion at any time. When we make changes to this notice, we will amend the last modified date at the bottom of this page. Any modification or amendment to this privacy notice will be applied to you and your data as of that revision date. We encourage you to periodically review this privacy notice to be informed about how we are protecting your data.</p>
  
  <h3>Questions and complaints</h3>
  
  <p>If you have queries about how the Agency protects and uses your personal data, please contact&nbsp;<a href="mailto:dataprotection@mhra.gov.uk">dataprotection@mhra.gov.uk</a>&nbsp;in the first instance. You may also contact the DHSC Data Protection Officer at&nbsp;<a href="mailto:data_protection@dhsc.gov.uk">data_protection@dhsc.gov.uk</a>.</p>
  
  <p>Alternatively, you can contact us in writing:</p>
  
  <p>Data Protection Officer<br/>
  MHRA<br/>
  10 South Colonnade<br/>
  London<br/>
  E14 4PU</p>
  
  <p>Or</p>
  
  <p>Data Protection Officer<br/>
  DHSC<br/>
  1st Floor North<br/>
  39 Victoria Street<br/>
  London<br/>
  SW1H 0EU</p>
  
  <p>You have the right to make a complaint to the&nbsp;<a href="https://ico.org.uk/make-a-complaint/your-personal-information-concerns/" target="_blank">Information Commissioner’s Office through their website</a>&nbsp;or their helpline 0303 123 1113.</p>
  </div>           

  
    </div>
  
  </article>
  
    </div>

            </>
        );
    }