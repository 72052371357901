import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { LoadingBox, LabelText, HintText, Input, Button, ErrorText, ErrorSummary } from 'govuk-react';
import yn from 'yn';

import { WorkspaceContext } from '../contexts/WorkspaceContext';
import { ApiEndpoint } from '../components/models/apiEndPoints';
import { loginRequest, trecoreServicesConfig } from '../components/Core/authConfig';
import { CallApiWithToken, HttpMethod } from '../components/Core/fetch';

import { Title } from '../components/ui/Title';
import { Subtitle } from '../components/ui/Subtitle';
import { FormWrapper } from '../components/ui/FormWrapper';
import { FormButtonContainer } from '../components/ui/FormButtonContainer';
import { FormLabel } from '../components/ui/FormLabel';
import { Textarea } from '../components/ui/Textarea';
import { CancelButton } from '../components/ui/CancelButton';
import { NotificationBox } from '../components/ui/NotificationBox';
import { Selectbox } from '../components/ui/Selectbox';

import { error } from '../types';
import { UpdateWorkspaceServiceRequest } from '../components/models/workspace';

import './UpdateUserResource.css';
import { MessageCard } from '../components/Error/MessageCard';

export const UpdateUserResource = () => {
  const location = useParams();
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const workspaceCtx = useContext(WorkspaceContext);
  const workspaceId = location.id;
  const workspaceServiceId = location.rid;
  const userResourceId = location.urid;
  const [resource, setResource] = useState<null | any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errors, setErrors] = useState<null | error[]>(null);
  const [apiError, setApiError] = useState(null);
  const [requestSuccessful, setRequestSuccessful] = useState(null);
  const [initialVmSize, setInitialVmSize] = useState<null | string>(null);
  const [vmSize, setVmSize] = useState<null | string>(null);
  const resourceNameInputRef = useRef<HTMLInputElement>(null);
  const resourceDescriptionInputRef = useRef<HTMLTextAreaElement>(null);
  const resourceVmSizeInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setIsLoading(true);
    workspaceCtx.workspace.properties && (
      instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
        scopes: [`${workspaceCtx.workspace.properties.scope_id}/${process.env.REACT_APP_TRE_CORE_API_USER_IMPERSONATION}`]
      }).then(async (response) => {
        await CallApiWithToken(
          response.accessToken,
          `${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${workspaceId}/${ApiEndpoint.WorkspaceServices}/${workspaceServiceId}/${ApiEndpoint.UserResources}/${userResourceId}`,
          HttpMethod.Get,
          ''
        ).then(response => {
          setResource(response.userResource);
          setVmSize(response.userResource.properties?.vm_size ? response.userResource.properties.vm_size : "4 CPU | 32GB RAM");
          setInitialVmSize(response.userResource.properties?.vm_size ? response.userResource.properties.vm_size : "4 CPU | 32GB RAM");
          setIsLoading(false);
        }).catch((err: any) => {
          setError(err);
          setIsLoading(false);
        })
      })
    )
  }, [workspaceCtx]);

  const makeApiCall = async (payload: any, workspace: any) => {
    const tokenRequest = {
      scopes: [`${workspaceCtx.workspace.properties.scope_id}/${process.env.REACT_APP_TRE_CORE_API_USER_IMPERSONATION}`],
      account: accounts[0]
    }

    setApiError(null);
    setIsLoading(true);

    instance.acquireTokenSilent(tokenRequest).then(async (response) => {
      await CallApiWithToken(
        response.accessToken,
        `${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${workspaceId}/${ApiEndpoint.WorkspaceServices}/${workspaceServiceId}/${ApiEndpoint.UserResources}/${userResourceId}`,
        HttpMethod.Patch,
        payload,
        "*"
      ).then(response => {
        setRequestSuccessful(response.operation.message);
        setIsLoading(false);
        const path = `/manage-workspaces/${workspaceCtx.workspace.id}/manage-workspace-services/${workspaceServiceId}/manage-user-resources`;
        navigate(path, {state: { updated: resourceNameInputRef.current!.value }});
      }).catch((err: any) => {
        setApiError(err.message);
        setIsLoading(false);
      }).catch((err: any) => {
        setApiError(err.message);
        setIsLoading(false);
      })
      document.getElementById("notification")?.scrollIntoView();
      yn(process.env.REACT_APP_DEBUG) && console.log("User resource update request submitted");
    })
  }

  const createPayload = (e: any) => {
    e.preventDefault();
    const enteredName = resourceNameInputRef.current!.value;
    const enteredDescription = resourceDescriptionInputRef.current!.value;
    const enteredVmSize = resourceVmSizeInputRef.current!.value;

    let properties: UpdateWorkspaceServiceRequest["properties"] = {}

    properties = {
      display_name: enteredName,
      description: enteredDescription,
      vm_size: enteredVmSize
    };

    const newUpdateWorkspaceServiceRequest : UpdateWorkspaceServiceRequest = {
      properties: properties
    };

    makeApiCall(newUpdateWorkspaceServiceRequest, workspaceCtx.workspace);
  }

  const hasError = (formItem: string) => {
    const checkError = (obj: error) => obj.targetName === formItem;
    return errors && errors.some(checkError);
  }

  const onSubmit = (e: any) => {
    e.preventDefault();
    setErrors(null);
    const newErrors = [];
    const resourceName = resourceNameInputRef.current?.value;
    if (!resourceName || (resourceName.length < 5 || resourceName.length > 100)) {
      newErrors.push({
        targetName: "resource-name",
        text: "Enter a valid resource name"
      })
    }

    const description = resourceDescriptionInputRef.current?.value;
    if (description && (description.length < 10 || description.length > 100)) {
      newErrors.push({
        targetName: "resource-description",
        text: "Enter a valid description"
      })
    }

    const resourceVmSize = resourceVmSizeInputRef.current?.value;
    if (resourceVmSize === "Select") {
      newErrors.push({
        targetName: "vm-size",
        text: "Select a VM size. Default size is 4 CPU | 32GB RAM"
      })
    }

    return newErrors.length > 0 ? (
      setErrors(newErrors),
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    ) : createPayload(e);
  }

  const routeChange = () => {
    const path = `/manage-workspaces/${location.id}/manage-workspace-services/${location.rid}/manage-user-resources`;
    navigate(path);
  }

  return (
    <LoadingBox loading={isLoading}>
      {error && (
        <MessageCard msgData={error} />
      )}
      {(apiError || requestSuccessful) && (
        <NotificationBox
          id="notification"
          error={apiError}
          text={requestSuccessful}
        />
      )}
      <header>
        <Title>Update user resource</Title>
        <Subtitle>{resource && resource.properties.display_name}</Subtitle>
      </header>
      {errors && errors.length > 0 && (
        <ErrorSummary
          errors={errors}
          heading="There is a problem submitting your request"
          onHandleErrorClick={(e: any) => document.getElementById(e)?.scrollIntoView()}
        />
      )}
      {resource && (
        <form onSubmit={onSubmit}>
          <FormWrapper>
            <FormLabel error={hasError("service-name")} id="service-name">
              <LabelText>Service name</LabelText>
              <HintText>Choose a name for your service.</HintText>
              {hasError("resource-name") && <ErrorText>Enter a valid resource name (between 5 - 100 characters long)</ErrorText>}
              <Input ref={resourceNameInputRef} defaultValue={resource.properties.display_name} />
            </FormLabel>
            <FormLabel error={hasError("resource-description")} id="resource-description">
              <LabelText>Description</LabelText>
              <HintText>Add a biref description for the resource.</HintText>
              {hasError("resource-description") && <ErrorText>Enter a valid description (between 10 - 100 characters long)</ErrorText>}
              <Textarea forwardRef={resourceDescriptionInputRef} defaultValue={resource.properties.description} />
            </FormLabel>
            {vmSize && (
              <Selectbox
                className="update-user-resource__select"
                id="vm-size"
                label="VM size"
                forwardRef={resourceVmSizeInputRef}
                defaultValue={vmSize}
                error={hasError("vm-size")}
                errorText="Select a VM size. Default size is 4 CPU | 32GB RAM"
                hint="Select the size of the VM"
                options={[
                  {
                    name: "4 CPU | 32GB RAM",
                    value: "4 CPU | 32GB RAM"
                  },
                  {
                    name: "4 CPU | 64GB RAM",
                    value: "4 CPU | 64GB RAM"
                  }
                ]}
                onChange={(e: string) => setVmSize(e)}
              />
            )}
            {initialVmSize === "4 CPU | 32GB RAM" && vmSize === "4 CPU | 64GB RAM" && (
              <p className="update-user-resource__warning-text">Your current VM is charged at £0.60/hour for using 32GB. 64GB VMs are charged at £0.90/hour. (This charge will be drawn down from your VM usage allocation)</p>
            )}
            <FormButtonContainer>
              <Button type="submit" className="govuk-button" data-module="govuk-button">Update resource</Button>
              <CancelButton onClick={() => routeChange()}>Cancel</CancelButton>
            </FormButtonContainer>
          </FormWrapper>
        </form>
      )}
    </LoadingBox>
  )
}
