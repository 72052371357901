
import "./Home.css";


export const AccessiblityStatement=(props:any)=>{            
        return (
            <>
                <div id="block-cprd-page-title" className="block block-core block-page-title-block">
  
    
      
                    <h1 className="page-title"><span property="schema:name" className="field field--name-title field--type-string field--label-hidden">Accessibility statement</span>
                    </h1>


                </div>


                <div id="block-cprd-content" className="block block-system block-system-main-block">
  
    
      
  <article data-history-node-id="130" role="article" about="/accessibility-statement" typeof="schema:WebPage" className="node-type--page node node--type-page node--view-mode-full">
  
    
        <span property="schema:name"  className="rdf-meta hidden"></span>
  
  
    
    <div className="node-content--page node-content node__content">
      
                  <div property="schema:text" className="clearfix text-formatted field field--name-body field--type-text-with-summary field--label-hidden govuk-body govuk-!-font-size-19 field__item"><p>This statement applies to content published on the CPRD.com domain.</p>
  
  <p>This website is run by the Clinical Practice Research Datalink (CPRD) on behalf of the Medicines and Healthcare products Regulatory Agency. It is designed to be used by as many people as possible. The text should be clear and simple to understand. You should be able to:</p>
  
  <ul>
      <li>zoom in up to 300% without problems</li>
      <li>navigate most of the website using just a keyboard</li>
      <li>navigate most of the website using speech recognition software</li>
      <li>use most of the website using a screen reader (including the most recent versions of JAWS, NVDA and VoiceOver)</li>
  </ul>
  
  <h3>How accessible this website is</h3>
  
  <p>Parts of this website are not fully accessible. For example:</p>
  
  <ul>
      <li>some pages and document attachments are not clearly written</li>
      <li>some tables do not have row headers</li>
      <li>some pages have poor colour contrast</li>
      <li>some heading elements are not consistent</li>
      <li>some images do not have good alternative text</li>
      <li>some buttons are not correctly identified</li>
      <li>some error messages are not clearly associated with form controls</li>
      <li>many documents are in PDF format and are not accessible</li>
  </ul>
  
  <h3>How to request content in an accessible format</h3>
  
  <p>If you need information in a different format&nbsp;<a href="mailto:enquiries@cprd.com">contact us</a>&nbsp;and tell us:</p>
  
  <ul>
      <li>the web address (URL) of the content</li>
      <li>your name and email address</li>
      <li>the format you need</li>
  </ul>
  
  <h3>Reporting accessibility problems with this website</h3>
  
  <p>If you find any problems that are not listed on this page or you think we’re not meeting the accessibility requirements,&nbsp;<a href="mailto:enquiries@cprd.com">contact us</a>.</p>
  
  <h3>Enforcement procedure</h3>
  
  <p>If you contact us with a complaint and you are not happy with our response&nbsp;<a href="https://www.equalityadvisoryservice.com/" target="_blank">contact the Equality Advisory and Support Service (EASS)</a>.</p>
  
  <p>The Equality and Human Rights Commission (EHRC) is responsible for enforcing the Public Sector Bodies (Websites and Mobile Applications) (No. 2) Accessibility Regulations 2018 (the ‘accessibility regulations’).</p>
  
  <h3>Technical information about this website’s accessibility</h3>
  
  <p>CPRD is committed to making its website accessible, in accordance with the Public Sector Bodies (Websites and Mobile Applications) (No. 2) Accessibility Regulations 2018.</p>
  
  <p>This website is partially compliant with the&nbsp;<a href="https://www.w3.org/TR/WCAG21/" target="_blank">Web Content Accessibility Guidelines version 2.1</a>&nbsp;AA standard.</p>
  
  <h3>Non-accessible content</h3>
  
  <p>The content that is not accessible is outlined below with details of:</p>
  
  <ul>
      <li>where it fails the success criteria</li>
      <li>planned dates for when issues will be fixed</li>
  </ul>
  
  <p>Some tables in content do not have table row headers when needed. This fails WCAG 2.1A 1.3.1 success criterion (Info and Relationships).&nbsp;We plan to identify and fix these by April 2022.</p>
  
  <p>Focused links and buttons sometimes do not have enough contrast. This fails WCAG 1.4.11 AA (Non-text Contrast).&nbsp;We plan to identify and fix these by April 2022.</p>
  
  <p>Images on pages do not always have suitable alternative text. Some images are missing alternative text, some have incomplete text and some have ‘placeholder’ as alternative text. This fails WCAG 1.1.1A success criterion (Non-text Content). We plan to identify and fix these by April 2022.</p>
  
  <p>Headings on some pages are not in a logical nested order. We plan to identify and fix these by April 2022.</p>
  
  <p>Buttons on some pages do not have a correct role identified, which affects how they work with assistive technology. This fails WCAG 2.1A success criterion (Name, Role, Value). We plan to identify and fix these by April 2022.</p>
  
  <p>Error messages on some forms are unclear or lack clear association with particular form controls. This fails WCAG 2.1A 3.3.1 success criterion (Error Identification).</p>
  
  <h3>PDFs and other documents</h3>
  
  <p>Many documents are not accessible in a number of ways including missing text alternatives and missing document structure.&nbsp;We plan to identify and fix these by April&nbsp;2022.</p>
  
  <p><a href="mailto:enquiries@cprd.com">Contact us</a>&nbsp;to request documents in an alternative format.</p>
  
  <h3>Disproportionate burden</h3>
  
  <p>It would be a disproportionate burden on CPRD to resolve the non-compliance for the areas outlined above by September 2020. We are addressing all points as part of an ongoing project that is due to be completed by April 2022.</p>
  
  <h3>What we’re doing to improve accessibility</h3>
  
  <p>We plan to identify and fix issues according to the timescales shown for each area above.</p>
  
  
  </div>

  
    </div>
  
  </article>
  
    </div>

            </>
        );
    }