import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { Button, Caption, Heading, ErrorText, LoadingBox } from 'govuk-react';

import { WorkspaceContext } from '../contexts/WorkspaceContext';
import { CheckCurrentServices } from '../services/CheckServiceDeployments';

import { RadioButton } from '../components/ui/RadioButton';
import { Lede } from '../components/ui/Lede';
import { MessageCard } from '../components/Error/MessageCard';

import { wsServiceCheck } from '../types';

import './CreateNewWorkspaceService.css';

export const CreateNewWorkspaceService = (props: any) => {
  const { instance, accounts } = useMsal();
  const workspaceCtx = useContext(WorkspaceContext);
  const [workspaceService, setWorkspaceService] = useState<null | string>(null);
  const [error, setError] = useState(false);
  const [errorData, setErrorData] = useState();
  const [wsServiceDeploying, setWsServiceDeploying] = useState(false);
  const [workspaceServices, setWorkspaceServices] = useState<wsServiceCheck>();
  const [loading, setLoading] = useState(false);
  const wsId = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    workspaceCtx.workspace?.properties && (
      CheckCurrentServices(
        instance,
        accounts,
        workspaceCtx.workspace.properties.scope_id,
        setErrorData,
        setLoading,
        setWsServiceDeploying,
        setWorkspaceServices,
        wsId.id
      )
    )
  }, [workspaceCtx]);

  const onSubmit = (e: any) => {
    e.preventDefault();
    setError(false);
    workspaceService === null ? setError(true) : navigate(`/Workspaces/${wsId.id}/${workspaceService}`);
  }

  return (
    <>
      {errorData && (
        <MessageCard msgData={errorData} />
      )}
      <LoadingBox loading={loading}>
        <form onSubmit={onSubmit}>
          <Heading className="CreateNewWorkspaceService__heading">New workspace service</Heading>
          <Caption>Select a workspace service</Caption>
          {workspaceServices && workspaceServices.hasEachService ? (
            <Lede className="CreateNewWorkspaceService__message">This workspace has each type of workspace service already</Lede>
          ) : (
            <>
              {wsServiceDeploying ? (
                <Lede className="CreateNewWorkspaceService__message">There is currently a workspace service deploying, once it has finished then you can add another workspace service</Lede>
              ) : (
                <>
                  <fieldset className={`CreateNewWorkspaceService__fieldset${error ? ' CreateNewWorkspaceService__fieldset--error' : ''}`}>
                    {error && <ErrorText>Select a workspace service</ErrorText>}
                    <RadioButton
                      label="Atlas"
                      checked={workspaceService === "createAtlas"}
                      onChange={() => setWorkspaceService("createAtlas")}
                      disabled={workspaceServices?.hasAtlas}
                      disabledText="This workspace already has an Atlas service"
                    />
                    <RadioButton
                      label="Gitea"
                      checked={workspaceService === "createGitea"}
                      onChange={() => setWorkspaceService("createGitea")}
                      disabled={workspaceServices?.hasGitea}
                      disabledText="This workspace already has a Gitea service"
                    />
                    <RadioButton
                      label="Guacamole"
                      checked={workspaceService === "createGuacamole"}
                      onChange={() => setWorkspaceService("createGuacamole")}
                      disabled={workspaceServices?.hasGuacamole}
                      disabledText="This workspace already has a Guacamole service"
                    />
                  </fieldset>
                  <Button type="submit" className="govuk-button CreateNewWorkspaceService__next">Next</Button>
                </>
              )}
            </>
          )}
        </form>
      </LoadingBox>
    </>
  )
}
