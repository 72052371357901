import React from 'react';

import './Tabs.css';

type TabProps = {
  activeTab: string;
  children: React.ReactNode;
  setActiveTab: (arg: string) => void;
  tabs:{
    label: string;
    name: string;
  }[];
}

export const Tabs = ({ children, activeTab, setActiveTab, tabs }: TabProps) => {
  return (
    <div className="gds-tabs">
      <ul className="gds-tabs__list" role="tablist">
        {tabs.map((tab: any, i: number) => {
          return (
            <li key={`tab-${i}`} className={`gds-tabs__list-item${activeTab === tab.name ? " gds-tabs__list-item--selected" : ""}`} role="presentation">
              {activeTab === tab.name ? (
                tab.label
              ) : (
                <button className="gds-tabs__button" onClick={() => setActiveTab(tab.name)}>{tab.label}</button>
              )}
            </li>
          )
        })}
      </ul>
      {/* do we need more ARIA attributes here? */}
      <div className="gds-tabs__panel" role="tabpanel">
        {children}
      </div>
    </div>
  )
}
