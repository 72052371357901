import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { useLocation } from "react-router-dom";
import yn from "yn";
import { AppRolesContext } from "../../contexts/AppRolesContext";
import { WorkspaceContext } from "../../contexts/WorkspaceContext";
import { HttpMethod, ResultType } from "../Core/fetch";
import { useAuthApiCall } from "../hooks/useAuthAPICall";
import { ApiEndpoint } from "../models/apiEndPoints";


export const WorkspaceProvider: React.FunctionComponent = () => {
  const apiCall = useAuthApiCall();    
  const appRolesCtx = useContext(AppRolesContext);
  const workspaceCtx = useRef(useContext(WorkspaceContext));
  let wsId = useParams();      
  let location = useLocation()
  let stateData:any = location.state  
  let mWsId = ''

  useEffect(() =>  {
    const getWorkspace = async () => {
      if (wsId.id == undefined) {
        if ((stateData !== null && stateData.data !== undefined)) {
          mWsId = stateData.data
        } else {
          // deconstrust path directly when params aren't passing through an id
          const fullString = location.pathname.substring(location.pathname.indexOf("/") + 1);
          const removeCharactersBefore = fullString.substring(fullString.indexOf("/") + 1);
          const checkIfCharactersAfter = removeCharactersBefore.includes("/");
          if (checkIfCharactersAfter) {
            mWsId = removeCharactersBefore.split("/")[0];
          } else {
            mWsId = removeCharactersBefore;
          }
        }
      } else {
        mWsId = wsId.id
      }

      yn(process.env.REACT_APP_DEBUG) && console.log('WorkspaceProvider WorkspaceId...', mWsId)

          //let scopeId =process.env.REACT_APP_TRE_CORE_API_WORKSPACE_SCOPES;            
      let scopeId = (await apiCall(`${ApiEndpoint.Workspaces}/${mWsId}/scopeid`, HttpMethod.Get)).workspaceAuth.scopeId;
      if (scopeId === "") {
        console.error("Unable to get scope_id from workspace - authentication not set up.");
      }

      //yn(process.env.REACT_APP_DEBUG) && console.log('***Scope Id:', scopeId) 

      const ws = (await apiCall(`${ApiEndpoint.Workspaces}/${mWsId}`, HttpMethod.Get, scopeId)).workspace;
      workspaceCtx.current.setWorkspace(ws);
      const ws_application_id_uri = ws.properties.scope_id;

      //yn(process.env.REACT_APP_DEBUG) && console.info('+++ WorkspaceProvider WS: ',ws)

      // use the client ID to get a token against the workspace (tokenOnly), and set the workspace roles in the context

      let wsRoles: Array<string> = [];
      await apiCall(`${ApiEndpoint.Workspaces}/${mWsId }`, HttpMethod.Get, ws_application_id_uri, undefined, ResultType.JSON, (roles: Array<string>) => {
        workspaceCtx.current.setRoles(roles);
        wsRoles = roles;
      }, true);
      
      yn(process.env.REACT_APP_DEBUG) && console.info('Workpsace Provider: AppRoles are...',appRolesCtx.roles);
      yn(process.env.REACT_APP_DEBUG) && console.info('Workpsace Provider: AppRoles are OId is...',appRolesCtx.OId);
      
    };
    getWorkspace();

    // let ctx = workspaceCtx.current;

    // // run this on onmount - to clear the context
    // return (() => {
    //    yn(process.env.REACT_APP_DEBUG) && console.log('WorkspaceProvider: Clearing context for Workspace')
    //   ctx.setRoles([]);
    //   ctx.setWorkspace({} as Workspace);
    // });
  }, [apiCall]);     

  return (    
    <></>
  );
};
