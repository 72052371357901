import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Link, Panel } from 'govuk-react';

import './AirlockReviewSubmitted.css';

export const AirlockReviewSubmitted = () => {
  const ws = useParams();
  const location = useLocation();
  const stateData: any = location.state;
  const status = stateData.approval.toLowerCase() === "true" ? "Approved" : "Rejected";
  console.log("status: ", status);
  console.log("stateData: ", stateData);

  return (
    <>
      <div className="airlock-review-submitted">
        <Panel title="Review request complete">
          <p className="airlock-review-submitted__banner-copy">Status: <strong>{status}</strong></p>
        </Panel>
      </div>
      <p className="airlock-review-submitted__copy">We have sent an email to the requestor about the status of their request.</p>
      <Link className="govuk-button" href={`/workspaces/${ws.id}/airlocks/`}>Back to airlock dashboard</Link>
    </>
  )
}
