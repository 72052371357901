import React from 'react';

import './Title.css';

type TitleProps = {
  children: React.ReactNode;
  className?: string;
}

export const Title = ({ children, className }: TitleProps) => {
  const classNames = `title ${className}`;
  return (
    <h1 className={classNames}>{children}</h1>
  )
}
