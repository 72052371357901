import React from 'react';

import './FormButtonContainer.css';

type FormButtonContainerProps = {
  children: React.ReactNode
}

export const FormButtonContainer = ({ children }: FormButtonContainerProps) => {
  return (
    <footer className="FormButtonContainer">
      {children}
    </footer>
  )
}
