
import "./Home.css";


export const TermsAndConditions=(props:any)=>{    
        
        return (
            <>
                <div id="block-cprd-page-title" className="block block-core block-page-title-block">                    
                    <h1 className="page-title"><span property="schema:name" className="field field--name-title field--type-string field--label-hidden">Terms and conditions</span>
                    </h1>
                </div>


                <div id="block-cprd-content" className="block block-system block-system-main-block">
  
    
      
  <article data-history-node-id="131" role="article" about="/terms-and-conditions" typeof="schema:WebPage" className="node-type--page node node--type-page node--view-mode-full">
  
    
        <span property="schema:name" className="rdf-meta hidden"></span>
  
  
    
    <div className="node-content--page node-content node__content">
      
                  <div property="schema:text" className="clearfix text-formatted field field--name-body field--type-text-with-summary field--label-hidden govuk-body govuk-!-font-size-19 field__item"><p>This page and any pages it links to explains CPRD’s terms of use.&nbsp;By using CPRD.com, including the electronic Research Application Portal (eRAP), you agree to be bound by these terms.</p>
  
  <h3>Who we are</h3>
  
  <p>CPRD.com is managed by the Clinical Practice Research Datalink (CPRD) on behalf of the Medicines and Healthcare products Regulatory Agency (the agency). CPRD is centre of the agency and will be referred to as ‘we’ from now on.&nbsp;</p>
  
  <h3>Using CPRD.com</h3>
  
  <p>You agree to use CPRD.com only for lawful purposes. You must also use it in a way that does not infringe the rights of, or restrict or inhibit the use and enjoyment of, this site by anyone else.</p>
  
  <p>We update CPRD.com regularly. We can change or remove content at any time without notice.&nbsp;</p>
  
  <h3>Services and transactions</h3>
  
  <p>You can use CPRD.com to access CPRD services and transactions, for example the electronic Research Application Portal (eRAP).</p>
  
  <p>Some services have their own terms and conditions which also apply – please read these before you use the service.&nbsp;</p>
  
  <h3>Linking to CPRD.com&nbsp;</h3>
  
  <p>You must contact us for permission if you want to either:</p>
  
  <ul>
      <li>link to any page on CPRD.com</li>
      <li>say your website is associated with or endorsed by CPRD.com or another government department or agency</li>
  </ul>
  
  <h3>Linking from CPRD.com</h3>
  
  <p>CPRD.com may link to websites that are managed by other government departments and agencies, service providers or other organisations. We do not have any control over the content on these websites.</p>
  
  <p>We are not responsible for:</p>
  
  <ul>
      <li>the protection of any information you give to these websites</li>
      <li>any loss or damage that may come from your use of these websites, or any other websites they link to</li>
  </ul>
  
  <p>You agree to release us from any claims or disputes that may come from using these websites.</p>
  
  <p>You should read all terms and conditions, privacy policies and end user licences that relate to these websites before you use them.&nbsp;</p>
  
  <h3>Using CPRD.com content</h3>
  
  <p>Most content on CPRD.com is subject to&nbsp;<a href="http://www.nationalarchives.gov.uk/information-management/re-using-public-sector-information/copyright-and-re-use/crown-copyright/" target="_blank">Crown copyright protection</a>.</p>
  
  <p>Departmental logos and crests are exempt from the Open Government Licence (OGL), except when they form an integral part of a document or dataset.</p>
  
  <p>If any content is not subject to Crown copyright protection or published under the OGL, we will usually credit the author or copyright holder.</p>
  
  <p><a href="mailto:enquiries@cprd.com">Contact us</a>&nbsp;if you want to reproduce a piece of content but are not sure if it is covered by Crown copyright.</p>
  
  <p>The most up to date version of our content will always be on CPRD.com.&nbsp;</p>
  
  <h3>Registering to use this website</h3>
  
  <p>In order to access certain content, systems or interactive features on this website you may be asked to create an account with a password and other log in details. As a minimum, CPRD recommends that passwords you set for use with this website have a level of complexity which ensures they cannot be easily guessed by cyber attackers or malicious software. CPRD recommends that passwords should:</p>
  
  <ul>
      <li>be at least twelve characters long;</li>
      <li>not contain your user name, real name, or organisation name;</li>
      <li>not appear in the list of top 10,000 passwords;</li>
      <li>not use sequential key characters on the keyboard (e.g. qwerty, asdfg, 12345)</li>
  </ul>
  
  <p>In&nbsp;line with Government best practice, we recommend using the ‘three random words’ approach, which uses three or more randomly chosen words like ‘saloonbadgertree’ or ‘coffeetrainfish’. These words should be random, and not related personally to you (so not involving a favourite thing, holiday, relative). Add or substitute some of the lower case letters with at least two of the following: numbers, capital letters and special characters. Passwords must not under any circumstances be shared with anyone.</p>
  
  <p>You may deactivate your account by sending an email to&nbsp;<a href="mailto:enquiries@cprd.com">enquiries@cprd.com</a>&nbsp;requesting deactivation. CPRD will continue to store all personal information required in order to comply with all requirements governing access to CPRD data for observational research studies, openness and transparency, or as required by law.&nbsp;</p>
  
  <h3>Disclaimer</h3>
  
  <p>While we make every effort to keep CPRD.com up to date, we do not provide any guarantees, conditions or warranties that the information will be:</p>
  
  <ul>
      <li>current</li>
      <li>secure</li>
      <li>accurate</li>
      <li>complete</li>
      <li>free from bugs or viruses</li>
  </ul>
  
  <p>We are not liable for any loss or damage that may come from using CPRD.com. This includes:</p>
  
  <ul>
      <li>any direct, indirect or consequential losses</li>
      <li>any loss or damage caused by civil wrongs (‘tort’, including negligence), breach of contract or otherwise</li>
      <li>the use of CPRD.com and any websites that are linked to or from it</li>
      <li>the inability to use CPRD.com and any websites that are linked to or from it</li>
  </ul>
  
  <p>This applies if the loss or damage was foreseeable, arose in the normal course of things or you advised us that it might happen.</p>
  
  <p>This includes (but is not limited to) the loss of your:</p>
  
  <ul>
      <li>income or revenue</li>
      <li>salary, benefits or other payments</li>
      <li>business</li>
      <li>profits or contracts</li>
      <li>opportunity</li>
      <li>anticipated savings</li>
      <li>data</li>
      <li>goodwill or reputation</li>
      <li>tangible property</li>
      <li>intangible property, including loss, corruption or damage to data or any computer system</li>
      <li>wasted management or office time</li>
  </ul>
  
  <p>We may still be liable for:</p>
  
  <ul>
      <li>death or personal injury arising from our negligence</li>
      <li>fraudulent misrepresentation</li>
      <li>any other liability which cannot be excluded or limited under applicable law</li>
  </ul>
  
  <h3>Requests to remove content</h3>
  
  <p>You can ask for content to be removed from CPRD.com. We will remove content:</p>
  
  <ul>
      <li>in order to comply with data protection legislation covering the rights and freedoms of individuals</li>
      <li>if it breaches copyright laws, contains sensitive personal data or material that may be considered obscene or defamatory</li>
  </ul>
  
  <p><a href="mailto:enquiries@cprd.com">Contact us</a>&nbsp;to ask for content to be removed. You will need to send us the web address (URL) of the content and explain why you think it should be removed. We will reply to let you know whether we will remove it.</p>
  
  <p>We remove content at our discretion. You can still request information under the&nbsp;<a href="https://www.gov.uk/make-a-freedom-of-information-request" target="_blank">Freedom of Information Act</a>&nbsp;and the&nbsp;<a href="https://www.gov.uk/data-protection">Data Protection Act</a>.&nbsp;</p>
  
  <h3>Information about you and your visits to CPRD.com</h3>
  
  <p>We collect information about you in accordance with our&nbsp;<a href="https://www.cprd.com/privacy-notice">privacy policy</a>&nbsp;and our&nbsp;<a href="https://www.cprd.com/privacy-notice">cookie policy</a>. By using CPRD.com, you agree to us collecting this information and confirm that any data you provide is accurate.&nbsp;</p>
  
  <h3>Virus protection</h3>
  
  <p>We make every effort to check and test CPRD.com for viruses at every stage of production. You must make sure that the way you use CPRD.com does not expose you to the risk of viruses, malicious computer code or other forms of interference which can damage your computer system.</p>
  
  <p>We are not responsible for any loss, disruption or damage to your data or computer system that might happen when you use CPRD.com.</p>
  
  <h3>Viruses, hacking and other offences</h3>
  
  <p>When using CPRD.com, you must not introduce viruses, trojans, worms, logic bombs or any other material that is malicious or technologically harmful.</p>
  
  <p>You must not try to gain unauthorised access to CPRD.com, the server on which it is stored, or any server, computer or database connected to it.</p>
  
  <p>You must not attack CPRD.com in any way. This includes denial-of-service attacks.</p>
  
  <p>We will report any attacks or attempts to gain unauthorised access to CPRD.com to the relevant law enforcement authorities and share information about you with them.</p>
  
  <h3>Governing law</h3>
  
  <p>These terms and conditions are governed by and construed in accordance with the laws of England and Wales.</p>
  
  <p>Any dispute you have which relates to these terms and conditions, or your use of CPRD.com (whether it be contractual or non-contractual), will be subject to the exclusive jurisdiction of the courts of England and Wales.&nbsp;&nbsp;</p>
  
  <h3>General&nbsp;</h3>
  
  <p>There may be legal notices elsewhere on CPRD.com that relate to how you use the site.</p>
  
  <p>We are not liable if we fail to comply with these terms and conditions because of circumstances beyond our reasonable control.</p>
  
  <p>We might decide not to exercise or enforce any right available to us under these terms and conditions. We can always decide to exercise or enforce that right at a later date.</p>
  
  <p>Doing this once will not mean we automatically waive the right on any other occasion.</p>
  
  <p>If any of these terms and conditions are held to be invalid, unenforceable or illegal for any reason, the remaining terms and conditions will still apply.</p>
  
  <h3>Changes to these terms and conditions</h3>
  
  <p>Please check these terms and conditions regularly. We can update them at any time without notice.</p>
  
  <p>You will agree to any changes if you continue to use CPRD.com after the terms and conditions have been updated.</p>  

  </div>
  
    </div>
  
  </article>
  
    </div>

            </>
        );
    }