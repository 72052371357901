import React, { useState } from "react";

import { Arrow } from './icons/arrow';
import './ToggleButton.css';

type ToggleButtonProps = {
  children: React.ReactNode;
  onClick: () => void;
}

export const ToggleButton = ({ children, onClick }: ToggleButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleFunction = () => {
    setIsOpen(!isOpen);
    onClick();
  }

  return (
    <button className="toggle-button" onClick={toggleFunction}>{children} <Arrow className={isOpen ? "toggle-button__open" : ""} /></button>
  )
}
