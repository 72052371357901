import { useMsal } from "@azure/msal-react";
import { useCallback, useContext, useEffect, useState } from "react";
import { LoadingBox } from "govuk-react";
import { WorkspaceContext } from "../../contexts/WorkspaceContext";
import { AppRolesContext } from "../../contexts/AppRolesContext";
import { useParams } from "react-router-dom";
import { loginRequest, trecoreServicesConfig } from "../Core/authConfig";
import { ApiEndpoint } from "../models/apiEndPoints";
import { CallApiWithToken, HttpMethod } from "../Core/fetch";
import { CustomMessageCard, CustomMessageErrorCard, CustomMessageSuccessCard } from "../Error/MessageCard";
import { CreateGuacamoleForm } from "./CreateGuacamoleForm";
import { NewWorkspaceServiceRequest } from "../models/workspaceServices";
import yn from "yn";
import { FormTitle } from "../ui/FormTitle";
import { Title } from "../ui/Title";

export const CreateGuacamole=(props:any)=>{
  const [isLoading, setIsLoading] = useState(false);
  const { instance, accounts } = useMsal();
  const [responseData, setresponseData] = useState(null);
  const [newWorkspaceData, setnewWorkspaceData] = useState<NewWorkspaceServiceRequest>({} as NewWorkspaceServiceRequest);
  const [errorData, setErrorData] = useState(Error);
  const [isRequestValid, setRequestValid] = useState(false);
  const workspaceCtx = useContext(WorkspaceContext);
  const appRolesCtx = useContext(AppRolesContext);    
  let paramIds = useParams();
  
  let finalurl=`${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${paramIds.id}/${ApiEndpoint.WorkspaceServices}`;    
  
  const GuacamoleHandler= useCallback ( async( newGuacamoleRequest:any) => {
    setErrorData({} as Error);
    setnewWorkspaceData(newGuacamoleRequest);

    if ((newGuacamoleRequest.properties.display_name?.length > 0 && newGuacamoleRequest.properties.description?.length > 0)) {
      setIsLoading(true);
      yn(process.env.REACT_APP_DEBUG) &&  console.log('Trying to submit the request...');
      instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
        scopes: [`${workspaceCtx.workspace.properties.scope_id}/${process.env.REACT_APP_TRE_CORE_API_USER_IMPERSONATION}`] 
      }).then(async (response) => {               
        await CallApiWithToken(response.accessToken,finalurl,HttpMethod.Post,newGuacamoleRequest)
          .then(response => setresponseData(response))
          .catch((err:any)=> setErrorData(err));
            setIsLoading(false);
        }).catch((err:any) => {
          setErrorData(err);
          setIsLoading(false);
      })
      yn(process.env.REACT_APP_DEBUG) && console.log('New Guacamole service request submitted...');            
    } else {
      //console.log('Guacamole name: length is smaller then expected...')
    }
  }, [setnewWorkspaceData, accounts, finalurl, instance, isRequestValid, paramIds.id, workspaceCtx]);

  useEffect(() => {
    newWorkspaceData.properties ?
    setRequestValid((newWorkspaceData.properties.display_name?.length > 0 && newWorkspaceData.properties.description?.length > 0)):<></>
  }, [newWorkspaceData,setRequestValid]);

  let rtnUrl = 'workspaces/' + paramIds.id;;
  let msgprops ={
      msgData:'New Guacamole service requesd posted for Creation and now in queued and pending, please go to resource area to view the progress...',
      linkData:rtnUrl  }
  return (
    responseData ? (
      <>
        <Title>New Guacamole Service</Title>
        {responseData ? <CustomMessageSuccessCard props={msgprops} /> : <CustomMessageCard msgData={errorData} />}
      </>
    ) : (
      <>
        <FormTitle>New Guacamole Service Request</FormTitle>
        {!isLoading && <CreateGuacamoleForm onAddImportRequest={GuacamoleHandler} />}
        {(errorData.message === undefined || errorData === undefined || errorData.message === '') ? (
          <div>
            {isRequestValid && <p>Please enter a valid name/desc for the Guacamole (max 100 characters)</p>}
          </div>
        ) : (
          <CustomMessageErrorCard props={msgprops={msgData:errorData.message,linkData:rtnUrl}} />
        )}
        {isLoading && (
          <LoadingBox loading={isLoading}>
            <p>(Loading...) Please wait while submitting the request for creating new Guacamole...</p>
          </LoadingBox>
        )}
      </>
    )
  );
}
