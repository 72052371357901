import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import {CustomMessageCard, MessageCard} from "./MessageCard";


const NotFound=(props:any)=>{
    //const currentDateTime = format(new Date(), "dd/MM/yyyy HH:mm:ss");
        
        return (
            <>
                <CustomMessageCard msgData={<div>Oops - we've looked everywhere and could not find this page.</div>}></CustomMessageCard>                
            </>
        );
    }

    export default NotFound;