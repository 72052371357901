import React from 'react';
import { Link, Button } from 'govuk-react';
import { useParams, useNavigate } from 'react-router-dom';

import { Title } from '../../../ui/Title';

import './index.css';

export const AirlockExportTriageRejection = () => {
  const navigate = useNavigate();
  const location = useParams();
  const wsId = location.id;

  const goToAirlockDashboard = () => {
    return navigate(`/workspaces/${wsId}/airlocks/`)
  }

  return (
    <>
      <Title>Sorry, we are unable to proceed with your request</Title>
      <p className="airlock-export-triage-rejection__copy">Unfortunately, your output request has been automatically rejected as it doesn’t meet CPRD disclosure control rules. Please refer to our <Link href="/safe-output-guidance">Safe Outputs guidance</Link> for information on how assessments are made.</p>
      <Button className="airlock-export-triage-rejection__button govuk-button" onClick={() => goToAirlockDashboard()}>Back to airlock dashboard</Button>
    </>
  )
}
