import React, { useEffect, useState, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { LoadingBox, Tag } from 'govuk-react';

import { loginRequest, trecoreConfig } from '../components/Core/authConfig';
import { ApiEndpoint } from '../components/models/apiEndPoints';
import { CallApiWithToken, HttpMethod } from '../components/Core/fetch';
import { useAuthApiCall } from '../components/hooks/useAuthAPICall';
import { WorkspaceContext } from '../contexts/WorkspaceContext';
import { useInterval } from '../components/hooks/useInterval';
import { CheckOps } from '../services/CheckOps';

import { Title } from '../components/ui/Title';
import { Subtitle } from '../components/ui/Subtitle';
import { Selectbox } from '../components/ui/Selectbox';
import { SearchBox } from '../components/ui/SearchBox';
import { MessageCard } from '../components/Error/MessageCard';
import Pagination from '../components/ui/Pagination';
import { TableHead } from '../components/ui/TableHead';
import { TextButton } from '../components/ui/TextButton';
import { Modal } from '../components/ui/Modal';
import { NotificationBox } from '../components/ui/NotificationBox';
import { Td } from '../components/ui/GDS-components/Table';

// temporary
import { testWorkspaceCosts } from '../services/CombineWorkspacesAndCosts';

import "./ManageWorkspaces.css";

export const ManageWorkspaces = () => {
  const { instance, accounts } = useMsal();
  const apiCall = useAuthApiCall();
  const workspaceCtx = useContext(WorkspaceContext);
  const [workspaces, setWorkspaces] = useState([]);
  const [workspaceCosts, setWorkspaceCosts] = useState([]);
  const [allCombinedData, setAllCombinedData] = useState([]);
  const [combinedData, setCombinedData] = useState([]);
  const [searchTerm, setSearchTerm] = useState<string | null>();
  const [sort, setSort] = useState<string | null>();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [pausedWorkspace, setPausedWorkspace] = useState(false);
  const [changeStateError, setChangeStateError] = useState(null);
  const [selectedWorkspace, setSelectedWorkspace] = useState<any>([]);
  const [selectedAction, setSelectedAction] = useState<null | string>(null);
  const [confirmPauseDelete, setConfirmPauseDelete] = useState(false);
  const [updatedWorkspace, setUpdatedWorkspace] = useState<null | string>(null);
  const [refresh, setRefresh] = useState(false);
  const location: any = useLocation();

  useEffect(() => {
    setIsLoading(true);
    // fetch workspaces
    instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
      scopes: trecoreConfig.scopes
    }).then(async (response) => {
      await CallApiWithToken(response.accessToken, `${trecoreConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}`, HttpMethod.Get, '')
      .then(response => {
        setWorkspaces(response.workspaces);
      })
      .catch((err: any) => {
        setError(err);
        setIsLoading(false);
      })
    })
    // fetch workspace costs
    instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
      scopes: trecoreConfig.scopes
    }).then(async (response) => {
      await CallApiWithToken(
        response.accessToken,
        `${trecoreConfig.trecoreEndpoint}/${ApiEndpoint.WorskspaceCosts}`,
        HttpMethod.Get,
        ''
      ).then(response => {
        setWorkspaceCosts(response.workspace_costs_items);
        console.log("response.workspace_costs_items: ", response.workspace_costs_items);
        setIsLoading(false);
      }).catch((err: any) => {
        console.log("error: ", err);
        setError(err);
        setIsLoading(false);
      })
    });
    pausedWorkspace && setPausedWorkspace(false);
    refresh && setRefresh(false);
    refresh && setUpdatedWorkspace(null);
  }, [pausedWorkspace, refresh]);

  const combineData = () => {
    let combinedArray: any = [];
    workspaces.map((workspace: any, i: number) => {
      const combined = Object.assign({}, workspace, {costs: workspaceCosts.filter((item: any) => item.workspace_id === workspace.id)});
      combinedArray.push(combined);
    })
    return (
      setAllCombinedData(combinedArray),
      setCombinedData(combinedArray)
    );
  }

  useEffect(() => {
    combineData()
  }, [workspaces, workspaceCosts]);

  const sortWorkspaces = (e: string, workspaces: any = allCombinedData) => {
    if (e === null) {
      return workspaces;
    } else if (e === "active") {
      return workspaces.filter((item: any) => item.isEnabled);
    } else {
      return workspaces.filter((item: any) => !item.isEnabled);
    }
  }

  const searchWorkspaces = (e: string, workspaces: any = allCombinedData) => {
    const searchedTerm = e.toLowerCase();
    let newIds = workspaces;
    let newNames = workspaces;

    const filteredByIds = newIds.filter((item: any) => item.id.toLowerCase().includes(searchedTerm));
    const filteredByName = newNames.filter((item: any) => item.properties.display_name.toLowerCase().includes(searchedTerm));

    const searchWorkspaces = filteredByIds.concat(filteredByName);

    const newWorkspaces: any = Array.from(new Set(searchWorkspaces));
    return newWorkspaces;
  } 

  const onSearch = (e: string) => {
    const searchedTerm = e.toLowerCase();
    setSearchTerm(searchedTerm);
    sort ? setCombinedData(searchWorkspaces(e, sortWorkspaces(sort))) : setCombinedData(searchWorkspaces(e));
  }

  const onSort = (e: string) => {
    setSort(e);
    sortWorkspaces(e);
    searchTerm ? setCombinedData(sortWorkspaces(e, searchWorkspaces(searchTerm))) : setCombinedData(sortWorkspaces(e));
  }

  const onPause = async (id: string, etag: string, enable: boolean) => {
    const pause = {
      isEnabled: enable
    }
    setIsLoading(true);
    await apiCall(`/${ApiEndpoint.Workspaces}/${id}`, HttpMethod.Patch, workspaceCtx.workspaceApplicationIdURI, pause, undefined, undefined, false, etag)
    .then(response => {
      setPausedWorkspace(true);
      setIsLoading(false);
      setChangeStateError(null);
      setUpdatedWorkspace(id);
    })
    .catch((err: any) => {
      setIsLoading(false);
      setChangeStateError(err.message);
    })
  }

  const onDelete = async (id: string) => {
    setIsLoading(true);
    await apiCall(`/${ApiEndpoint.Workspaces}/${id}`, HttpMethod.Delete, workspaceCtx.workspaceApplicationIdURI)
    .then(response => {
      setChangeStateError(null);
      setPausedWorkspace(true);
      setIsLoading(false);
      setUpdatedWorkspace(id);
    })
    .catch((err: any) => {
      setIsLoading(false);
      setChangeStateError(err.message);
    })
  }

  const notificationCopy = selectedAction === "delete" ? (
    `The following workspace is awaiting deletion: ${selectedWorkspace?.properties?.display_name}`
  ) : (
    `The following workspace has been ${selectedAction}d: ${selectedWorkspace?.properties?.display_name}`
  );

  console.log("combinedData: ", combinedData);
  console.log("workspaces: ", workspaces);

  return (
    <>
      {location.state && (!changeStateError || !selectedWorkspace?.properties) && !confirmPauseDelete && (
        <NotificationBox
          error={null}
          text="The following workspace has been updated: "
          updated={location.state.updated}
        />
      )}
      {error ? (
        <MessageCard msgData={error} />
      ) : (
        <section>
          {(changeStateError || selectedWorkspace?.properties) && confirmPauseDelete && (
            <NotificationBox
              error={changeStateError}
              text={notificationCopy}
            />
          )}
          <header>
            <Title>Manage workspaces</Title>
            <Subtitle>List of workspaces</Subtitle>
          </header>
          <LoadingBox loading={isLoading}>
            <Selectbox
              className="manage-workspaces__select"
              label="Filter by"
              options={
                [
                  {
                    name: "Active",
                    value: "active"
                  },
                  {
                    name: "Inactive",
                    value: "inactive"
                  }
                ]
              }
              onChange={(e: any) => onSort(e)}
            />
            <SearchBox
              className="manage-workspaces__search"
              placeholder="Search for a workspace or ID"
              onSearch={(e) => onSearch(e.target.value)}
            />
            {workspaces && (
              <Workspaces
                workspaces={combinedData}
                onPause={onPause}
                onDelete={onDelete}
                selectedAction={selectedAction}
                setSelectedAction={setSelectedAction}
                selectedWorkspace={selectedWorkspace}
                setSelectedWorkspace={setSelectedWorkspace}
                setConfirmPauseDelete={setConfirmPauseDelete}
                updatedWorkspace={updatedWorkspace}
                setRefresh={setRefresh}
                searchTerm={searchTerm}
                onSearch={onSearch}
                sort={sort}
                onSort={onSort}
              />
            )}
          </LoadingBox>
        </section>
      )}
    </>
  )
}

const Workspaces = ({
  workspaces,
  onPause,
  onDelete,
  selectedAction,
  setSelectedAction,
  selectedWorkspace,
  setSelectedWorkspace,
  setConfirmPauseDelete,
  updatedWorkspace,
  setRefresh,
  searchTerm, 
  onSearch, 
  sort, 
  onSort
}: any) => {
  const [data, setData] = useState(workspaces);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setData(workspaces.slice(indexOfFirstRecord, indexOfLastRecord));
  }, [workspaces, currentPage]);

  console.log("data: ", data);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const nPages = workspaces && Math.ceil(workspaces.length / recordsPerPage);

  const tableHeaders = [
    {
      header: "Workspace name"
    },
    {
      header: "ID"
    },
    {
      header: "CUP"
    },
    {
      header: "Status",
      colSpan: 4
    }
  ];

  const openModal = (workspace: any, action: string) => {
    setModalOpen(true);
    setSelectedWorkspace(workspace);
    setSelectedAction(action);
  }

  const confirmAction = () => {
    selectedAction === "delete" ? onDelete(selectedWorkspace.id) : onPause(selectedWorkspace.id, selectedWorkspace._etag, !selectedWorkspace.isEnabled);
    setModalOpen(false);
    setConfirmPauseDelete(true);
  }

  const cancelAction = () => {
    setConfirmPauseDelete(false);
    setModalOpen(false);
  }

  const actionCopy = selectedAction === "delete" ? (
    "You are about to delete this workspace, are you sure you want to delete this workspace permanently?"
  ) : (
    `You are about to ${selectedAction} this workspace temporarily, are you sure you want to ${selectedAction} this workspace?`
  );

  return (
    <>
      {modalOpen && selectedAction && (
        <Modal
          action={selectedAction}
          copy={actionCopy}
          onAction={() => confirmAction()}
          onExit={() => cancelAction()}
          title={`Are you sure you want to ${selectedAction} this workspace?`}
        />
      )}
      <table className="manage-workspaces__table">
        <TableHead headers={tableHeaders} />
        <tbody>
          {data.map((workspace: any) => (
            <Workspace
              key={workspace.id}
              workspace={workspace}
              openModal={openModal}
              updatedWorkspace={updatedWorkspace}
              setRefresh={setRefresh}
              searchTerm={searchTerm}
              onSearch={onSearch}
              sort={sort}
              onSort={onSort}
            />
          ))}
        </tbody>
      </table>
      {nPages > 1 && (
        <Pagination
          className="manage-workspaces__pagination"
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  )
}

const Workspace = ({
  workspace,
  openModal,
  updatedWorkspace,
  setRefresh,
  searchTerm,
  onSearch,
  sort,
  onSort
}: any) => {
  const { instance, accounts } = useMsal();
  const [operations, setOperations] = useState<any>();
  const [updating, setUpdating] = useState(false);

  const callOperations = () => {
    instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
      scopes: [`${workspace.properties.scope_id}/${process.env.REACT_APP_TRE_CORE_API_USER_IMPERSONATION}`]
    }).then(async (response) => {
      await CallApiWithToken(
        response.accessToken,
        `${trecoreConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${workspace.id}/${ApiEndpoint.Operations}`,
        HttpMethod.Get,
        ''
      ).then(response => {
        setOperations(response.operations);
        CheckOps(workspace, response.operations, setUpdating, setRefresh, updating);
      })
    })
  }

  useEffect(() => {
    callOperations();
  }, []);

  useEffect(() => {
    searchTerm && onSearch(searchTerm);
    sort && onSort(sort);
  }, [updating]);

  useEffect(() => {
    updatedWorkspace === workspace.id && callOperations()
  }, [updatedWorkspace]);

  return (
    <WorkspaceItem
      workspace={workspace}
      openModal={openModal}
      updating={updating}
      callOperations={callOperations}
    />
  )
}

const WorkspaceItem = ({ updating, workspace, openModal, callOperations }: any) => {
  useInterval(() => callOperations(), updating ? 10000 : null);

  return (
    <tr>
      <Td>
        {updating || workspace.deploymentStatus === "deployment_failed" ? (
          workspace.properties.display_name
        ) : (
          <Link className="manage-workspaces__link" to={`/manage-workspaces/${workspace.id}`}>{workspace.properties.display_name}</Link>
        )}
      </Td>
      <Td>{workspace.id}</Td>
      <Td>
        {updating ? (
          <Tag backgroundColor="#eeefef" color="#383f43">Updating</Tag>
        ) : (
          workspace.costs.length > 0 && Math.trunc(workspace.costs[0].credit_percentage_usage)
        )}
      </Td>
      <Td>
        {updating ? (
          <Tag backgroundColor="#eeefef" color="#383f43">Updating</Tag>
        ) : (
          workspace.deploymentStatus === "deployment_failed" ? (
            <Tag backgroundColor="#eeefef" color="#383f43">Deployment failed</Tag>
          ) : (
            workspace.isEnabled ? <Tag tint="GREEN">Active</Tag> : <Tag tint="RED">Inactive</Tag>
          )
        )}
      </Td>
      <Td>
        {updating ? (
            <>loading...</>
          ) : (
            !workspace.isEnabled && <TextButton onClick={() => openModal(workspace, "delete")}>Delete</TextButton>
          )}
      </Td>
      <Td>
        {workspace.deploymentStatus !== "deployment_failed" && (
          updating ? (
            <>loading...</>
          ) : (
            <Link to={`/Workspaces/${workspace.id}/update-workspace`}>Update</Link>
          )
        )}
      </Td>
      <Td>
        {updating ? (
          <>loading...</>
        ) : (
          <TextButton onClick={() => openModal(workspace, workspace.isEnabled ? "pause" : "unpause")}>
            {workspace.isEnabled ? "Pause" : "Unpause"}
          </TextButton>
        )}
      </Td>
    </tr>
  )
}
