import React from 'react';
import { Label } from 'govuk-react';

import './FormLabel.css';

type FormLabelProps = {
  children: React.ReactNode;
  error?: any;
  id?: string;
}

export const FormLabel = ({ children, error = false, id }: FormLabelProps) => {
  return (
    <Label className="FormLabel" id={id} error={error}>{children}</Label>
  )
}
