import React from 'react';

import './Subtitle.css';

type SubtitleProps = {
  children: React.ReactNode;
  className?: string;
}

export const Subtitle = ({ children, className }: SubtitleProps) => {
  const classNames = `subtitle ${className}`;
  return (
    <h2 className={classNames}>{children}</h2>
  )
}
