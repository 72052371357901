import { loginRequest, trecoreServicesConfig } from "../components/Core/authConfig";
import { ApiEndpoint } from "../components/models/apiEndPoints";
import { CallApiWithToken, HttpMethod } from "../components/Core/fetch";

import { wsServiceCheck } from "../types";

export const CheckServiceDeployments = (
  instance: any,
  accounts: any,
  scope_id: string,
  e: any,
  setErrors: any,
  setErrorData: any,
  submitFunction: any,
  id: string | undefined,
  type: string
  ) => {
  const apiUrl = `${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${id}/${ApiEndpoint.WorkspaceServices}`;

  return instance.acquireTokenSilent({
    ...loginRequest,
    account: accounts[0],
    scopes: [`${scope_id}/${process.env.REACT_APP_TRE_CORE_API_USER_IMPERSONATION}`]
  }).then(async (response: any) => {
    await CallApiWithToken(
      response.accessToken,
      apiUrl,
      HttpMethod.Get,
      ''
    ).then(response => {
      const checkType = response.workspaceServices?.filter((item: any) => item.templateName.includes(type));
      const deploying = response.workspaceServices?.filter((item: any) => item.deploymentStatus === "deploying" || item.deploymentStatus === "awaiting_deployment" || item.deploymentStatus === null);
      if (checkType && checkType.length > 0) {
        setErrors([{
          targetName: "",
          text: "This workspace already has this type of service and you can only have one per workspace"
        }])
      } else {
        return deploying === undefined ? (
          submitFunction(e)
        ) : (
          deploying.length > 0 ? (
            setErrors([{
              targetName: "",
              text: "A workspace is currently being deployed, you can create another workspace once this has completed"
            }])
          ) : (
            submitFunction(e)
          )
        )
      }
    }).catch((err: any) => {
      setErrorData(err);
    })
  })
}

export const CheckCurrentServices = (
  instance: any,
  accounts: any,
  scope_id: string,
  setErrorData: any,
  setLoading: (loading: boolean) => void,
  setWsServiceDeploying: (deploying: boolean) => void,
  setWorkspaceServices: (services: wsServiceCheck) => void,
  id: string | undefined,
) => {
  const apiUrl = `${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${id}/${ApiEndpoint.WorkspaceServices}`;

  return instance.acquireTokenSilent({
    ...loginRequest,
    account: accounts[0],
    scopes: [`${scope_id}/${process.env.REACT_APP_TRE_CORE_API_USER_IMPERSONATION}`]
  }).then(async (response: any) => {
    await CallApiWithToken(
      response.accessToken,
      apiUrl,
      HttpMethod.Get,
      ''
    ).then(response => {
      const gitea = response.workspaceServices?.filter((item: any) => item.templateName.includes("gitea"));
      const guacamole = response.workspaceServices?.filter((item: any) => item.templateName.includes("guacamole"));
      const atlas = response.workspaceServices?.filter((item: any) => item.templateName.includes("ohdsi"));
      const services = {
        hasGitea: gitea.length > 0,
        hasGuacamole: guacamole.length > 0,
        hasAtlas: atlas.length > 0,
        hasEachService: gitea.length > 0 && guacamole.length > 0 && atlas.length > 0
      }
      setWorkspaceServices(services);
      const deploying = response.workspaceServices?.filter((item: any) => item.deploymentStatus === "deploying" || item.deploymentStatus === "awaiting_deployment" || item.deploymentStatus === null);
      setWsServiceDeploying(deploying?.length > 0);
      setLoading(false);
    }).catch((err: any) => {
      setErrorData(err);
      setLoading(false);
    })
  })
}
