import React from "react";

import "./SafeInsetText.css";

type SafeInsetTextProps = {
  children: React.ReactNode;
  online: boolean;
}

export const SafeInsetText = ({ children, online }: SafeInsetTextProps) => {
  return (
    <div className={`gds-inset-text ${online ? "gds-inset-text--online" : "gds-inset-text--offline"}`}>
      {children}
    </div>
  )
}
