
import { graphConfig, loginRequest } from "../components/Core/authConfig";
import { ProfileData } from "../Profile/ProfileData";
import { CallApiWithToken, HttpMethod } from "../components/Core/fetch";
import { useMsal } from "@azure/msal-react";
import { useCallback, useEffect, useState } from "react";
import {MessageCard} from "../components/Error/MessageCard";
import { Offline, Online } from "react-detect-offline"


//https://stackoverflow.com/questions/40248639/detect-network-connection-in-react-redux-app-if-offline-hide-component-from-u

export const Profile=(props:any)=> {
  // Silently acquires an access token which is then attached to a request for MS Graph data
  const { instance, accounts } = useMsal();
  const [graphData, setGraphData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorData, setErrorData] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [ipData,setIpData] = useState(null);

  useEffect(() => {
      setIsLoading(true);
  instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0]            
  }).then(async (res) => {
    await CallApiWithToken(res.accessToken, graphConfig.graphMeEndpoint, HttpMethod.Get, '')
      .then(async (response) => {
        setGraphData(response)
        await CallApiWithToken(res.accessToken, `${graphConfig.graphUsersEndpoint}/${response.id}?$select=companyName`, HttpMethod.Get, '')
          .then(response => {
            setCompanyName(response.companyName);
          })
          .catch((err => setErrorData(err)))
      })
      .catch(err=> setErrorData(err));
        setIsLoading(false);
  }).catch((err:any)=> 
  {
    setErrorData(err);
    setIsLoading(false);
  });
  }, [ instance, accounts ]);

  if (isLoading) {
    return (
      <section>
        <p>Loading...</p>
      </section>
    );
  }

  return (
    graphData ? <ProfileData graphData={graphData} companyName={companyName} /> : <MessageCard msgData={errorData}></MessageCard>               
  );
}
