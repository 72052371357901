import React from 'react';
import { Tag } from 'govuk-react';

type GetTagProps = {
  roles: any;
}

export const GetTag = ({ roles }: GetTagProps) => {
  const listOfRoles = roles;

  const getRoleTag = (role: string) => {
    switch (role) {
      case "treadmin":
        return <Tag key="1" backgroundColor="#912b88" color="#fff">admin</Tag>;
      case "airlock manager":
        return <Tag key="2" backgroundColor="#4c2c92" color="#fff">{role}</Tag>
      case "treuser":
        return <Tag key="3" backgroundColor="#6f72af" color="#fff">researcher</Tag>
      case "workspace owner":
        return <Tag key="4" backgroundColor="#f7d7e6" color="#80224d">{role}</Tag>
      default:
        return <Tag key="5" backgroundColor="#dbd5e9" color="#383f43">"Request name"</Tag>;
    }
  };

  return listOfRoles.map((role: string) => getRoleTag(role.toLowerCase()));
}

type GetStatusTagProps = {
  status: string;
}

export const GetStatusTag = ({ status }: GetStatusTagProps) => {
  const formattedStatus = status.replace(/_/g, ' ');

  const getTag = (status: string) => {
    switch (status) {
      case "DRAFT":
        return <Tag tint="BLUE">{status}</Tag>
      case "IN REVIEW":
        return <Tag tint="YELLOW">{status}</Tag>
      case "APPROVED":
        return <Tag tint="TURQUOISE">{status}</Tag>
      case "REJECTED":
        return <Tag tint="RED">{status}</Tag>
      case "FAILED":
        return <Tag tint="RED">{status}</Tag>
      default:
        return <Tag tint="BLUE">{status}</Tag>
    }
  }

  return getTag(formattedStatus.toUpperCase());
}
