import React from 'react';

type ArrowProps = {
  className?: string;
}

export const Arrow = ({ className }: ArrowProps) => {
  return (
    <svg className={className} width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 -7.15256e-07L10 6L-1.19209e-06 6L5 -7.15256e-07Z" fill="#605E5C"/>
    </svg>
  )
}
