type CloseIconProps = {
  className?: string;
}

export const CloseIcon = ({ className }: CloseIconProps) => {
  return (
    <svg aria-labelledby="title" className={className} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title id="title" lang="en">Close</title>
      <path d="M1 17L9 9L17 17M17 1L8.99847 9L1 1" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
