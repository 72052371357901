
import "./Home.css";


export const Contact=(props:any)=>{    
        
        return (
            <>
               <div id="block-cprd-page-title" className="block block-core block-page-title-block">      
                  <h1 className="page-title"><span property="schema:name" className="field field--name-title field--type-string field--label-hidden">Contact</span>
                  </h1>
               </div>

               <div property="schema:text" className="clearfix text-formatted field field--name-body field--type-text-with-summary field--label-hidden govuk-body govuk-!-font-size-19 field__item"><p>Clinical Practice Research Datalink<br/>
The Medicines and Healthcare products Regulatory Agency<br/>
10th Floor<br/>
10 South Colonnade<br/>
Canary Wharf<br/>
London E14 4PU<br/>
United Kingdom</p>

<p>CPRD Enquiries: +44 (0)20 3080 6383</p>

<p><a href="mailto:enquiries@cprd.com">enquiries@cprd.com</a>&nbsp;&nbsp;</p>

<p><strong><a href="/news/covid-19-contingency-response">COVID-19 contingency response</a>&nbsp;</strong></p><br/>
<br/>
For press enquiries please contact the&nbsp;<a href="https://www.gov.uk/guidance/contact-mhra#news-centre" target="_blank">MHRA News Centre</a>.<p/>

<p><iframe max-width="600px" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2483.4671168579143!2d-0.02412734805087928!3d51.50464567953466!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487602b78ad90099%3A0x1ea648edeb553a32!2s10+S+Colonnade%2C+Poplar%2C+London+E14+4PH!5e0!3m2!1sen!2suk!4v1530260535128" width="75%" height="450"></iframe></p>

<p>&nbsp;</p>

<p><a href="https://www.google.com/maps/place/10+S+Colonnade,+Canary+Wharf,+London+E14+4QQ/@51.504628,-0.021863,16z/data=!4m5!3m4!1s0x487602b78ad90099:0x1ea648edeb553a32!8m2!3d51.5046283!4d-0.021863?hl=en-GB" target="_blank">View larger map</a></p>

<p><a href="https://support.google.com/maps/answer/6396990?co=GENIE.Platform%3DDesktop&amp;hl=en" target="_blank">Accessibility in Google Maps</a></p>
</div>

            </>
        );
    }