import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useMsal } from "@azure/msal-react";
import yn from "yn";

import { CallApiWithToken, HttpMethod } from "../../Core/fetch";
import { loginRequest, trecoreServicesConfig } from "../../Core/authConfig";
import {MessageCard} from "../../Error/MessageCard";
import { ApiEndpoint } from "../../models/apiEndPoints";
import { WorkspaceContext } from "../../../contexts/WorkspaceContext";
import { AppRolesContext } from "../../../contexts/AppRolesContext";
import { AirlockAdminHistoryData } from "./AirlockAdminHistoryData";
import { Title } from "../../ui/Title";
import { Selectbox } from "../../ui/Selectbox";
import { SearchBox } from "../../ui/SearchBox";

import "../Airlock.css";

export const AirlockAdminHistory = (props: any) => {
  const [isLoading, setIsLoading] = useState(true);    
  const [filterStatus,setfilterStatus]= useState<string>('');    
  const [titleSearch,setTitleSearch]= useState<string>('');
  const { instance, accounts } = useMsal();    
  const [airlockListData, setairlockListData] = useState(null);
  const [errorData, setErrorData] = useState(null);    
  let { id } = useParams();
  const workspaceCtx = useContext(WorkspaceContext);
  const appRolesCtx = useContext(AppRolesContext);

  let finalurl: string;
  finalurl = `${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${id}/${ApiEndpoint.AirlockRequests}?`;

  if (filterStatus!=='') {
    finalurl += `&status=${filterStatus}`;
  }

  useEffect(() =>  {
    if(workspaceCtx.workspace.properties !== undefined && workspaceCtx.workspace.id === id) {
      yn(process.env.REACT_APP_DEBUG) &&  console.info('FINAL URL',finalurl);
        instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
            scopes: [`${workspaceCtx.workspace.properties.scope_id}/${process.env.REACT_APP_TRE_CORE_API_USER_IMPERSONATION}`] 
        }).then(async (response) => {
          await CallApiWithToken(response.accessToken,finalurl ,HttpMethod.Get,'').then(response => setairlockListData(response))
          .catch((err:any)=> setErrorData(err));
            setIsLoading(false);
        }).catch((err:any) => {
          setErrorData(err);
          setIsLoading(false);
        });
    }
  }, [id, workspaceCtx.workspace.id, workspaceCtx.workspace.properties, instance, accounts, workspaceCtx.roles, finalurl]);

  if (isLoading) {
    return (
      <section>
        <p>Loading...</p>
      </section>
    );
  }

  if(airlockListData) {
    yn(process.env.REACT_APP_DEBUG) && console.info('Airlock: workpace roles...',workspaceCtx.roles);        
    yn(process.env.REACT_APP_DEBUG) && console.info('Airlock: App roles...',appRolesCtx.roles);
  }

  const filterStatusAction = (value: string) => {
    value === null ? (
      setfilterStatus("")
    ) : (
      setfilterStatus(value)
    );
  }

  const titleSearchAction = (value: string) => {
    setTitleSearch(value);
  }

  return (    
    <>           
      <div>
        <Title>Airlock Admin History</Title>
        <div className="Airlock__margin">
          <Selectbox
            label="Filter by status"
            options={[
              {
                name: "Approved",
                value: "approved"
              },
              {
                name: "Rejected",
                value: "rejected"
              },
              {
                name: "Failed",
                value: "failed"
              }
            ]}
            onChange={(e: any) => filterStatusAction(e)}
          />
        </div>
        <div className="Airlock__margin">
          <SearchBox
            placeholder="Search by name"
            onSearch={(e: any) => titleSearchAction(e.target.value)}
          />
        </div>
        {airlockListData ? (
          <div className="Airlock__margin">
            <AirlockAdminHistoryData airlockListParam={airlockListData} searchByTitleParam={titleSearch} />
          </div>
        ) : (
          <div className="Airlock__margin">
            <MessageCard msgData={errorData} />
          </div>
        )}
      </div>
    </>
  );
}
