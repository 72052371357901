import { SearchIcon } from '../icons/SearchIcon';

import './SearchBox.css';

type SearchBoxProps = {
  onSearch: (e: any) => void;
  placeholder: string;
  className?: string;
}

export const SearchBox = ({ onSearch, placeholder, className }: SearchBoxProps) => {
  const classNames = `search-box ${className}`;

  return (
    <div className={classNames}>
      <input className="search-box__input" type="search" placeholder={placeholder} onChange={onSearch} />
      <button className="search-box__button" onSubmit={onSearch}>
        <SearchIcon />
      </button>
    </div>
  )
}
