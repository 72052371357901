import React from 'react';
import { Link } from 'react-router-dom';

import './ButtonLink.css';

type ButtonLinkProps = {
  children: React.ReactNode;
  className?: string;
  to?: string;
  href?: string;
  target?: string;
}

export const ButtonLink = ({ children, className, to, href, target }: ButtonLinkProps) => {
  const classNames = `govuk-button button-link${className ? ` ${className}` : ''}`;
  return (
    <>
      {to && (
        <Link className={classNames} to={to}>{children}</Link>
      )}
      {href && (
        <a className={classNames} href={href} target={target}>{children}</a>
      )}
    </>
  )
}
