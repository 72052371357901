import React from 'react';

import './Table.css';

type ChildrenProps = {
  children: React.ReactNode;
  scope?: string;
  colspan?: number;
  className?: string;
}

export const Th = ({ children, scope = "col", colspan = 1 }: ChildrenProps) => {
  return <th className="gds-table__head" scope={scope} colSpan={colspan}>{children}</th>
}

export const Td = ({ children, className, colspan = 1 }: ChildrenProps) => {
  const classNames = `gds-table__cell${className ? ` ${className}` : ""}`
  return (
    <td className={classNames} colSpan={colspan}>{children}</td>
  )
}
