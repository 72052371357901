import { useCallback, useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";
import yn from "yn";

import { WorkspaceContext } from "../../contexts/WorkspaceContext";
import { HttpMethod } from "../Core/fetch";
import { CustomMessageCard } from "../Error/MessageCard";
import { useAuthApiCall } from "../hooks/useAuthAPICall";
import { ApiEndpoint } from "../models/apiEndPoints";
import { OnlineStatus } from "../../services/OnlineStatus";
import { Lede } from "../ui/Lede";

import "./Airlock.css";

export const AirlockDownloadcmp = (props:any) => {
  const apiCall = useAuthApiCall();
  const type = props.uploadtype.toLowerCase();
  
  const [filesLink, setFilesLink] = useState<string>();
  const [filesLinkError, setFilesLinkError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isOnline, setIsOnline] = useState(true);
  
  const workspaceCtx = useContext(WorkspaceContext);
  let wsId = useParams();
  let location = useLocation()
  let stateData:any = location.state

  useEffect(() => {
    OnlineStatus(setIsOnline, setLoading);
    generateFilesLink();
  }, [])
      

  const generateFilesLink = useCallback( async () => {
    try {
      if (wsId.id != undefined) {
        let airlockSaSTokenurl = `${ApiEndpoint.Workspaces}/${wsId.id}/${ApiEndpoint.AirlockRequests}/${wsId.aid}/${ApiEndpoint.AirlockLink}`;
        const linkObject = (await apiCall(airlockSaSTokenurl, HttpMethod.Get, workspaceCtx.workspaceApplicationIdURI));

        if (stateData.stateObj.files != undefined) {
          yn(process.env.REACT_APP_DEBUG) && console.info('file exist...')
          yn(process.env.REACT_APP_DEBUG) && console.info(linkObject.containerUrl.replace(wsId.aid,wsId.aid + '/' + stateData.stateObj.files[0].name));
          setFilesLink(linkObject.containerUrl.replace(wsId.aid,wsId.aid + '/' + stateData.stateObj.files[0].name));
        } else {
          yn(process.env.REACT_APP_DEBUG) && console.info('NO file exist...')
          setFilesLink(linkObject.containerUrl);
        }
      }
    } catch (err: any) {
      err.userMessage = 'Error retrieving storage link';
      //setApiFilesLinkError(err);
      setFilesLinkError(true);
    }
  }, [apiCall]);

  console.log("props.review: ", props.reviewing);
  console.log("filesLink: ", filesLink);
  console.log("stateData.stateObj: ", stateData.stateObj);

  return (
    <div className="Airlock__margin">
      {loading ? (
        <p>loading...</p>
      ) : (
        type === "import" ? (
          isOnline ? (
            <Lede>Download function is only available in your CPRD Safe workspace</Lede>
          ) : (
            <>
              {filesLink && (
                <a
                  href={filesLink}
                  target="_blank"
                  className="govuk-button"
                  data-module="govuk-button"
                  onClick={() => props.setFileDownloaded(true)}
                  download={stateData.stateObj.files !== "undefined" ? stateData.stateObj.files[0].name : "Downloaded file"}
                >
                  Download
                </a>
              )}
              {filesLinkError && <CustomMessageCard props={filesLinkError} />}
            </>
          )
        ) : (
          isOnline ? (
            <>
              {props.reviewing ? (
                <Lede>Download function is only available in your CPRD Safe workspace</Lede>
              ) : (
                <>
                  {filesLink && (
                    <a
                      href={filesLink}
                      target="_blank"
                      className="govuk-button"
                      data-module="govuk-button"
                      onClick={() => props.setFileDownloaded(true)}
                      download={stateData.stateObj.files !== "undefined" ? stateData.stateObj.files[0].name : "Downloaded file"}
                    >
                      Download
                    </a>
                  )}
                  {filesLinkError && <CustomMessageCard props={filesLinkError} />}
                </>
              )}
            </>
          ) : (
            <>
              {props.reviewing ? (
                <>
                  {filesLink && (
                    <a href={filesLink} target="_blank" className="govuk-button" data-module="govuk-button" onClick={() => props.setFileDownloaded(true)}>Download</a>
                  )}
                  {filesLinkError && <CustomMessageCard props={filesLinkError} />}
                </>
              ) : (
                <Lede>Export function is only available outside your CPRD Safe workspace</Lede>
              )}
            </>
          )
        )
      )}
    </div>
  );
}
