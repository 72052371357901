import React, { useState, useEffect } from 'react';
import { SkipLink } from "govuk-react";
import { AuthenticatedTemplate } from '@azure/msal-react';

import { FooterBar } from "./FooterBar";
import { NavigationBar } from "./NavigationBar";
import { HeaderBar } from "./HeaderBar";
import { MainDisplay } from "./MainDisplay";
import { SafeInsetText } from '../ui/GDS-components/SafeInsetText';

import { OnlineStatus } from "../../services/OnlineStatus";

/**
 * Renders the navbar component with a sign-in or sign-out button depending on whether or not a user is authenticated
 * @param props 
 */
export const PageLayout = (props: { children: any; }) => {
	const [isOnline, setIsOnline] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	useEffect(() => {
		OnlineStatus(setIsOnline, setIsLoading)
	}, []);

	return (
		<>
			<SkipLink />
			<HeaderBar />
			<div className="govuk-width-container layout-container">
				<NavigationBar />
				<AuthenticatedTemplate>
					{isLoading ? (
						<p>Loading...</p>
					) : (
						<SafeInsetText online={isOnline}>
							You are {isOnline ? "outside" : "inside"} CPRD Safe
						</SafeInsetText>
					)}
				</AuthenticatedTemplate>
				<MainDisplay children={props.children} />
			</div>
			<footer>
				<FooterBar children={undefined}/>
			</footer>
		</>
	);   
};
