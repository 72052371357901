import React, { useEffect, useState, useContext } from 'react';
import { useMsal } from '@azure/msal-react';
import { Link } from 'react-router-dom';
import { LoadingBox } from "govuk-react";

import { AppRolesContext } from '../contexts/AppRolesContext';
import { WorkspaceRoleName, RoleName } from "../components/models/roleNames";
import { loginRequest, trecoreConfig, trecoreServicesConfig } from "../components/Core/authConfig";
import { CallApiWithToken, HttpMethod } from "../components/Core/fetch";
import { ApiEndpoint } from "../components/models/apiEndPoints";
import { VMPowerStates } from '../components/models/resource';

import { Title } from "../components/ui/Title";
import { Subtitle } from "../components/ui/Subtitle";
import { Lede } from "../components/ui/Lede";
import { GetTag } from "../components/ui/GetTag";
import Pagination from '../components/ui/Pagination';
import { ButtonLink } from '../components/ui/ButtonLink';
import { SearchBox } from '../components/ui/SearchBox';
import { MessageCard } from '../components/Error/MessageCard';
// temporary
import { testWorkspaceCosts } from '../services/CombineWorkspacesAndCosts';

import './dashboard.css';

export const Dashboard = () => {
  const { instance, accounts } = useMsal();
  const user = useContext(AppRolesContext);
  const [isLoading, setIsLoading] = useState(true);
  const [allWorkspaces, setAllWorkspaces] = useState([]);
  const [workspaces, setWorkspaces] = useState([]);
  const [workspaceServices, setWorkspaceServices] = useState([]);
  const [workspaceCount, setWorkspaceCount] = useState('--');
  const [sharedServicesCount, setSharedServicesCount] = useState('--');
  const [activeVms, setActiveVms] = useState<null | []>(null);
  const [currentlyDeploying, setCurrentlyDeploying] = useState(false);
  const [error, setError] = useState(null);

  const isWorkspaceDeploying = (workspaces: any) => {
    const checkDeploying = workspaces.filter((item: any) => item.deploymentStatus === "deploying" || item.deploymentStatus === "awaiting_deployment");
    return checkDeploying.length > 0;
  }

  useEffect(() => {
    setIsLoading(true);
    instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
      scopes: trecoreConfig.scopes
    }).then(async (response) => {
      await CallApiWithToken(response.accessToken, `${trecoreConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}`, HttpMethod.Get, '')
      .then(response => {
        setAllWorkspaces(response.workspaces);
        setWorkspaces(response.workspaces);
        setWorkspaceCount(response.workspaces.length);
        (user.roles?.includes(RoleName.TREAdmin)) && setCurrentlyDeploying(isWorkspaceDeploying(response.workspaces));
        setIsLoading(false);
        response.workspaces && response.workspaces.length > 0 && getWorkspaceServices(response.workspaces);
      })
      await CallApiWithToken(
        response.accessToken,
        `${trecoreConfig.trecoreEndpoint}/${ApiEndpoint.SharedServices}`,
        HttpMethod.Get,
        ''
      )
      .then(response => {
        setSharedServicesCount(response.sharedServices.filter((obj: any) => obj.isEnabled === true).length);
      })
      .catch((err: any) => {
        setError(err);
        setIsLoading(false);
      })
    }).catch((err: any) => {
      setError(err);
      setIsLoading(false);
    })
  }, [instance, accounts]);

  const getWorkspaceServices = (workspaces: any) => {
    const getServices = workspaces.map((workspace: any, i: number) => {
      return instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
        scopes: [`${workspace.properties.scope_id}/${process.env.REACT_APP_TRE_CORE_API_USER_IMPERSONATION}`]
      }).then(async (response) => {
        return await CallApiWithToken(
          response.accessToken,
          `${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${workspace.id}/${ApiEndpoint.WorkspaceServices}`,
          HttpMethod.Get,
          ''
        )
      });
    })

    let collectWorkspaceServices: any = [];

    const returnedPromises = Promise.all(getServices);

    returnedPromises.then(body => {
      body.forEach((response: any) => {
        response.workspaceServices.length > 0 && collectWorkspaceServices.push(response.workspaceServices);
      })
      setWorkspaceServices(collectWorkspaceServices.flat(Infinity));
      getActiveVMs(collectWorkspaceServices.flat(Infinity), workspaces);
    }).catch((err: any) => {
      setError(err);
      setIsLoading(false);
    })
  };

  const getActiveVMs = (wsServices: any, workspaces: any) => {
    const filterServices = wsServices.filter((item: any) => item.templateName && item.templateName.includes("guacamole"));

    const getVms = filterServices.map((wsService: any, i: number) => {
      const workspace = workspaces.filter((item: any) => item.id === wsService.workspaceId);
      
      return instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
        scopes: [`${workspace[0].properties.scope_id}/${process.env.REACT_APP_TRE_CORE_API_USER_IMPERSONATION}`]
      }).then(async (response) => {
        return await CallApiWithToken(
          response.accessToken,
          `${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${wsService.workspaceId}/${ApiEndpoint.WorkspaceServices}/${wsService.id}/${ApiEndpoint.UserResources}`,
          HttpMethod.Get,
          ''
        )
      });
    })

    let Vms: any = [];

    const returnedPromises = Promise.all(getVms);

    returnedPromises.then(body => {
      body.forEach((response: any) => {
        const filteredVms = response.userResources.filter((item: any) => item.azureStatus.powerState === VMPowerStates.Running || item.azureStatus.powerState === VMPowerStates.Starting);
        filteredVms && Vms.push(filteredVms);
      })
      setActiveVms(Vms.flat(Infinity));
    })
    .catch((err: any) => {
      setError(err);
      setIsLoading(false);
    })
  }

  const onSearch = (e: string) => {
    const searchTerm = e.toLowerCase();
    let newIds = allWorkspaces;
    let newNames = allWorkspaces;

    const filteredByIds = newIds.filter((item: any) => item.id.toLowerCase().includes(searchTerm));
    const filteredByName = newNames.filter((item: any) => item.properties.display_name.toLowerCase().includes(searchTerm));

    const searchWorkspaces = filteredByIds.concat(filteredByName);

    const newWorkspaces: any = Array.from(new Set(searchWorkspaces));
    setWorkspaces(newWorkspaces);
    (user.roles?.includes(RoleName.TREAdmin)) && setCurrentlyDeploying(isWorkspaceDeploying(allWorkspaces));
  }

  return (
    <>
      {error ? (
        <MessageCard msgData={error} />
      ) : (
        <>
          <header className="dashboard__header">
            <div>
              <Title>Home</Title>
              {(user.roles?.includes(RoleName.TREAdmin)) && (
                <Subtitle>Dashboard</Subtitle>
              )}
            </div>
            <div className="dashboard__header-right">
              {accounts[0] !== null && <Lede>{accounts[0].name}</Lede>}
              {user.roles && user.roles.length > 0 && <p className="dashboard__tag-wrapper"><GetTag roles={user.roles} /></p>}
            </div>
          </header>
          <LoadingBox loading={isLoading}>
            <section className="dashboard__section">
              <div className="dashboard__figures-box">
                <p className="dashboard__figure">{workspaceCount && workspaceCount} <span className="dashboard__figure-text">Workspaces</span></p>
                <p className="dashboard__figure">{activeVms ? activeVms.length : '--'} <span className="dashboard__figure-text">Active Vms</span></p>
                <p className="dashboard__figure">{sharedServicesCount && sharedServicesCount} <span className="dashboard__figure-text">Active shared services</span></p>
              </div>
              <h3 className="dashboard__heading">{(user.roles?.includes(RoleName.TREAdmin)) ? "Workspaces you are managing" : "Workspaces you are working on"}</h3>
              <SearchBox className="dashboard__search-box" placeholder="Search for workspace or ID" onSearch={(e) => onSearch(e.target.value)} />
              {(user.roles?.includes(RoleName.TREAdmin)) && (
                <>
                  {currentlyDeploying ? (
                    <p>A workspace is being deployed, you can create another workspace once this has completed</p>
                  ) : (
                    <ButtonLink to="/createworkspace">Create a workspace</ButtonLink>
                  )}
                </>
              )}
              {workspaces && workspaces.length > 0 && <Workspaces workspaces={workspaces} />}
            </section>
          </LoadingBox>
        </>
      )}
    </>
  )
}

const Workspaces = ({ workspaces }: any) => {
  const [data, setData] = useState(workspaces);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const [workspaceCosts, setWorkspaceCosts] = useState<any>();
  const appRolesCtx = useContext(AppRolesContext);
  const { instance, accounts } = useMsal();

  const canViewCosts = (appRolesCtx.roles?.includes(WorkspaceRoleName.WorkspaceOwner) || appRolesCtx.roles?.includes(RoleName.TREAdmin));

  useEffect(() => {
    setData(workspaces.slice(indexOfFirstRecord, indexOfLastRecord));
  }, [workspaces, currentPage]);

  useEffect(() => {
    canViewCosts && (
      instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
        scopes: trecoreConfig.scopes
      }).then(async (response) => {
        await CallApiWithToken(
          response.accessToken,
          `${trecoreConfig.trecoreEndpoint}/${ApiEndpoint.WorskspaceCosts}`,
          HttpMethod.Get,
          ''
        ).then(response => {
          setWorkspaceCosts(response.workspace_costs_items);
        }).catch((err: any) => {
          console.log("error: ", err);
        })
      })
    );
  }, []);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const nPages = workspaces && Math.ceil(workspaces.length / recordsPerPage);

  return (
    <>
      <div className="dashboard__workspaces-container">
        {data.map((workspace: any) => {
          // const costs: any = canViewCosts && workspaceCosts && workspaceCosts.filter((item: any) => item.workspace_id === workspace.id);
          const costs: any = canViewCosts && testWorkspaceCosts && testWorkspaceCosts.filter((item: any) => item.workspace_id === workspace.id);
          return (
            <article key={workspace.id} className="dashboard__workspace">
              <Link className="dashboard__workspace-link" to={`/Workspaces/${workspace.id}`}>
                <p className="dashboard__workspace-title">{workspace.properties.display_name}</p>
                {canViewCosts && workspaceCosts && costs.length > 0 && (
                  <p className="dashboard__workspace-cost-user-percentage">
                    Credit usage percentage: 
                    <span className="dashboard__workspace-cost-user-percentage-number">{Math.trunc(costs[0].credit_percentage_usage)}</span>
                  </p>
                )}
                <p className="dashboard__workspace-description">{workspace.properties.description}</p>
              </Link>
            </article>
          )
        })}
      </div>
      {nPages > 1 && (
        <Pagination
          className="dashboard__pagination"
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  )
}
