import React from "react";

import { Sort } from "./icons/sort";

import './ButtonWithSort.css';

type ButtonWithSortProps = {
  children: React.ReactNode;
  onClick: () => void;
}

export const ButtonWithSort = ({ children, onClick }: ButtonWithSortProps) => {
  return (
    <button className="button-with-sort" onClick={onClick}>{children}<Sort /></button>
  )
}
