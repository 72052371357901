
export interface Resource{
    templateName: string,
    properties: Properties
}


export interface ResourceVM extends Resource{

}

export interface Properties{
    display_name: string,
    description: string,
    os_image: string
}

export enum VMPowerStates {
    Running = "VM running",
    Starting = "VM starting",
    Stopping = "VM stopping",
    Stopped = "VM stopped",
    Deallocating = "VM deallocating",
    Deallocated = "VM deallocated"
  }