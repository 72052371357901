import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";

import { Dashboard } from "./Dashboard";
import { Home } from "./Home";

export const HomeLink = (props:any)=>{    
  const ProfileContent = () => {

    return (
      <>           
        {newHomeAuthentic()}
      </>
    );

    function newHomeAuthentic() {
      return <Dashboard />
    }
  }

  return (
    <>
      <div className="App">
        <AuthenticatedTemplate>
          <ProfileContent />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Home />
        </UnauthenticatedTemplate>
      </div>        
    </>
  );
}
