import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { LoadingBox, LabelText, HintText, Input, Button, ErrorText, ErrorSummary } from 'govuk-react';
import yn from 'yn';

import { WorkspaceContext } from '../contexts/WorkspaceContext';
import { ApiEndpoint } from '../components/models/apiEndPoints';
import { loginRequest, trecoreServicesConfig } from '../components/Core/authConfig';
import { CallApiWithToken, HttpMethod } from '../components/Core/fetch';

import { Title } from '../components/ui/Title';
import { Subtitle } from '../components/ui/Subtitle';
import { FormWrapper } from '../components/ui/FormWrapper';
import { FormButtonContainer } from '../components/ui/FormButtonContainer';
import { FormLabel } from '../components/ui/FormLabel';
import { Textarea } from '../components/ui/Textarea';
import { CancelButton } from '../components/ui/CancelButton';
import { NotificationBox } from '../components/ui/NotificationBox';

import { error } from '../types';
import { UpdateWorkspaceServiceRequest } from '../components/models/workspace';

export const UpdateWorkspaceService = () => {
  const location = useParams();
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const workspaceCtx = useContext(WorkspaceContext);
  const workspaceId = location.id;
  const workspaceServiceId = location.rid;
  const [service, setService] = useState<null | any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errors, setErrors] = useState<null | error[]>(null);
  const [apiError, setApiError] = useState(null);
  const [requestSuccessful, setRequestSuccessful] = useState(null);
  const serviceNameInputRef = useRef<HTMLInputElement>(null);
  const serviceDescriptionInputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    setIsLoading(true);
    workspaceCtx.workspace.properties && (
      instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
        scopes: [`${workspaceCtx.workspace.properties.scope_id}/${process.env.REACT_APP_TRE_CORE_API_USER_IMPERSONATION}`]
      }).then(async (response) => {
        await CallApiWithToken(
          response.accessToken,
          `${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${workspaceId}/${ApiEndpoint.WorkspaceServices}/${workspaceServiceId}`,
          HttpMethod.Get,
          ''
        ).then(response => {
          setService(response.workspaceService);
          setIsLoading(false);
        }).catch((err: any) => {
          setError(err);
          setIsLoading(false);
        })
      })
    )
  }, [workspaceCtx]);

  const getServiceName = () => {
    let name;
    if (service) {
      if (service.templateName.includes("guacamole")) {
        name = "Guacamole";
      } else if (service.templateName.includes("gitea")) {
        name = "Gitea";
      } else if (service.templateName.includes("ohdsi")) {
        name = "Atlas"
      } else {
        name = null;
      }
    }
    return name;
  }

  const makeApiCall = async (payload: any, workspace: any) => {
    const tokenRequest = {
      scopes: [`${workspaceCtx.workspace.properties.scope_id}/${process.env.REACT_APP_TRE_CORE_API_USER_IMPERSONATION}`],
      account: accounts[0]
    }

    setApiError(null);
    setIsLoading(true);

    instance.acquireTokenSilent(tokenRequest).then(async (response) => {
      await CallApiWithToken(
        response.accessToken,
        `${trecoreServicesConfig.trecoreEndpoint}/${ApiEndpoint.Workspaces}/${workspaceId}/${ApiEndpoint.WorkspaceServices}/${workspaceServiceId}`,
        HttpMethod.Patch,
        payload,
        "*"
      ).then(response => {
        setRequestSuccessful(response.operation.message);
        const path = `/manage-workspaces/${workspaceCtx.workspace.id}/manage-workspace-services`;
        navigate(path, {state: { updated: serviceNameInputRef.current!.value }})
        setIsLoading(false);
      }).catch((err: any) => {
        setApiError(err.message);
        setIsLoading(false);
      }).catch((err: any) => {
        setApiError(err.message);
        setIsLoading(false);
      })
      document.getElementById("notification")?.scrollIntoView();
      yn(process.env.REACT_APP_DEBUG) && console.log("Workspace service update request submitted");
    })
  }

  const createPayload = (e: any) => {
    e.preventDefault();
    const enteredName = serviceNameInputRef.current!.value;
    const enteredDescription = serviceDescriptionInputRef.current!.value;

    let properties: UpdateWorkspaceServiceRequest["properties"] = {}

    properties = {
      display_name: enteredName,
      description: enteredDescription,
    };

    const newUpdateWorkspaceServiceRequest : UpdateWorkspaceServiceRequest = {
      properties: properties
    };

    makeApiCall(newUpdateWorkspaceServiceRequest, workspaceCtx.workspace);
  }

  const hasError = (formItem: string) => {
    const checkError = (obj: error) => obj.targetName === formItem;
    return errors && errors.some(checkError);
  }

  const onSubmit = (e: any) => {
    e.preventDefault();
    setErrors(null);
    const newErrors = [];
    const serviceName = serviceNameInputRef.current?.value;
    if (!serviceName || (serviceName.length < 5 || serviceName.length > 100)) {
      newErrors.push({
        targetName: "service-name",
        text: "Enter a valid service name"
      })
    }

    const description = serviceDescriptionInputRef.current?.value;
    if (!description || (description.length < 10 || description.length > 100)) {
      newErrors.push({
        targetName: "service-description",
        text: "Enter a valid description"
      })
    }

    return newErrors.length > 0 ? (
      setErrors(newErrors),
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    ) : createPayload(e);
  }

  const routeChange = () => {
    // e.preventDefault();
    const path = `/manage-workspaces/${location.id}/manage-workspace-services`;
    navigate(path);
  }

  return (
    <LoadingBox loading={isLoading}>
      {(apiError || requestSuccessful) && (
        <NotificationBox
          id="notification"
          error={apiError}
          text={requestSuccessful}
        />
      )}
      <header>
        <Title>Update workspace service</Title>
        <Subtitle>{workspaceCtx.workspace.properties && workspaceCtx.workspace.properties.display_name}/{getServiceName()}</Subtitle>
      </header>
      {errors && errors.length > 0 && (
        <ErrorSummary
          errors={errors}
          heading="There is a problem submitting your request"
          onHandleErrorClick={(e: any) => document.getElementById(e)?.scrollIntoView()}
        />
      )}
      {service && (
        <form onSubmit={onSubmit}>
          <FormWrapper>
            <FormLabel error={hasError("service-name")} id="service-name">
              <LabelText>{getServiceName()} service name</LabelText>
              <HintText>Choose a name for your {getServiceName()?.toLowerCase()} service.</HintText>
              {hasError("service-name") && <ErrorText>Enter a valid service name (between 5 - 100 characters long)</ErrorText>}
              <Input ref={serviceNameInputRef} defaultValue={service.properties.display_name} />
            </FormLabel>
            <FormLabel error={hasError("service-description")} id="service-description">
              <LabelText>Description</LabelText>
              <HintText>Add a biref description for the {getServiceName()?.toLowerCase()} service.</HintText>
              {hasError("service-description") && <ErrorText>Enter a valid description (between 10 - 100 characters long)</ErrorText>}
              <Textarea forwardRef={serviceDescriptionInputRef} defaultValue={service.properties.description} />
            </FormLabel>
            <FormButtonContainer>
              <Button type="submit" className="govuk-button" data-module="govuk-button">Update service</Button>
              <CancelButton onClick={() => routeChange()}>Cancel</CancelButton>
            </FormButtonContainer>
          </FormWrapper>
        </form>
      )}
    </LoadingBox>
  )
}
